import { formatDateIntoYYYYMMDDWithDashes } from './formatters'
import ConfigurationHolder from '../configurationHolder'

/**
 * Creating FE logger configuration url with concatenate current date
 * @param feConfiguration
 * @returns {string|undefined}
 */
function getCurrentEndpoint(feConfiguration) {
    const currentDate = new Date(Date.now())
    const parsedDate = formatDateIntoYYYYMMDDWithDashes(currentDate)
    return `${feConfiguration.endpoint + parsedDate}/_doc`
}

const logFrontendError = (frontendLog, accessToken) => {
    const feConfiguration = ConfigurationHolder.getConfiguration().frontendLoggerConfiguration

    if (feConfiguration && feConfiguration.isEnabled && feConfiguration.endpoint !== '') {
        const endpoint = getCurrentEndpoint(feConfiguration)
        fetch(endpoint, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + accessToken,
            },
            body: JSON.stringify(frontendLog),
        }).catch((error) => console.log(error))
    }
}

export default logFrontendError
