import { REMOVE_CONTEXT_URI, SET_CONTEXT_URI } from '../types/reduxActionTypes'

const initialState = {
    contextUri: null,
}

const userSignedOutAction = 'oidc/userSignedOut'

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CONTEXT_URI:
            return {
                ...state,
                contextUri: action.payload.value,
            }
        case REMOVE_CONTEXT_URI:
            return {
                ...state,
                contextUri: null,
            }
        case userSignedOutAction:
            return {
                ...initialState,
            }
        default:
            return state
    }
}
