import {
    SET_ACCESS_TO_CREATE_PROCESS,
    SET_CANCEL_NEW_CUSTOMER_ACTIVE,
    SET_CREATE_NEW_CUSTOMER_ACTIVE,
    SET_DEALER_POPUP_ACTIVE,
    SET_DELETE_PROFILE_ACTIVE,
    SET_HAS_SEAT_IDENT_ACTIVE,
    SET_LOGOUT_FROM_GRP_ACTIVE,
} from '../types/reduxActionTypes'

const initialState = {
    createNewCustomerActive: false,
    cancelNewCustomerActive: false,
    hasSeatIdentActive: false,
    dealerProfilePopupActive: false,
    logoutFromGRPPopupActive: false,
    deleteProfilePopupActive: false,
    cancelYesUrlAction: null,
    accessToCreateProcess: false,
}
const userSignedOutAction = 'oidc/userSignedOut'
const userFoundAction = 'oidc/userFound'
const userLoadingAction = 'oidc/loadingUser'

export const popupsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CREATE_NEW_CUSTOMER_ACTIVE:
            return {
                ...state,
                createNewCustomerActive: action.payload.value,
            }
        case SET_CANCEL_NEW_CUSTOMER_ACTIVE:
            return {
                ...state,
                cancelNewCustomerActive: action.payload.value,
                cancelYesUrlAction: action.payload.onYesUrl,
            }
        case SET_HAS_SEAT_IDENT_ACTIVE:
            return {
                ...state,
                hasSeatIdentActive: action.payload.value,
            }
        case SET_DEALER_POPUP_ACTIVE:
            return {
                ...state,
                dealerProfilePopupActive: action.payload.value,
            }
        case SET_LOGOUT_FROM_GRP_ACTIVE:
            return {
                ...state,
                logoutFromGRPPopupActive: action.payload.value,
            }
        case SET_DELETE_PROFILE_ACTIVE:
            return {
                ...state,
                deleteProfilePopupActive: action.payload.value,
            }
        case SET_ACCESS_TO_CREATE_PROCESS:
            return {
                ...state,
                accessToCreateProcess: action.payload.value,
            }
        case userFoundAction:
            return {
                ...state,
                logoutFromGRPPopupActive: false,
            }
        case userSignedOutAction:
        case userLoadingAction:
            return {
                ...initialState,
                accessToCreateProcess: state.accessToCreateProcess,
            }
        default:
            return state
    }
}
