import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import {
    closeCancelNewCustomer,
    disallowAccessToCreateProcess,
    onCancelNewCustomerConfirm,
    showCreateNewCustomer,
} from '../../../actions/popupsActions'
import { SecondaryButton, PrimaryButton } from '../../Buttons'
import React from 'react'
import { TRANSLATIONS_NAMESPACE } from '../../../constants/global'
import { WarningIcon } from '../../Icons/WarningIcon'
import { DialogButtonsWrapper, DialogContentTitle, DialogContentWrapper } from '../../ContentDialog'
import PopUp from '../PopUp'
import { useSaveInputChange } from '../../../hooks/form/useSaveInputChange'

export default function CancelNewCustomerPopUp() {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const history = useHistory()
    const { setSearchParam } = useSaveInputChange()
    const { cancelNewCustomerActive, cancelYesUrlAction } = useSelector((state) => state.popups)

    const onCancelNewCustomer = () => {
        disallowAccessToCreateProcess()
        onCancelNewCustomerConfirm()
        setSearchParam(null)
        if (cancelYesUrlAction === 'create') {
            showCreateNewCustomer()
        } else if (cancelYesUrlAction !== null) {
            history.push({
                pathname: cancelYesUrlAction,
            })
        } else {
            history.push({
                pathname: '/customers',
            })
        }
    }

    const content = (
        <DialogContentWrapper>
            <WarningIcon width={56} />
            <DialogContentTitle>{t('general.customer.create.popup.exit')}</DialogContentTitle>
        </DialogContentWrapper>
    )

    const actions = (
        <DialogButtonsWrapper>
            <PrimaryButton
                clickAction={onCancelNewCustomer}
                label={t('general.customer.create.popup.yes')}
            />
            <SecondaryButton
                clickAction={closeCancelNewCustomer}
                label={t('general.customer.create.popup.no')}
            />
        </DialogButtonsWrapper>
    )

    const config = {
        title: t('general.customer.cancel.popup.title'),
        type: 'CANCEL_NEW_USER',
        actions: actions,
        body: content,
    }
    return (
        <PopUp
            onClose={closeCancelNewCustomer}
            open={cancelNewCustomerActive}
            body={config.body}
            title={config.title}
            actions={config.actions}
        />
    )
}
