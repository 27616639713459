import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { allowAccessToCreateProcess, closeCreateNewCustomer } from '../../../actions/popupsActions'
import { CancelButton, SecondaryButton } from '../../Buttons'
import PopUp from '../PopUp'
import React from 'react'
import { TRANSLATIONS_NAMESPACE } from '../../../constants/global'
import { WarningIcon } from '../../Icons/WarningIcon'
import DialogContentText from '@material-ui/core/DialogContentText'
import { DialogButtonsWrapper, DialogContentTitle, DialogContentWrapper } from '../../ContentDialog'
import { useCustomerActions } from '../../../hooks/actions/useCustomerActions'
import { useSaveInputChange } from '../../../hooks/form/useSaveInputChange'
import { useServicePartnersActions } from '../../../hooks/actions/useServicePartnerActions'

export default function CustomerExistsInGroupIdPopUp({ emailValue }) {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const history = useHistory()
    const { getCustomerGroupIdData, initializeRegistrationProcess } = useCustomerActions()
    const { setSearchParam } = useSaveInputChange()
    const { removePsp } = useServicePartnersActions()

    const { createNewCustomerActive } = useSelector((state) => state.popups)
    const { isPaperConsent } = useSelector((state) => state.customer)

    const handleProceed = () => {
        initializeRegistrationProcess({
            isPaperConsent,
        })
        removePsp()
        setSearchParam(null)
        getCustomerGroupIdData(emailValue)
        closeCreateNewCustomer()
        allowAccessToCreateProcess()
        history.push({
            pathname: '/customer-information',
        })
    }

    const actions = (
        <DialogButtonsWrapper>
            <SecondaryButton
                clickAction={handleProceed}
                label={t('general.customer.create.popup.proceed')}
            />
            <CancelButton
                clickAction={closeCreateNewCustomer}
                label={t('general.customer.create.popup.cancel')}
            />
        </DialogButtonsWrapper>
    )

    const content = (
        <DialogContentWrapper>
            <WarningIcon width={56} />
            <DialogContentTitle>
                {t('general.customer.create.popup.groupid.title')}
            </DialogContentTitle>
            <DialogContentText>{t('general.customer.create.popup.groupid.text')}</DialogContentText>
        </DialogContentWrapper>
    )

    const popupData = {
        title: t('general.customer.create.popup.title'),
        type: 'CREATE_NEW_USER_EXISTS_IN_GID',
        actions,
        body: content,
    }

    return (
        <PopUp
            onClose={closeCreateNewCustomer}
            open={createNewCustomerActive}
            body={popupData.body}
            title={popupData.title}
            actions={popupData.actions}
        />
    )
}
