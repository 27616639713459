import React from 'react'
import { connect, useSelector } from 'react-redux'
import { CallbackComponent } from 'redux-oidc'
import UserManagerHolder from '../../userManagerHolder'
import { useHistory } from 'react-router'
import Loading from '../../components/Loading'
import ConfigurationHolder from '../../configurationHolder'

function LoginCallback() {
    const history = useHistory()
    const { user } = useSelector((state) => state.oidc)
    const { authority, client_id } = ConfigurationHolder.getConfiguration().userManagerConfiguration
    return (
        <CallbackComponent
            userManager={UserManagerHolder.getUserManager()}
            successCallback={() => {
                localStorage.setItem(`oidc.user:${authority}:${client_id}`, JSON.stringify(user))
                history.push({
                    pathname: '/',
                })
            }}
            errorCallback={(error) => {
                history.push({
                    pathname: '/',
                })
                console.error(error)
            }}
        >
            <Loading />
        </CallbackComponent>
    )
}
export default connect()(LoginCallback)
