import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'
import { useSelector } from 'react-redux'
import { closeDeleteProfilePopup } from '../../../actions/popupsActions'
import { SecondaryButton, PrimaryButton } from '../../Buttons'
import React, { useCallback } from 'react'
import Popup from '../PopUp'
import { TRANSLATIONS_NAMESPACE } from '../../../constants/global'
import { WarningIcon } from '../../Icons/WarningIcon'
import { DialogButtonsWrapper, DialogContentTitle, DialogContentWrapper } from '../../ContentDialog'
import { useCustomerActions } from '../../../hooks/actions/useCustomerActions'

export default function DeleteCustomerPopUp() {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const history = useHistory()
    const { deleteCustomer } = useCustomerActions()
    const { deleteProfilePopupActive } = useSelector((state) => state.popups)
    const { customerId: id } = useParams()

    const confirmDeleteProfile = useCallback(() => {
        deleteCustomer(id).then(() => {
            closeDeleteProfilePopup()
            history.push('/customers')
        })
    }, [deleteCustomer, id, history])

    const content = (
        <DialogContentWrapper>
            <WarningIcon width={56} />
            <DialogContentTitle>
                {t('general.customer.information.deleteCustomer.confirmDelete')}
            </DialogContentTitle>
        </DialogContentWrapper>
    )

    const actions = (
        <DialogButtonsWrapper>
            <PrimaryButton
                clickAction={confirmDeleteProfile}
                label={t('general.customer.create.popup.yes')}
            />
            <SecondaryButton
                clickAction={closeDeleteProfilePopup}
                label={t('general.customer.create.popup.no')}
            />
        </DialogButtonsWrapper>
    )

    const config = {
        title: t('general.customer.information.deleteCustomer'),
        type: 'CANCEL_NEW_USER',
        actions: actions,
        body: content,
    }
    return (
        <Popup
            onClose={closeDeleteProfilePopup}
            body={config.body}
            title={config.title}
            actions={config.actions}
            open={deleteProfilePopupActive}
        />
    )
}
