import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { useDispatchNotification } from '../useDispatchNotification'
import { DownloadReport } from '../../api'
import {
    DOWNLOAD_REPORT_ERROR,
    DOWNLOAD_REPORT_STARTED,
    DOWNLOAD_REPORT_SUCCESS,
} from '../../types/reduxActionTypes'
import { downloadReportErrorMessage } from '../../types/reduxNotificationTypes'
import { saveToCsvFile } from '../../util/savers'
import { formatDateIntoDDMMYYYY } from '../../util/formatters'

export function useDownloadReportActions() {
    const dispatch = useDispatch()
    const [dispatchNotification] = useDispatchNotification()

    const downloadReport = useCallback(() => {
        dispatch({
            type: DOWNLOAD_REPORT_STARTED,
        })
        DownloadReport.downloadReport()
            .then((response) => {
                saveToCsvFile(response.response.body, formatDateIntoDDMMYYYY(new Date()))
                dispatch({
                    type: DOWNLOAD_REPORT_SUCCESS,
                })
            })
            .catch((error) => {
                console.log(error)
                dispatch({
                    type: DOWNLOAD_REPORT_ERROR,
                })
                dispatchNotification(downloadReportErrorMessage)
            })
    }, [dispatch, dispatchNotification])

    return { downloadReport }
}
