import { NOTIFICATION_TYPE_ERROR, NOTIFICATION_TYPE_SUCCESS } from 'react-redux-notify'

export const updateSuccessMessage = {
    message: 'general.notification.update.success',
    type: NOTIFICATION_TYPE_SUCCESS,
    duration: 3000,
    canDismiss: true,
}
export const updateErrorMessage = {
    message: 'general.notification.update.failed',
    type: NOTIFICATION_TYPE_ERROR,
    duration: 3000,
    canDismiss: true,
}

export const customerCreatedSuccessMessage = {
    message: 'general.notification.create.success',
    type: NOTIFICATION_TYPE_SUCCESS,
    duration: 3000,
    canDismiss: true,
}
export const customerCreatedErrorMessage = {
    message: 'general.notification.create.failed',
    type: NOTIFICATION_TYPE_ERROR,
    duration: 3000,
    canDismiss: true,
}
export const customerDeletedSuccessMessage = {
    message: 'general.notification.delete.success',
    type: NOTIFICATION_TYPE_SUCCESS,
    duration: 3000,
    canDismiss: true,
}
export const customerDeletedErrorMessage = {
    message: 'general.notification.delete.failed',
    type: NOTIFICATION_TYPE_ERROR,
    duration: 3000,
    canDismiss: true,
}
export const downloadReportErrorMessage = {
    message: 'general.notification.download.failed',
    type: NOTIFICATION_TYPE_ERROR,
    duration: 3000,
    canDismiss: true,
}

export const logoutErrorMessage = {
    message: 'general.notification.logout.failed',
    type: NOTIFICATION_TYPE_ERROR,
    duration: 3000,
    canDismiss: true,
}

export const pspErrorMessage = {
    message: 'general.dealershipCustomers.noItemsFound',
    type: NOTIFICATION_TYPE_ERROR,
    duration: 3000,
    canDismiss: true,
}

export const consentsSentErrorMessage = {
    message: 'general.consent.submit.failed',
    type: NOTIFICATION_TYPE_ERROR,
    duration: 3000,
    canDismiss: true,
}

export const somethingWentWrongErrorMessage = {
    message: 'general.errorBoundary.info',
    type: NOTIFICATION_TYPE_ERROR,
    duration: 3000,
    canDismiss: true,
}

export const sendEmailSuccessMessage = {
    message: 'general.sendemail.success',
    type: NOTIFICATION_TYPE_SUCCESS,
    duration: 3000,
    canDismiss: true,
}

export const sendEmailErrorMessage = {
    message: 'general.sendemail.error',
    type: NOTIFICATION_TYPE_ERROR,
    duration: 3000,
    canDismiss: true,
}

export const setPspErrorMessage = {
    message: 'general.setpsp.error',
    type: NOTIFICATION_TYPE_ERROR,
    duration: 3000,
    canDismiss: true,
}

export const setDmsErrorMessage = {
    message: 'general.setdms.error',
    type: NOTIFICATION_TYPE_ERROR,
    duration: 3000,
    canDismiss: true,
}

export const cancelOrderSuccessMessage = {
    message: 'general.cancel.success.popup.text',
    type: NOTIFICATION_TYPE_SUCCESS,
    duration: 3000,
    canDismiss: true,
}
