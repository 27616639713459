import React from 'react'
import './theme.scss'
import { useTranslation } from 'react-i18next'
import { ignoreKeyInHTML, TRANSLATIONS_NAMESPACE } from '../../constants/global'
import parse from 'html-react-parser'

export function LegalContent() {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    return (
        <div className="content-wrapper">
            {parse(t('general.footer.legal.content', { replace: ignoreKeyInHTML }))}
        </div>
    )
}
