import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText'
import { getErrorText, INCORRECT_EMAIL } from '../util/validators'
import InputTooltipWithLabel from './InputTooltipWithLabel'
import { BeakIcon } from './Icons/BeakIcon'
import { InputCheckmark } from './Icons/InputCheckmark'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { TRANSLATIONS_NAMESPACE } from '../constants/global'
import muiTheme from '../themes/muiTheme'

export const CustomInputWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: 18px;
    flex-direction: column;

    input[type='text'] {
        box-sizing: content-box !important;
    }
`

const Label = styled(InputLabel)`
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #808285;
`

const HelperText = styled(FormHelperText)`
    text-align: right;
    ${(props) => props.isinfo === 'true' && `color: ${muiTheme.palette.secondary.main};`}
`

export default function TextInput({
    formId,
    inputId,
    handleChange,
    label,
    value,
    options,
    error,
    disabled,
    fullWidth,
    noCheckmark,
    tooltipText,
    maxLength,
    onKeyDown,
    warningLabelText,
    mandatory,
    onOpen,
    locked,
    valueKey,
    valueLabel,
    helperText,
    labelTranslationKeyPrefix = '',
    ignoreFirstOptionDisabled = false,
}) {
    const textInputId = `${formId}-${inputId}-input`
    const textLabelId = `${textInputId}-label`
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)

    const errorMessagesWithoutLabel = [
        INCORRECT_EMAIL,
        'general.order.vin.tooltip',
        'general.error.vin.exists',
    ]

    const isError = error !== null && error !== undefined
    const ITEM_HEIGHT = 48
    const ITEM_PADDING_TOP = 8
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            },
        },
    }
    const textInputProps = {}
    if (maxLength) {
        textInputProps.maxLength = maxLength
    }

    const onChange = (event) => {
        handleChange(event)
    }
    const disabledStyle = { cursor: 'default !important' }

    const renderInput = (
        <>
            <TextField
                style={locked ? disabledStyle : {}}
                value={value}
                onChange={onChange}
                id={textInputId}
                name={inputId}
                label={
                    <InputTooltipWithLabel
                        labelText={label}
                        tooltipText={tooltipText}
                        warningText={warningLabelText}
                        mandatory={mandatory}
                    />
                }
                aria-describedby="component-error-text"
                error={isError}
                disabled={disabled}
                inputProps={textInputProps}
                InputLabelProps={{
                    shrink: true,
                }}
                onKeyDown={onKeyDown}
            />
            {!isError && value !== '' && !disabled && !noCheckmark && <InputCheckmark />}
        </>
    )

    const renderSelect = (
        <React.Fragment>
            <Label htmlFor={textInputId} error={isError}>
                <InputTooltipWithLabel
                    labelText={label}
                    tooltipText={tooltipText}
                    mandatory={mandatory}
                />
            </Label>
            <Select
                labelId={textLabelId}
                id={textInputId}
                name={inputId}
                value={value}
                onChange={handleChange}
                aria-describedby="component-error-text"
                error={isError}
                disabled={disabled}
                IconComponent={() => (disabled ? null : <BeakIcon isInput={true} />)}
                MenuProps={MenuProps}
                onOpen={onOpen}
            >
                {valueKey &&
                    valueLabel &&
                    options &&
                    options.map((option, index) => (
                        <MenuItem
                            value={option[valueKey]}
                            key={index}
                            disabled={!ignoreFirstOptionDisabled && mandatory && index === 0}
                        >
                            {labelTranslationKeyPrefix
                                ? t(`${labelTranslationKeyPrefix}.${option[valueLabel]}`)
                                : option[valueLabel]}
                        </MenuItem>
                    ))}
                {(!valueKey || !valueLabel) &&
                    options &&
                    options.map((option, index) => (
                        <MenuItem value={index} key={index} disabled={mandatory && index === 0}>
                            {option}
                        </MenuItem>
                    ))}
            </Select>
        </React.Fragment>
    )

    return (
        <CustomInputWrapper>
            <FormControl className={!fullWidth ? 'text-input' : null} error={isError}>
                {options ? renderSelect : renderInput}
                {isError && (
                    <HelperText id="component-error-text" error={isError}>
                        {errorMessagesWithoutLabel.includes(error) ? (
                            t(getErrorText(error))
                        ) : (
                            <>
                                {label} {t(getErrorText(error))}
                            </>
                        )}
                    </HelperText>
                )}
                {locked && (
                    <HelperText id="component-error-text" isinfo="true">
                        {label + ' ' + t('general.validation.disabled')}
                    </HelperText>
                )}
                {helperText && <HelperText>{helperText}</HelperText>}
            </FormControl>
        </CustomInputWrapper>
    )
}
