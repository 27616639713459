import React from 'react'
import styled from 'styled-components'
import x from '../../assets/images/x.svg'

const Root = styled.div`
    justify-content: flex-end;
    margin-bottom: -2px;
`

export default function XIcon() {
    return (
        <Root>
            <img src={x} width={14} alt="Remove" />
        </Root>
    )
}
