import React, { useCallback, useEffect, useRef, useState } from 'react'
import { MuiThemeProvider } from 'material-ui'
import TextInput from '../../components/TextInput'
import HorizontalStepper from '../../components/HorizontalStepper'
import RegistrationNavigationButtons from '../../components/RegistrationNavigationButtons'
import { ORDER_DETAILS_STEP } from '../../constants/registrationConstants'
import { useSelector } from 'react-redux'
import FormWrapper from '../../components/Wrappers/FormWrapper'
import Panel from '../../components/Wrappers/Panel'
import { useHistory } from 'react-router-dom'
import { errorTextMap, OrderDetailsFormValidator, validateVin } from '../../util/validators'
import { useTranslation } from 'react-i18next'
import ServicePartnerSearchInput from '../../components/ServicePartnerSearchInput/ServicePartnerSearchInput'
import { INPUT_SHORT } from '../../constants/defaultFormValues'
import { TRANSLATIONS_NAMESPACE } from '../../constants/global'
import ScrollTop from '../../components/Wrappers/ScrollTop'
import { FormField, FormInputField, FormTitle } from '../../components/FormElements'
import { useServicePartnersActions } from '../../hooks/actions/useServicePartnerActions'
import { useUpdateCustomerActions } from '../../hooks/actions/useUpdateCustomerActions'
import { WithHeader } from '../../components/Wrappers/WithHeader'
import RequiredBox from '../../components/RequiredBox'
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core'
import { PrimaryButton } from '../../components/Buttons'
import { useCustomerActions } from '../../hooks/actions/useCustomerActions'
import { disallowAccessToCreateProcess } from '../../actions/popupsActions'
import { UploadConsentDocument } from '../../components/PopUp/UploadConsentDocument'
import { ModifyConsents } from '../../components/PopUp/ModifyConsents'
import { Vehicles } from '../../api'

const isFormValid = (validationErrors) => {
    const formProperties = ['commissionNumber', 'vin', 'orderNumber', 'psp']
    return formProperties.every((item) => validationErrors[item] === null)
}

const year = new Date().getFullYear()

function CreateOrderDetails() {
    const { clearCustomer } = useUpdateCustomerActions()
    const customer = useSelector((state) => state.customer)
    const { orderDetails, requestedServices, isPaperConsent } = customer
    const { accessToCreateProcess } = useSelector((state) => state.popups)
    const { userInfo } = useSelector((state) => state.oidc)
    const { createCustomer } = useCustomerActions()

    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const history = useHistory()
    const { setPsp } = useServicePartnersActions()
    const { saveOrderDetailsField } = useUpdateCustomerActions()

    const formId = 'order-details'

    const [isUploadPopupOpen, setIsUploadPopupOpen] = useState(false)
    const [isConsentDocumentUploaded, setIsConsentDocumentUploaded] = useState(false)
    const [isModifyConsentPopupOpen, setIsModifyConsentPopupOpen] = useState(false)
    const [errors, setErrors] = useState({})
    const checkExistingVehicleHandleRef = useRef(null)

    useEffect(() => {
        if (!accessToCreateProcess) {
            history.push({
                pathname: '/customers',
            })
        }
    }, [accessToCreateProcess, history])

    useEffect(() => {
        !orderDetails.commissionYear && saveOrderDetailsField('commissionYear', year)
    }, [orderDetails.commissionYear, saveOrderDetailsField])

    const onChangeVinHandle = (vin) => {
        saveOrderDetailsField('modelName', '')
        setErrors((prevState) => ({
            ...prevState,
            vin: null,
        }))
        checkExistingVehicleHandleRef.current &&
            window.clearTimeout(checkExistingVehicleHandleRef.current)
        if (vin && validateVin(vin) === null) {
            checkExistingVehicleHandleRef.current = window.setTimeout(async () => {
                try {
                    const modelNameResponse = await Vehicles.getModelNameByVin(vin)
                    modelNameResponse.data?.modelName &&
                        saveOrderDetailsField('modelName', modelNameResponse.data.modelName)
                } catch (e) {
                    console.error(e)
                }
            }, 500)
        }
    }

    const onChangeCommissionNumberFullHandle = async (commissionNumberFull) => {
        setErrors((prevState) => ({
            ...prevState,
            commissionNumber: null,
        }))
        if (/\d{6}-\d{3}-\d{4}/.test(commissionNumberFull)) {
            checkExistingVehicleHandleRef.current = window.setTimeout(async () => {
                try {
                    const commissionNumberParts = commissionNumberFull.split('-')

                    const vinAndModelNameResponse =
                        await Vehicles.getVinAndModelNameByCommissionNumber(
                            commissionNumberParts[0],
                            commissionNumberParts[1],
                            commissionNumberParts[2]
                        )
                    if (vinAndModelNameResponse.data?.vin) {
                        saveOrderDetailsField('vin', vinAndModelNameResponse.data.vin)
                    }
                    vinAndModelNameResponse.data?.modelName &&
                        saveOrderDetailsField('modelName', vinAndModelNameResponse.data.modelName)
                } catch (e) {
                    console.error(e)
                }
            }, 500)
        }
    }

    const createCustomerHandle = useCallback(() => {
        createCustomer({
            ...customer,
            customerInformation: {
                ...customer.customerInformation,
                address: {
                    addressTypeId: 1,
                    countryId: customer.customerInformation.address.countryId,
                },
                phoneNumber: customer.customerInformation.phoneNumber
                    ? customer.customerInformation.phoneNumber.replace(/\s/g, '')
                    : null,
            },
            orderDetails: {
                ...customer.orderDetails,
                commissionNumberBid: userInfo.bid,
            },
        })
            .then((result) => {
                if (result?.body?.errorKey) {
                    const errorPair = result.body.errorKey.split(':')
                    setErrors({ ...errors, [errorPair[0]]: errorPair[1] })
                } else {
                    disallowAccessToCreateProcess()
                    history.push({
                        pathname: '/customers',
                    })
                    clearCustomer()
                }
            })
            .catch((err) => {
                console.error(err)
                disallowAccessToCreateProcess()
                history.push({
                    pathname: '/customers',
                })
                clearCustomer()
            })
    }, [userInfo, clearCustomer, createCustomer, customer, errors, history, setErrors])

    const validate = () => {
        const validationErrors = OrderDetailsFormValidator(orderDetails, requestedServices)
        setErrors(validationErrors)
        return validationErrors
    }

    const handleChange = (event) => {
        event.persist()
        saveOrderDetailsField(event.target.name, event.target.value)
    }

    const handleChangeVIN = (event) => {
        event.persist()
        saveOrderDetailsField(event.target.name, event.target.value.toUpperCase())
    }

    const onNextClick = () => {
        const validationErrors = validate()
        if (isFormValid(validationErrors)) {
            createCustomerHandle()
        }
    }

    return (
        <ScrollTop>
            <MuiThemeProvider>
                <FormWrapper>
                    <HorizontalStepper activeStep={ORDER_DETAILS_STEP} />
                    <Panel>
                        <FormTitle>{t('general.order.title')}</FormTitle>
                        <form noValidate autoComplete="off">
                            <FormField>
                                <FormInputField>
                                    <TextInput
                                        formId={formId}
                                        handleChange={(e) => {
                                            handleChangeVIN(e)
                                            onChangeVinHandle(e.target.value)
                                        }}
                                        inputId={'vin'}
                                        label={t('general.order.vin')}
                                        value={orderDetails.vin || ''}
                                        fullWidth
                                        maxLength={INPUT_SHORT}
                                        error={errors.vin}
                                        tooltipText={t('general.order.vin.tooltip')}
                                        mandatory={false}
                                        // MSP-69037
                                        // helperText={!errors.vin && orderDetails.modelName}
                                    />
                                </FormInputField>
                            </FormField>
                            <Box mb={3}>
                                <Grid container spacing={1} wrap={'nowrap'}>
                                    <Grid item sm={6}>
                                        <FormControl error={!!errors.commissionNumber}>
                                            <InputLabel
                                                style={{ whiteSpace: 'nowrap' }}
                                                required={
                                                    !orderDetails.vin && !orderDetails.orderNumber
                                                }
                                            >
                                                {t('general.order.commission')}
                                            </InputLabel>

                                            <Input
                                                name={'commissionNumber'}
                                                value={orderDetails.commissionNumber ?? ''}
                                                fullWidth
                                                inputProps={{
                                                    maxLength: 6,
                                                }}
                                                style={{
                                                    letterSpacing: '1px',
                                                }}
                                                error={!!errors.commissionNumber}
                                                onChange={(e) => {
                                                    saveOrderDetailsField(
                                                        'commissionNumber',
                                                        e.target.value.toUpperCase()
                                                    )
                                                    onChangeCommissionNumberFullHandle(
                                                        `${e.target.value}-${userInfo.bid}-${orderDetails.commissionYear}`
                                                    )
                                                }}
                                                placeholder={'______'}
                                                disabled={!!orderDetails.vin}
                                            />
                                        </FormControl>
                                        {errors.commissionNumber && (
                                            <FormHelperText style={{ whiteSpace: 'nowrap' }} error>
                                                {errorTextMap[errors.commissionNumber] ? (
                                                    <>
                                                        {t('general.order.commission')}{' '}
                                                        {t(errorTextMap[errors.commissionNumber])}
                                                    </>
                                                ) : (
                                                    t(errors.commissionNumber) || null
                                                )}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item sm={3}>
                                        <FormControl error={!!errors.commissionNumber}>
                                            <InputLabel></InputLabel>
                                            <Input
                                                name={'bid'}
                                                value={userInfo.bid ?? ''}
                                                disabled
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={3}>
                                        <FormControl error={!!errors.commissionNumber}>
                                            <InputLabel></InputLabel>
                                            <Select
                                                name={'commission-year'}
                                                value={orderDetails.commissionYear || ''}
                                                fullWidth
                                                onChange={(e) => {
                                                    saveOrderDetailsField(
                                                        'commissionYear',
                                                        e.target.value
                                                    )
                                                    onChangeCommissionNumberFullHandle(
                                                        `${orderDetails.commissionNumber}-${userInfo.bid}-${e.target.value}`
                                                    )
                                                }}
                                                disabled={!!orderDetails.vin}
                                            >
                                                <MenuItem value={year + 1}>{year + 1}</MenuItem>
                                                <MenuItem value={year}>{year}</MenuItem>
                                                <MenuItem value={year - 1}>{year - 1}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Box>
                            <FormField>
                                <FormInputField>
                                    <TextInput
                                        formId={formId}
                                        handleChange={handleChange}
                                        inputId={'orderNumber'}
                                        label={t('general.order.orderNumber')}
                                        value={orderDetails.orderNumber || ''}
                                        fullWidth
                                        maxLength={INPUT_SHORT}
                                        error={errors.orderNumber}
                                        mandatory={false}
                                    />
                                </FormInputField>
                            </FormField>
                            <FormField>
                                <ServicePartnerSearchInput
                                    orderDetails={orderDetails}
                                    setPsp={(a, b) => {
                                        setPsp(a, b)
                                        setErrors((prevState) => ({
                                            ...prevState,
                                            psp: null,
                                        }))
                                    }}
                                    errors={errors}
                                    editMode
                                />
                            </FormField>
                            {isPaperConsent ? (
                                <FormField>
                                    <Box mb={4}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    disabled={true}
                                                    checked={
                                                        requestedServices?.dealerMaintenanceScheduling
                                                    }
                                                />
                                            }
                                            label={t('general.order.serviceScheduling')}
                                        />
                                    </Box>
                                </FormField>
                            ) : (
                                <Box mb={2}></Box>
                            )}
                        </form>
                        <RequiredBox withTop />
                        <Box mt={4}>
                            <Grid container justifyContent={'center'} spacing={2}>
                                <Grid item>
                                    <PrimaryButton
                                        label={t('general.order.button.modifyConsent')}
                                        clickAction={() => setIsModifyConsentPopupOpen(true)}
                                    />
                                </Grid>
                                <Grid item>
                                    <PrimaryButton
                                        disabled={!isPaperConsent}
                                        label={t('general.order.button.uploadConsentDocument')}
                                        clickAction={() => setIsUploadPopupOpen(true)}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <RegistrationNavigationButtons
                            activeStep={ORDER_DETAILS_STEP}
                            onNextClick={onNextClick}
                            isNextDisabled={
                                customer.loading || (isPaperConsent && !isConsentDocumentUploaded)
                            }
                        />
                    </Panel>
                    <UploadConsentDocument
                        isOpen={isUploadPopupOpen}
                        setIsUploadPopupOpen={setIsUploadPopupOpen}
                        setIsConsentDocumentUploaded={setIsConsentDocumentUploaded}
                    />
                    <ModifyConsents
                        isOpen={isModifyConsentPopupOpen}
                        onCloseHandler={setIsModifyConsentPopupOpen}
                    />
                </FormWrapper>
            </MuiThemeProvider>
        </ScrollTop>
    )
}

export default WithHeader(CreateOrderDetails)
