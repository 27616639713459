import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { SET_SEARCH_PARAM } from '../../types/reduxActionTypes'

export function useSaveInputChange() {
    const dispatch = useDispatch()
    const saveInputChange = useCallback(
        (payload, type) => {
            dispatch({
                type,
                payload,
            })
        },
        [dispatch]
    )

    const setSearchParam = useCallback(
        (value) => {
            dispatch({
                type: SET_SEARCH_PARAM,
                payload: {
                    value,
                },
            })
        },
        [dispatch]
    )

    return { saveInputChange, setSearchParam }
}
