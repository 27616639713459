import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Commissions } from '../api'
import {
    GET_COMMISSIONS_ERROR,
    GET_COMMISSIONS_STARTED,
    GET_COMMISSIONS_SUCCESS,
} from '../types/reduxActionTypes'

export function useSearchCommissions() {
    const dispatch = useDispatch()

    const searchCommissions = useCallback(
        async (searchInput) => {
            dispatch({
                type: GET_COMMISSIONS_STARTED,
            })

            return await Commissions.searchCommissions(searchInput)
                .then((response) => {
                    dispatch({
                        type: GET_COMMISSIONS_SUCCESS,
                        payload: {
                            commissions: response.data || [],
                        },
                    })
                })
                .catch((error) => {
                    console.log(error)
                    dispatch({
                        type: GET_COMMISSIONS_ERROR,
                    })
                })
        },
        [dispatch]
    )
    return [searchCommissions]
}
