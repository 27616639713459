import React from 'react'
import ReactLoading from 'react-loading'
import styled from 'styled-components'
import muiTheme from '../themes/muiTheme'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 50px;
    height: 100%;
    padding-top: 150px;
`

export default function Loading() {
    return (
        <Wrapper>
            <ReactLoading
                type="bubbles"
                color={muiTheme.palette.secondary.main}
                height={120}
                width={120}
            />
        </Wrapper>
    )
}
