export const TRANSLATIONS_NAMESPACE = 'cba'

export const DEALER_ROLE = 'DEALER'
export const DPO_ROLE = 'DPO'
export const REPORT_ADMINISTRATOR_ROLE = 'REPORT_ADMINISTRATOR'

export const SUPPORTED_LANGUAGES = [
    'de',
    'en',
    'fr',
    'it',
    'bs',
    'cs',
    'nl',
    'bgG',
    'el',
    'da',
    'et',
    'ru',
    'es',
    'fi',
    'hr',
    'hu',
    'is',
    'lt',
    'lv',
    'mk',
    'nn',
    'pl',
    'pt',
    'ro',
    'sr',
    'sr',
    'sv',
    'sl',
    'sk',
    'uk',
]

export const SUPPORTED_LANGUAGES_CULTURES = {
    de: 'de-DE',
    en: 'en-GB',
    fr: 'fr-FR',
    it: 'it-IT',
    bs: 'bs-BA',
    cs: 'cs-CZ',
    nl: 'nl-NL',
    bg: 'bg-BG',
    el: 'el-GR',
    da: 'da-DK',
    et: 'et-EE',
    ru: 'ru-RU',
    es: 'es-ES',
    fi: 'fi-FI',
    hr: 'hr-HR',
    hu: 'hu-HU',
    is: 'is-IS',
    lt: 'lt-LT',
    lv: 'lv-LV',
    mk: 'mk-MK',
    nn: 'nn-NO',
    pl: 'pl-PL',
    pt: 'pt-PT',
    ro: 'ro-RO',
    sr: 'sr-RS',
    sv: 'sv-SE',
    sl: 'sl-SL',
    sk: 'sk-SK',
    uk: 'uk-UA',
}

export const DEFAULT_CULTURE = 'en-GB'

export const rqStatusMap = {
    INITIAL: 'initial',
    SUCCESS: 'success',
    ERROR: 'error',
    IN_PROGRESS: 'inProgress',
}

export const journeyStatusValuesEnum = {
    EMPTY: 'EMPTY',
    COMPLETED: 'COMPLETED',
    NOT_REQUIRED: 'NOT_REQUIRED',
    FAILED: 'FAILED',
}

export const CONSENTS_MANDATORY_KEY = 'dataProcessing'
export const PSP_KEY = 'preferredServicePartner'
export const DMS_KEY = 'dealerMaintenanceScheduling'

export const CONSENTS_TYPES = {
    PAPER: 'PAPER',
    DIGITAL: 'DIGITAL',
}

export const journeyStatusStatesEnum = {
    COMPLETED: 'COMPLETED',
    FAILED: 'FAILED',
    NOT_REQUIRED: 'NOT_REQUIRED',
    EMPTY: 'EMPTY',
}

export const orderCancelledKey = 'vehicleActivationRequestCancelled'

// used to ignore i18n hook, which adds key to HTML
// (used for translations in attributes)
export const ignoreKeyInHTML = 'ignoreKeyInHTML'
