import { DateTime } from 'luxon'
import { defaultDateFormat } from '../constants/dateFormats'

export function formatDateIntoDDMMYYYY(date) {
    if (!date) {
        return null
    }
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    return String(1000000 * day + 10000 * month + year)
}

export function formatDateIntoYYYYMMDDWithDashes(date) {
    if (!date) {
        return null
    }
    let month = date.getMonth() + 1
    let day = date.getDate()
    const year = date.getFullYear()

    if (month.toString().length < 2) {
        month = '0' + month
    }
    if (day.toString().length < 2) {
        day = '0' + day
    }

    return [year, month, day].join('-')
}

export function formatDateIntoDatePicker(date) {
    if (!date) {
        return null
    }

    if (!(date instanceof Date)) {
        date = DateTime.fromFormat(date, defaultDateFormat)
        const year = date.year
        const month = date.month
        const day = date.day
        return {
            year,
            month,
            day,
        }
    }

    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    return {
        year,
        month,
        day,
    }
}

export function formatDateFromDatePicker(dateObject, dateFormat) {
    if (!dateObject) {
        return ''
    }
    if (!dateObject || !dateObject?.year) {
        return dateObject
    }
    const year = dateObject.year
    const month = dateObject.month - 1
    const day = dateObject.day
    let date = new Date(year, month, day, 0, 0, 0, 0)
    return DateTime.fromISO(date.toISOString()).toFormat(dateFormat)
}

export function getDateFromDatePicker(dateObject) {
    if (!dateObject) {
        return null
    }
    const year = dateObject.year
    const month = dateObject.month - 1
    const day = dateObject.day
    return new Date(year, month, day, 0, 0, 0, 0)
}

export function getDateFromManualInput(dateObject, dateFormat) {
    if (!dateObject) {
        return null
    }
    return DateTime.fromFormat(dateObject, dateFormat)
}
