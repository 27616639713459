import { Box, Grid, Typography } from '@material-ui/core'
import { AppStoreIcon } from './Icons/AppStoreIcon'
import { GooglePlayIcon } from './Icons/GooglePlayIcon'
import { AppQrCode } from './Icons/AppQrCode'
import React from 'react'

const DownloadAppBox = ({ description, appleLink, googleLink, qrImage }) => {
    return (
        <>
            {description && (
                <Box mb={2}>
                    <Typography variant={'body1'} align={'center'}>
                        {description}
                    </Typography>
                </Box>
            )}
            <Box mb={2}>
                <Grid container justifyContent={'center'} spacing={2}>
                    <Grid item>
                        <AppStoreIcon href={appleLink} />
                    </Grid>
                    <Grid item>
                        <GooglePlayIcon href={googleLink} />
                    </Grid>
                </Grid>
            </Box>
            <Box mb={2}>
                <Grid container justifyContent={'center'}>
                    <Grid item>
                        <AppQrCode qrImg={qrImage} />
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default DownloadAppBox
