import React from 'react'
import './theme.scss'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

export default function PopUp({
    title,
    body,
    onClose,
    open,
    actions,
    isOnRight,
    children,
    isFloatingWidth,
}) {
    const renderWithoutCloseButton = () => {
        return (
            <div className={open ? 'dialog dialog--open' : 'dialog'}>
                <div className="dialog__overlay" />
                <div
                    className={`dialog__content ${
                        isFloatingWidth ? 'dialog__content--floating-width' : null
                    }`}
                >
                    <div className={'dialog__header'}>
                        <div className={'dialog__header__title'}>{title}</div>
                    </div>
                    <div className={'dialog__body'}>{children ? children : body}</div>
                    <div className={'dialog__actions'}>{actions}</div>
                </div>
            </div>
        )
    }
    if (onClose === undefined) {
        return renderWithoutCloseButton()
    }
    return (
        <div className={open ? 'dialog dialog--open' : 'dialog'}>
            <div className="dialog__overlay" />
            <ClickAwayListener
                mouseEvent="onMouseDown"
                touchEvent="onTouchStart"
                onClickAway={onClose}
            >
                <div
                    className={`dialog__content ${isOnRight ? 'dialog__content-right' : null} ${
                        isFloatingWidth ? 'dialog__content--floating-width' : null
                    }`}
                >
                    <div className={'dialog__header'}>
                        <div className={'dialog__header__title'}>{title}</div>
                        <div className={'dialog__header__closer'} onClick={onClose}>
                            <div className={'dialog__header__cross-icon'} />
                        </div>
                    </div>
                    <div className={'dialog__body'}>{children ? children : body}</div>
                    <div className={'dialog__actions'}>{actions}</div>
                </div>
            </ClickAwayListener>
        </div>
    )
}
