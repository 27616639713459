import {
    CHECK_ACCOUNT_ERROR,
    CHECK_ACCOUNT_STARTED,
    CHECK_ACCOUNT_SUCCESS,
    REMOVE_ACCOUNT_CHECK,
} from '../types/reduxActionTypes'

const initialState = {
    loading: false,
    error: false,
    accountCheck: null,
}

const userSignedOutAction = 'oidc/userSignedOut'

export const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHECK_ACCOUNT_STARTED:
            return {
                ...state,
                loading: true,
                error: false,
            }
        case CHECK_ACCOUNT_SUCCESS:
            return {
                ...state,
                accountCheck: action.payload.accountCheck,
                loading: false,
                error: false,
            }
        case CHECK_ACCOUNT_ERROR:
            return {
                ...state,
                accountCheck: null,
                loading: false,
                error: true,
            }
        case REMOVE_ACCOUNT_CHECK:
            return {
                ...state,
                accountCheck: null,
            }
        case userSignedOutAction:
            return {
                ...initialState,
            }
        default:
            return state
    }
}
