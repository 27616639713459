import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    CONSENTS_MANDATORY_KEY,
    DEFAULT_CULTURE,
    DMS_KEY,
    ignoreKeyInHTML,
    PSP_KEY,
    rqStatusMap,
    TRANSLATIONS_NAMESPACE,
} from '../../constants/global'
import { Accounts } from '../../api'
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Container,
    CssBaseline,
    FormControlLabel,
    Grid,
    makeStyles,
    Typography,
} from '@material-ui/core'
import { ButtonWithSuffixSuffix, ButtonWithSuffixWrapper } from '../../components/Buttons'
import { useDispatchNotification } from '../../hooks/useDispatchNotification'
import { consentsSentErrorMessage } from '../../types/reduxNotificationTypes'
import { getTranslations } from '../../actions/i18nActions'
import { useSelector } from 'react-redux'
import { useChangeLanguage } from '../../hooks/useChangeLanguage'
import Notification from '../../components/Notification'
import cupraTheme from '../../themes/cupraTheme'
import seatTheme from '../../themes/seatTheme'
import defaultTheme from '../../themes/muiTheme'
import { ThemeProvider } from '@material-ui/core'
import styled from 'styled-components'
import parse from 'html-react-parser'
import SeatLogo from '../../assets/images/seat-logo.png'
import HeroSeat from '../../assets/images/hero-seat-consent.png'
import HeroSeatMobile from '../../assets/images/hero-seat-consent-mobile.png'
import HeroSeatThankYou from '../../assets/images/hero-seat-consent-thankyou.png'
import HeroSeatThankYouMobile from '../../assets/images/hero-seat-consent-thankyou-mobile.png'
import HeroSeatError from '../../assets/images/hero-seat-consent-error.png'
import HeroCupra from '../../assets/images/hero-cupra-consent.png'
import HeroCupraMobile from '../../assets/images/hero-cupra-consent-mobile.png'
import HeroCupraThankYou from '../../assets/images/hero-cupra-consent-thankyou.png'
import HeroCupraThankYouMobile from '../../assets/images/hero-cupra-consent-thankyou-mobile.png'
import HeroCupraError from '../../assets/images/hero-cupra-consent-error.png'
import HeroCupraErrorMobile from '../../assets/images/hero-cupra-consent-error-mobile.png'

const ImageFluid = styled.img`
    display: block;
    max-width: 100%;
`

const CupraErrorHeroWrapper = styled.div`
  margin-bottom: 1rem;
  ${defaultTheme.breakpoints.up('md')} {
    margin-bottom: -2.5rem;
  }
}`

const SeatHeaderDesktopWrapper = styled.div`
    position: relative;
    padding-right: 10rem;
`

const SeatHeaderDesktopTextWrapper = styled.div`
    ${defaultTheme.breakpoints.up('md')} {
        position: absolute;
        right: 2rem;
        top: 12rem;
        max-width: 36rem;
        text-shadow: #4a4a4a 0 0 3px;
    }
`

const ContainerRelative = styled(Container)`
    position: relative;
`

const useStyles = makeStyles((theme) => ({
    displayNoneMdUp: {
        display: 'block !important',
        [theme.breakpoints.up('md')]: {
            display: 'none !important',
        },
    },
    displayBlockMdUp: {
        display: 'none !important',
        [theme.breakpoints.up('md')]: {
            display: 'block !important',
        },
    },
}))

const SeatLogoComponent = () => (
    <Grid container>
        <Grid item lg={2} sm={3} xs={4}>
            <Box my={2}>
                <ImageFluid src={SeatLogo} alt={'SEAT'} />
            </Box>
        </Grid>
    </Grid>
)

const useFormLabelPaddedStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(4),
    },
}))

function ConsentConfirmation() {
    const [consentData, setConsentData] = useState(null)
    const [consentValues, setConsentValues] = useState(null)
    const [brand, setBrand] = useState(null)
    const [rqStatus, setRqStatus] = useState(rqStatusMap.IN_PROGRESS)
    const [submitStatus, setSubmitStatus] = useState(rqStatusMap.INITIAL)
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const [dispatchNotification] = useDispatchNotification()
    const [seatThemeDynamic, setSeatThemeDynamic] = useState(seatTheme)
    const classes = useStyles()

    const { translations, loading } = useSelector((state) => state.options)
    const { setLanguage } = useChangeLanguage()
    getTranslations()

    const fontLabelPaddedClasses = useFormLabelPaddedStyles()

    const setSeatThemeBgr = (bgr) => {
        setSeatThemeDynamic({
            ...seatTheme,
            palette: {
                ...seatTheme.palette,
                background: {
                    ...seatTheme.palette.background,
                    default: bgr,
                },
            },
        })
    }

    const submitData = async () => {
        setSubmitStatus(rqStatusMap.IN_PROGRESS)
        try {
            await Accounts.setConsents({
                hash: consentData.hash,
                // MSP-46741 psp and dms united to single checkbox
                consents: {
                    ...consentValues,
                    dealerMaintenanceScheduling: consentValues.preferredServicePartner,
                },
            })
            setSubmitStatus(rqStatusMap.SUCCESS)
        } catch (e) {
            dispatchNotification(consentsSentErrorMessage)
            setSubmitStatus(rqStatusMap.ERROR)
            console.error(e)
        }
        setSeatThemeBgr('#ea5d1a')
    }

    useEffect(() => {
        const init = async () => {
            const url = new URL(window.location)
            const hash = url.searchParams.get('hash')
            const urlBrand = url.searchParams.get('brand')
            urlBrand && setBrand(urlBrand)
            if (hash) {
                setRqStatus(rqStatusMap.IN_PROGRESS)
                try {
                    const response = await Accounts.getConsents(hash)
                    setBrand(response.data.brand)
                    setConsentData(response.data)
                    setConsentValues(() => {
                        const data = {}
                        Object.entries(response.data.consents).forEach(([key]) => {
                            data[key] = false
                        })
                        return data
                    })
                    setRqStatus(rqStatusMap.SUCCESS)
                } catch (e) {
                    console.error(e)
                    setSeatThemeBgr('#9e5983')
                    setRqStatus(rqStatusMap.ERROR)
                }
            } else {
                setSeatThemeBgr('#9e5983')
                setRqStatus(rqStatusMap.ERROR)
            }
        }
        init()
    }, [])

    useEffect(() => {
        if (translations && Object.keys(translations).length > 0 && !loading) {
            if (Object.keys(translations).length === 1) {
                setLanguage(Object.keys(translations)[0])
            } else {
                setLanguage(DEFAULT_CULTURE)
            }
        }
    }, [translations, loading, setLanguage])

    return (
        <ThemeProvider
            theme={
                brand === 'CUPRA' ? cupraTheme : brand === 'SEAT' ? seatThemeDynamic : defaultTheme
            }
        >
            <CssBaseline />
            {rqStatus === rqStatusMap.SUCCESS && consentData && consentValues ? (
                <ContainerRelative>
                    {submitStatus === rqStatusMap.SUCCESS ? (
                        <>
                            {brand === 'SEAT' && <SeatLogoComponent />}
                            <Box mb={4}>
                                {brand === 'CUPRA' && (
                                    <>
                                        <ImageFluid
                                            src={HeroCupraThankYou}
                                            className={classes.displayBlockMdUp}
                                            alt={''}
                                        />
                                        <ImageFluid
                                            src={HeroCupraThankYouMobile}
                                            className={classes.displayNoneMdUp}
                                            alt={''}
                                        />
                                    </>
                                )}
                                {brand === 'SEAT' && (
                                    <>
                                        <SeatHeaderDesktopWrapper
                                            className={classes.displayBlockMdUp}
                                        >
                                            <ImageFluid src={HeroSeatThankYou} alt={''} />
                                        </SeatHeaderDesktopWrapper>
                                        <ImageFluid
                                            className={classes.displayNoneMdUp}
                                            src={HeroSeatThankYouMobile}
                                            alt={''}
                                        />
                                    </>
                                )}
                            </Box>
                            {brand === 'CUPRA' && (
                                <Typography variant={'h1'}>
                                    {t('general.consents.setSuccessTitle')}
                                </Typography>
                            )}
                            {brand === 'SEAT' && (
                                <SeatHeaderDesktopTextWrapper>
                                    <Typography variant={'h1'}>
                                        {t('general.consents.setSuccessTitle')}
                                    </Typography>
                                </SeatHeaderDesktopTextWrapper>
                            )}
                            <div>
                                {parse(
                                    t('general.consents.setSuccess', { replace: ignoreKeyInHTML })
                                )}
                            </div>
                        </>
                    ) : (
                        <>
                            {brand === 'SEAT' && <SeatLogoComponent />}
                            <Box mb={4}>
                                {brand === 'CUPRA' && (
                                    <>
                                        <ImageFluid
                                            src={HeroCupra}
                                            className={classes.displayBlockMdUp}
                                            alt={''}
                                        />
                                        <ImageFluid
                                            src={HeroCupraMobile}
                                            className={classes.displayNoneMdUp}
                                            alt={''}
                                        />
                                    </>
                                )}
                                {brand === 'SEAT' && (
                                    <>
                                        <SeatHeaderDesktopWrapper
                                            className={classes.displayBlockMdUp}
                                        >
                                            <ImageFluid src={HeroSeat} alt={''} />
                                        </SeatHeaderDesktopWrapper>
                                        <ImageFluid
                                            className={classes.displayNoneMdUp}
                                            src={HeroSeatMobile}
                                            alt={''}
                                        />
                                    </>
                                )}
                            </Box>
                            {brand === 'CUPRA' && (
                                <>
                                    <Typography variant={'h1'}>Cupra Connect</Typography>
                                    <Typography variant={'h2'}>
                                        {t('general.page.consentConfirmation.subTitle')}
                                    </Typography>
                                </>
                            )}
                            {brand === 'SEAT' && (
                                <SeatHeaderDesktopTextWrapper>
                                    <Typography variant={'h1'}>SEAT Connect</Typography>
                                    <Typography variant={'h2'}>
                                        {t('general.page.consentConfirmation.subTitle')}
                                    </Typography>
                                </SeatHeaderDesktopTextWrapper>
                            )}
                            {consentData?.consents &&
                                // MSP-34083 for key === 'seatIdent' - ident disabled
                                // MSP-44558 ident hidden
                                // MSP-46741 psp and dms united to single checkbox
                                Object.entries(consentData.consents)
                                    .filter(
                                        ([key]) =>
                                            key !== 'seatIdent' &&
                                            key !== 'dealerMaintenanceScheduling'
                                    )
                                    .map(([key, value]) => (
                                        <FormControlLabel
                                            key={key}
                                            classes={{
                                                root:
                                                    key !== CONSENTS_MANDATORY_KEY
                                                        ? fontLabelPaddedClasses.root
                                                        : null,
                                            }}
                                            control={
                                                <Checkbox
                                                    checked={consentValues[key]}
                                                    onChange={(_e, checked) => {
                                                        setConsentValues(() => {
                                                            return key === PSP_KEY && !checked
                                                                ? {
                                                                      ...consentValues,
                                                                      [key]: checked,
                                                                      [DMS_KEY]: false,
                                                                  }
                                                                : {
                                                                      ...consentValues,
                                                                      [key]: checked,
                                                                  }
                                                        })
                                                    }}
                                                    disabled={
                                                        key === DMS_KEY && !consentValues[PSP_KEY]
                                                    }
                                                    color={'primary'}
                                                />
                                            }
                                            label={value.content}
                                        />
                                    ))}
                            <Box>
                                <Typography variant={'caption'}>
                                    {t('general.page.consentConfirmation.asterisk')}
                                </Typography>
                            </Box>
                            <Box mt={2}>
                                <Grid container>
                                    <Grid item>
                                        <ButtonWithSuffixWrapper>
                                            <Button
                                                variant={'outlined'}
                                                color={'primary'}
                                                onClick={submitData}
                                                disabled={
                                                    !consentValues[CONSENTS_MANDATORY_KEY] ||
                                                    submitStatus === rqStatusMap.IN_PROGRESS
                                                }
                                            >
                                                {t(`general.consentsconfirmation.saveconsents`)}
                                            </Button>
                                            {submitStatus === rqStatusMap.IN_PROGRESS && (
                                                <ButtonWithSuffixSuffix>
                                                    <CircularProgress size={'24px'} />
                                                </ButtonWithSuffixSuffix>
                                            )}
                                        </ButtonWithSuffixWrapper>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    )}
                    <Notification position={'TopRight'} />
                </ContainerRelative>
            ) : (
                <>
                    {rqStatus === rqStatusMap.IN_PROGRESS ? (
                        <Box py={13}>
                            <Grid container justifyContent={'center'}>
                                <CircularProgress />
                            </Grid>
                        </Box>
                    ) : (
                        <Container>
                            {brand === 'CUPRA' ? (
                                <>
                                    <CupraErrorHeroWrapper>
                                        <ImageFluid
                                            className={classes.displayBlockMdUp}
                                            src={HeroCupraError}
                                            alt={''}
                                        />
                                        <ImageFluid
                                            className={classes.displayNoneMdUp}
                                            src={HeroCupraErrorMobile}
                                            alt={''}
                                        />
                                    </CupraErrorHeroWrapper>
                                    <Grid container>
                                        <Grid item sm={8}>
                                            <Box mb={1}>
                                                <Typography variant={'h1'}>
                                                    {t('general.consents.hashErrorTitle')}
                                                </Typography>
                                            </Box>
                                            <Typography variant={'subtitle2'}>
                                                {t('general.consents.hashError')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    <SeatLogoComponent />
                                    <Box mb={3}>
                                        <ImageFluid src={HeroSeatError} alt={''} />
                                    </Box>
                                    <Grid container spacing={3}>
                                        <Grid item sm={8}>
                                            <Typography variant={'h1'}>
                                                {t('general.consents.hashErrorTitle')}
                                            </Typography>
                                        </Grid>
                                        <Grid item sm={4}>
                                            <Box my={2}>
                                                <Typography variant={'body1'}>
                                                    {t('general.consents.hashError')}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </Container>
                    )}
                </>
            )}
        </ThemeProvider>
    )
}

export default ConsentConfirmation
