import React, { Fragment } from 'react'
import styled from 'styled-components'
import InputTooltip from './Tooltip/InputTooltip'
import muiTheme from '../themes/muiTheme'
import { useTranslation } from 'react-i18next'
import { TRANSLATIONS_NAMESPACE } from '../constants/global'

const NavigationWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    margin-bottom: 30px;
`

const TabItem = styled.div`
    font-size: 14px;
    display: flex;
    letter-spacing: 1px;
    font-weight: 700;
    color: #808285;
    text-transform: uppercase;
    margin-right: 50px;
    text-align: center;
    cursor: pointer;
    position: relative;
    user-select: none;
     {
        ${({ active }) =>
            active &&
            `
	    color: black;
    `}
    }
    &:after {
        ${({ active }) =>
            active &&
            `
	    content: '';
	    background-color: ${muiTheme.palette.secondary.main};
	    position: absolute;
	    bottom: -8px;
	    left:0;
	    width: 100%;
	    height: 3px;
    `}
    }
`

const TabToolTip = styled.div`
    position: absolute;
    top: -6px;
    right: -20px;
`

const ErrorWrapper = styled.span`
    color: #ec1e1e;
`

export default function TabNavigation({ tabs, setActiveTab, errorSuffixes, activeTab = 0 }) {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)

    return (
        <NavigationWrapper>
            {tabs.map((tab, index) => (
                <Fragment key={`${index}-tab`}>
                    <TabItem onClick={() => setActiveTab(index)} active={index === activeTab}>
                        {t(tab.label)}
                        {((errorSuffixes && errorSuffixes[tab.suffixError] && tab.suffixError) ||
                            tab.suffixError === 0) && (
                            <>
                                :&nbsp;<ErrorWrapper>{errorSuffixes[tab.suffixError]}</ErrorWrapper>
                            </>
                        )}
                        <TabToolTip>
                            {tab.tooltip && <InputTooltip text={t(tab.tooltip)} size={'small'} />}
                        </TabToolTip>
                    </TabItem>
                </Fragment>
            ))}
        </NavigationWrapper>
    )
}
