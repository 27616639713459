import PopUp from './PopUp'
import React, { useRef, useState } from 'react'
import {
    ButtonWithSuffixSuffix,
    ButtonWithSuffixWrapper,
    Label,
    PrimaryButton,
    SecondaryButton,
} from '../Buttons'
import { CircularProgress, Grid } from '@material-ui/core'
import { Accounts } from '../../api'
import { useSelector } from 'react-redux'
import FormControl from '@material-ui/core/FormControl'
import { NOTIFICATION_TYPE_ERROR, NOTIFICATION_TYPE_SUCCESS } from 'react-redux-notify'
import { useDispatchNotification } from '../../hooks/useDispatchNotification'
import { useTranslation } from 'react-i18next'
import { ignoreKeyInHTML, TRANSLATIONS_NAMESPACE } from '../../constants/global'

export const UploadConsentDocument = ({
    isOpen,
    setIsUploadPopupOpen,
    setIsConsentDocumentUploaded,
}) => {
    const [consentDocument, setConsentDocument] = useState(null)
    const [isRqInProgress, setIsRqInProgress] = useState(false)
    const { customerInformation, orderDetails } = useSelector((state) => state.customer)
    const documentInput = useRef(null)
    const [dispatchNotification] = useDispatchNotification()

    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)

    const handleDocumentChange = () => {
        const formData = new FormData()
        formData.append('consentDocument', documentInput.current.files[0])
        setConsentDocument(formData.get('consentDocument'))
    }

    const onSubmitHandler = async () => {
        setIsRqInProgress(true)
        try {
            await Accounts.uploadConsentsDocument({
                email: customerInformation.email,
                file: consentDocument,
                brand: orderDetails.brand,
            })
            dispatchNotification({
                message: t('general.consentDocument.upload.success', { replace: ignoreKeyInHTML }),
                type: NOTIFICATION_TYPE_SUCCESS,
                duration: 3000,
                canDismiss: true,
            })
            setIsConsentDocumentUploaded(true)
            setIsUploadPopupOpen(false)
        } catch (e) {
            dispatchNotification({
                message: t('general.consentDocument.upload.error', { replace: ignoreKeyInHTML }),
                type: NOTIFICATION_TYPE_ERROR,
                duration: 3000,
                canDismiss: true,
            })
            console.error(e)
        }
        setIsRqInProgress(false)
    }

    return (
        <PopUp
            open={isOpen}
            title={t('general.order.button.uploadConsentDocument')}
            onClose={() => setIsUploadPopupOpen(false)}
            isFloatingWidth
            actions={
                <>
                    <Grid container spacing={3} wrap={'nowrap'}>
                        <Grid item>
                            <SecondaryButton
                                label={t('general.button.cancel')}
                                clickAction={() => setIsUploadPopupOpen(false)}
                            />
                        </Grid>
                        <Grid item>
                            <ButtonWithSuffixWrapper>
                                <PrimaryButton
                                    label={t('general.button.upload')}
                                    clickAction={onSubmitHandler}
                                    disabled={isRqInProgress || !consentDocument}
                                />
                                <ButtonWithSuffixSuffix>
                                    {isRqInProgress && <CircularProgress size={'24px'} />}
                                </ButtonWithSuffixSuffix>
                            </ButtonWithSuffixWrapper>
                        </Grid>
                    </Grid>
                </>
            }
        >
            <FormControl>
                <Label shrink>{t('general.consentDocument.label')}</Label>
                <input
                    type={'file'}
                    name={'consentDocument'}
                    ref={documentInput}
                    accept={'.pdf, application/pdf'}
                    onChange={handleDocumentChange}
                />
            </FormControl>
        </PopUp>
    )
}
