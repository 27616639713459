import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Commissions } from '../api'
import {
    GET_COMMISSIONS_ERROR,
    GET_COMMISSIONS_STARTED,
    GET_COMMISSIONS_SUCCESS,
} from '../types/reduxActionTypes'

export function useGetCommissions() {
    const dispatch = useDispatch()

    const getCommissions = useCallback(
        async (tabName, dateFrom, dateTo, customerStatus, vehicleStatus) => {
            dispatch({
                type: GET_COMMISSIONS_STARTED,
            })

            await Commissions.getCommissions({
                tabName,
                dateFrom: !!dateFrom ? dateFrom : null,
                dateTo: !!dateTo ? dateTo : null,
                customerStatus: customerStatus || null,
                vehicleStatus: vehicleStatus || null,
            })
                .then((response) => {
                    dispatch({
                        type: GET_COMMISSIONS_SUCCESS,
                        payload: {
                            commissions: response.data || null,
                        },
                    })
                })
                .catch((error) => {
                    console.log(error)
                    dispatch({
                        type: GET_COMMISSIONS_ERROR,
                    })
                })
        },
        [dispatch]
    )
    return [getCommissions]
}
