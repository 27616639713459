import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { ignoreKeyInHTML, TRANSLATIONS_NAMESPACE } from '../constants/global'
import { createNotification } from 'react-redux-notify'
import { useDispatch } from 'react-redux'

export function useDispatchNotification() {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const dispatch = useDispatch()

    const dispatchNotification = useCallback(
        (notification) => {
            const translatedMessage = {
                ...notification,
                message: t(notification.message, { replace: ignoreKeyInHTML }),
            }
            dispatch(createNotification(translatedMessage))
        },
        [dispatch, t]
    )

    return [dispatchNotification]
}
