import { showCreateNewCustomer } from '../actions/popupsActions'
import React from 'react'
import styled from 'styled-components'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { checkUrl, isRegistrationProcess } from '../util/util'
import { useTranslation } from 'react-i18next'
import { DEALER_ROLE, TRANSLATIONS_NAMESPACE } from '../constants/global'
import { useSelector } from 'react-redux'
import { useSaveInputChange } from '../hooks/form/useSaveInputChange'

const Menu = styled.div`
    display: flex;
    margin-left: 50px;
    align-items: center;
    flex: 1 0 auto;
`

const NavLink = styled.div`
    text-decoration: none;
    padding: 5px;
    margin-right: 30px;
    text-align: center;
    object-fit: contain;
    font-size: 16px;
    font-weight: ${(props) => (props.active ? '700' : '300')};
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #4a4a4a;
    &:hover {
        font-weight: 700;
        cursor: pointer;
    }
`

function MainNavLinks({ label, to, type, showPopup }) {
    const history = useHistory()
    const { setSearchParam } = useSaveInputChange()

    let match = useRouteMatch({
        path: to,
        exact: false,
    })

    const checkAndShowPopupForCreate = () => {
        if (!checkUrl('create')) {
            showPopup()
        }
    }

    const checkAndShowPopupForCustomers = (e) => {
        if (checkUrl('customers')) {
            e.preventDefault()
        } else {
            setSearchParam(null)
            history.push({
                pathname: to,
            })
        }
    }

    const getLink = (condition, onClick) => {
        if (condition) {
            return (
                <NavLink onClick={onClick} active={true}>
                    {label}
                </NavLink>
            )
        }
        return (
            <NavLink onClick={onClick} active={false}>
                {label}
            </NavLink>
        )
    }

    if (type !== 'createNewCustomer') {
        return getLink(match, checkAndShowPopupForCustomers)
    } else {
        return getLink(isRegistrationProcess(), checkAndShowPopupForCreate)
    }
}

export default function MainMenu() {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const { userInfo } = useSelector((state) => state.oidc)
    let mainMenuItems = [
        {
            label: 'general.header.dealershipCustomers',
            to: '/customers',
        },
    ]
    if (userInfo && userInfo.role === DEALER_ROLE) {
        mainMenuItems.push({
            label: 'general.header.createNewCustomer',
            to: '/create',
            type: 'createNewCustomer',
        })
    }

    return (
        <Menu>
            {mainMenuItems.map((menuItem, i) => {
                return (
                    <MainNavLinks
                        key={`nav-link-${i}`}
                        label={t(menuItem.label)}
                        to={menuItem.to}
                        activeOnlyWhenExact={true}
                        type={menuItem.type}
                        showPopup={showCreateNewCustomer}
                    />
                )
            })}
        </Menu>
    )
}
