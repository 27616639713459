import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import back from '../../assets/images/icon-back.svg'

const Wrapper = styled.div`
    margin-bottom: 29px;
    cursor: pointer;
`

export default function BackLinkArrow({ backLinkUrl, hideInPrint }) {
    const history = useHistory()

    const clickBackLink = useCallback(() => {
        history.push(backLinkUrl)
    }, [backLinkUrl, history])

    if (hideInPrint) {
        return (
            <div className="noprint">
                <Wrapper>
                    <img src={back} width={24} alt="back" onClick={clickBackLink} />
                </Wrapper>
            </div>
        )
    }
    return (
        <Wrapper>
            <img src={back} width={24} alt="back" onClick={clickBackLink} />
        </Wrapper>
    )
}
