import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 200px;
`

export default function FormWrapper({ children }) {
    return <Wrapper>{children}</Wrapper>
}
