import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import UserManagerHolder from '../../userManagerHolder'
import Loading from '../../components/Loading'
import { SET_CONTEXT_URI } from '../../types/reduxActionTypes'

export default function LoginHandler() {
    const history = useHistory()
    const dispatch = useDispatch()
    const { userInfo } = useSelector((state) => state.oidc)
    const isAuthenticated = userInfo !== null

    useEffect(() => {
        if (isAuthenticated) {
            history.push({
                pathname: '/customers',
            })
        } else {
            const loc = window.location.toString()
            if (loc.includes('?Target')) {
                dispatch({
                    type: SET_CONTEXT_URI,
                    payload: {
                        value: loc,
                    },
                })
                UserManagerHolder.getUserManager().signinRedirect()
            } else if (userInfo === null) {
                history.push({
                    pathname: '/not-logged-in',
                })
            }
        }
    }, [dispatch, history, isAuthenticated, userInfo])

    return <Loading />
}
