import { store } from '../index'
import {
    CLEAR_NEW_CUSTOMER,
    REMOVE_ACCOUNT_CHECK,
    SET_ACCESS_TO_CREATE_PROCESS,
    SET_CANCEL_NEW_CUSTOMER_ACTIVE,
    SET_CREATE_NEW_CUSTOMER_ACTIVE,
    SET_DEALER_POPUP_ACTIVE,
    SET_DELETE_PROFILE_ACTIVE,
    SET_HAS_SEAT_IDENT_ACTIVE,
    SET_LOGOUT_FROM_GRP_ACTIVE,
} from '../types/reduxActionTypes'
import { setOcwRegistrationId } from '../oidc/OidcSlice'

export const showCreateNewCustomer = () => {
    store.dispatch({
        type: REMOVE_ACCOUNT_CHECK,
    })
    store.dispatch({
        type: SET_CREATE_NEW_CUSTOMER_ACTIVE,
        payload: {
            value: true,
        },
    })
}

export const closeCreateNewCustomer = () => {
    store.dispatch({
        type: SET_CREATE_NEW_CUSTOMER_ACTIVE,
        payload: {
            value: false,
        },
    })
    store.dispatch(setOcwRegistrationId(null))
}

export const showCancelNewCustomer = (onYesUrl) => {
    store.dispatch({
        type: SET_CANCEL_NEW_CUSTOMER_ACTIVE,
        payload: {
            value: true,
            onYesUrl: onYesUrl,
        },
    })
}

export const closeCancelNewCustomer = () => {
    store.dispatch({
        type: SET_CANCEL_NEW_CUSTOMER_ACTIVE,
        payload: {
            value: false,
            onYesUrl: null,
        },
    })
}

export const onCancelNewCustomerConfirm = () => {
    store.dispatch({
        type: SET_CANCEL_NEW_CUSTOMER_ACTIVE,
        payload: {
            value: false,
            onYesUrl: null,
        },
    })
    store.dispatch({
        type: CLEAR_NEW_CUSTOMER,
    })
}

export const showHasSeatIdentPopup = () => {
    store.dispatch({
        type: SET_HAS_SEAT_IDENT_ACTIVE,
        payload: {
            value: true,
        },
    })
}

export const closeHasSeatIdentPopup = () => {
    store.dispatch({
        type: SET_HAS_SEAT_IDENT_ACTIVE,
        payload: {
            value: false,
        },
    })
}

export const showDealerProfilePopup = () => {
    store.dispatch({
        type: SET_DEALER_POPUP_ACTIVE,
        payload: {
            value: true,
        },
    })
}

export const closeDealerProfilePopup = () => {
    store.dispatch({
        type: SET_DEALER_POPUP_ACTIVE,
        payload: {
            value: false,
        },
    })
}

export const showLogoutFromGRPPopup = () => {
    store.dispatch({
        type: SET_LOGOUT_FROM_GRP_ACTIVE,
        payload: {
            value: true,
        },
    })
}

export const closeLogoutFromGRPPopup = () => {
    store.dispatch({
        type: SET_LOGOUT_FROM_GRP_ACTIVE,
        payload: {
            value: false,
        },
    })
}

export const allowAccessToCreateProcess = () => {
    store.dispatch({
        type: SET_ACCESS_TO_CREATE_PROCESS,
        payload: {
            value: true,
        },
    })
}

export const disallowAccessToCreateProcess = () => {
    store.dispatch({
        type: SET_ACCESS_TO_CREATE_PROCESS,
        payload: {
            value: false,
        },
    })
}

export const showDeleteProfilePopup = () => {
    store.dispatch({
        type: SET_DELETE_PROFILE_ACTIVE,
        payload: {
            value: true,
        },
    })
}

export const closeDeleteProfilePopup = () => {
    store.dispatch({
        type: SET_DELETE_PROFILE_ACTIVE,
        payload: {
            value: false,
        },
    })
}
