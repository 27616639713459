import React, { useCallback, useEffect, useState } from 'react'
import { validateEmailCreateNewCustomerPopup } from '../../util/validators'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { allowAccessToCreateProcess, closeCreateNewCustomer } from '../../actions/popupsActions'
import DealerProfilePopUp from './variants/DealerProfilePopUp'
import CustomerExistsInCBAPopUp from './variants/CustomerExistsInCBAPopUp'
import CustomerExistsInGroupIdPopUp from './variants/CustomerExistsInGroupIdPopUp'
import CreateNewCustomerPopUp from './variants/CreateNewCustomerPopUp'
import CancelNewCustomerPopUp from './variants/CancelNewCustomerPopUp'
import LogoutFromGRPPopUp from './variants/LogoutFromGRPPopUp'
import DeleteCustomerPopUp from './variants/DeleteCustomerPopUp'
import { SET_CUSTOMER_EMAIL } from '../../types/reduxActionTypes'
import { useCustomerActions } from '../../hooks/actions/useCustomerActions'
import { useSaveInputChange } from '../../hooks/form/useSaveInputChange'
import { useUpdateCustomerActions } from '../../hooks/actions/useUpdateCustomerActions'

export default function FullPagePopUp() {
    const [emailValue, setEmailValue] = useState('')
    const [emailError, setEmailError] = useState(null)
    const [emailLiveValidation, setEmailLiveValidation] = useState(false)

    const { accountCheck } = useSelector((state) => state.account)
    const { ocwRequestId } = useSelector((state) => state.oidc)
    const { customerInformation } = useSelector((state) => state.customer)
    const [customerExistsInCBA, setCustomerExistsInCBA] = useState(false)
    const [customerExistsInGID, setCustomerExistsInGID] = useState(false)
    const [newCustomer, setNewCustomer] = useState(false)
    const history = useHistory()
    const dispatch = useDispatch()
    const { checkCustomer } = useCustomerActions()
    const { setSearchParam } = useSaveInputChange()
    const {
        createNewCustomerActive,
        cancelNewCustomerActive,
        dealerProfilePopupActive,
        logoutFromGRPPopupActive,
        deleteProfilePopupActive,
    } = useSelector((state) => state.popups)
    const { clearCustomer } = useUpdateCustomerActions()

    useEffect(() => {
        if (!ocwRequestId && createNewCustomerActive) {
            clearCustomer()
            setEmailValue('')
        } else {
            setEmailValue(customerInformation.email)
        }
        setEmailError(null)
        setEmailLiveValidation(false)
        setCustomerExistsInCBA(false)
        setCustomerExistsInGID(false)
        setNewCustomer(false)
    }, [clearCustomer, createNewCustomerActive, customerInformation.email, ocwRequestId])

    const onConfirmNewUser = useCallback(
        (email) => {
            closeCreateNewCustomer()
            dispatch({
                type: SET_CUSTOMER_EMAIL,
                payload: {
                    email: email,
                },
            })
            allowAccessToCreateProcess()
            history.push({
                pathname: '/customer-information',
            })
        },
        [dispatch, history]
    )

    useEffect(() => {
        if (accountCheck !== null) {
            if (accountCheck.cbaAccount) {
                setCustomerExistsInCBA(true)
            } else if (accountCheck.connectAccount) {
                setCustomerExistsInGID(true)
            } else {
                setNewCustomer(true)
                if (createNewCustomerActive) {
                    onConfirmNewUser(emailValue)
                }
            }
        }
    }, [createNewCustomerActive, accountCheck, emailValue, onConfirmNewUser])

    const onCreateClick = useCallback(() => {
        let error = validateEmailCreateNewCustomerPopup(emailValue)
        if (error === null) {
            setSearchParam(null)
            checkCustomer(emailValue)
        } else {
            setEmailError(error)
            setEmailLiveValidation(true)
        }
    }, [checkCustomer, emailValue, setSearchParam])

    const changeValue = useCallback(
        (e) => {
            setEmailError(null)
            setEmailValue(e.target.value)
            if (emailLiveValidation) {
                setEmailError(validateEmailCreateNewCustomerPopup(e.target.value))
            }
        },
        [emailLiveValidation]
    )

    if (createNewCustomerActive) {
        if (customerExistsInCBA) {
            return <CustomerExistsInCBAPopUp emailValue={emailValue} />
        } else if (customerExistsInGID) {
            return <CustomerExistsInGroupIdPopUp emailValue={emailValue} />
        } else if (newCustomer) {
            return null
        } else {
            return (
                <CreateNewCustomerPopUp
                    emailValue={emailValue}
                    emailError={emailError}
                    setEmailError={setEmailError}
                    onCreateClick={onCreateClick}
                    changeValue={changeValue}
                />
            )
        }
    } else if (cancelNewCustomerActive) {
        return <CancelNewCustomerPopUp />
    } else if (logoutFromGRPPopupActive) {
        return <LogoutFromGRPPopUp />
    } else if (dealerProfilePopupActive) {
        return <DealerProfilePopUp />
    } else if (deleteProfilePopupActive) {
        return <DeleteCustomerPopUp />
    } else {
        return null
    }
}
