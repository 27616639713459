import './polyfills'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'
import configureStore, { history } from './configureStore'
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { OidcProvider, loadUser } from './oidc'
import ConfigurationHolder from './configurationHolder'
import UserManagerHolder from './userManagerHolder'
import './i18n'
import { setBasePath } from './api'

const initializeApplication = () => {
    fetch(`${window.location.origin}/config/config.json`).then(async (response) => {
        try {
            const responseJson = await response.json()
            const configuration = {
                ...responseJson,
                userManagerConfiguration: {
                    ...responseJson.userManagerConfiguration,
                    redirect_uri: `${window.location.origin}/login-callback`,
                    post_logout_redirect_uri: `${window.location.origin}/logout`,
                },
            }

            ConfigurationHolder.setConfiguration(configuration)
            setBasePath(ConfigurationHolder.getConfiguration().basePath)

            UserManagerHolder.initUserManager(
                ConfigurationHolder.getConfiguration().userManagerConfiguration
            )

            const userPromise = loadUser(store, UserManagerHolder.getUserManager())
            Promise.all([userPromise]).then(([user]) => {
                ReactDOM.render(
                    <Provider store={store}>
                        <OidcProvider
                            store={store}
                            userManager={UserManagerHolder.getUserManager()}
                        >
                            <ConnectedRouter history={history}>
                                <PersistGate loading={null} persistor={persistor}>
                                    <App />
                                </PersistGate>
                            </ConnectedRouter>
                        </OidcProvider>
                    </Provider>,
                    document.getElementById('root')
                )
            })
        } catch (error) {
            console.error(error)
            throw new Error('External configuration failed to load.')
        }
    })
}

const initialState = {}
export const { store, persistor } = configureStore(initialState)

initializeApplication()

serviceWorker.unregister()
