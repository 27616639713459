import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { customerReducer } from './reducers/customerReducer'
import { commissionsReducer } from './reducers/commissionsReducer'
import { accountReducer } from './reducers/accountReducer'
import { servicePartnerReducer } from './reducers/servicePartnerReducer'
import { optionsReducer } from './reducers/optionsReducer'
import { popupsReducer } from './reducers/popupsReducer'
import { authReducer } from './reducers/authReducer'
import notifyReducer from 'react-redux-notify'
import { oidcSlice } from './oidc/OidcSlice'
import { systemsAvailabilityReducer } from './reducers/systemsAvailabilityReducer'

const o = (history) =>
    combineReducers({
        router: connectRouter(history),
        oidc: oidcSlice.reducer,
        auth: authReducer,
        account: accountReducer,
        commissions: commissionsReducer,
        customer: customerReducer,
        options: optionsReducer,
        popups: popupsReducer,
        servicePartners: servicePartnerReducer,
        notifications: notifyReducer,
        systemsAvailability: systemsAvailabilityReducer,
    })

export default o
