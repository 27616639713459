import { responsiveFontSizes } from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles'
import '../assets/fontsCupra.scss'

const defaultTheme = createTheme()
const colorPrimary = '#95572b'
const fontFamilyPrimary = ['Helvetica', 'Arial', 'sans-serif'].join(',')
const fontFamilySecondary = ['Cupra', 'Helvetica', 'Arial', 'sans-serif'].join(',')

let cupraTheme = createTheme({
    palette: {
        primary: {
            main: colorPrimary,
        },
        text: {
            primary: colorPrimary,
        },
        background: {
            default: '#f8f8f8',
        },
    },
    typography: {
        fontFamily: fontFamilyPrimary,
        fontWeightRegular: 400,
        allVariants: {
            color: colorPrimary,
        },
        h1: {
            fontFamily: fontFamilySecondary,
            fontSize: 58,
            textTransform: 'uppercase',
            marginBottom: defaultTheme.spacing(2),
        },
        h2: {
            fontFamily: fontFamilySecondary,
            fontSize: 40,
            marginBottom: defaultTheme.spacing(4),
        },
        caption: {
            fontSize: 10,
        },
        subtitle2: {
            fontSize: 30,
            fontFamily: fontFamilySecondary,
            fontWeight: 300,
        },
    },
    props: {
        MuiInputLabel: {
            shrink: true,
        },
    },
    overrides: {
        MuiFormControlLabel: {
            root: {
                display: 'flex',
                alignItems: 'start',
                marginBottom: defaultTheme.spacing(2),
                marginLeft: 0,
            },
            label: {
                marginLeft: '0.5rem',
            },
        },
        MuiCheckbox: {
            root: {
                padding: 0,
                '&$checked': {
                    color: colorPrimary,
                },
            },
        },
        MuiButton: {
            root: {
                borderRadius: 5,
            },
            outlined: {
                borderWidth: '2px',
                '&:disabled': {
                    borderWidth: '2px',
                },
                '&:hover': {
                    borderWidth: '2px',
                },
            },
            outlinedPrimary: {
                border: `solid ${colorPrimary} 2px`,
                '&:disabled': {
                    borderWidth: '2px',
                },
                '&:hover': {
                    border: `solid ${colorPrimary} 2px`,
                },
            },
        },
        MuiFormControl: {
            root: {
                marginTop: defaultTheme.spacing(4),
                marginBottom: 0,
            },
        },
    },
})

cupraTheme = responsiveFontSizes(cupraTheme)

export default cupraTheme
