import React from 'react'
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import { SET_REQUESTED_SERVICES } from '../types/reduxActionTypes'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { DMS_KEY, PSP_KEY, TRANSLATIONS_NAMESPACE } from '../constants/global'

export const RequestedServicesForm = () => {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const dispatch = useDispatch()
    const { requestedServices } = useSelector((state) => state.customer)

    const setConsentGiven = (consent, value) => {
        // MSP-70080 PSP and DMS merged together, so setting DMS along with PSP
        if (consent === PSP_KEY) {
            dispatch({
                type: SET_REQUESTED_SERVICES,
                payload: {
                    ...requestedServices,
                    [PSP_KEY]: value,
                    [DMS_KEY]: value,
                },
            })
        } else {
            dispatch({
                type: SET_REQUESTED_SERVICES,
                payload: {
                    ...requestedServices,
                    [consent]: value,
                },
            })
        }
    }

    return (
        <>
            <Typography variant={'caption'}>
                {t(`general.customer.requestedServices.tooltip`)}
            </Typography>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={requestedServices.preferredServicePartner}
                        onChange={(_e, checked) => {
                            setConsentGiven(PSP_KEY, checked)
                        }}
                    />
                }
                label={t('general.order.serviceScheduling')}
            />
            {/*ident hidden MSP-47322*/}
            {/*<FormControlLabel*/}
            {/*    control={*/}
            {/*        <Checkbox*/}
            {/*            // MSP-34083*/}
            {/*            disabled={true}*/}
            {/*            checked={requestedServices.seatIdent}*/}
            {/*            onChange={(_e, checked) => setConsentGiven('seatIdent', checked)}*/}
            {/*        />*/}
            {/*    }*/}
            {/*    label={t('general.ident.title')}*/}
            {/*/>*/}
        </>
    )
}
