const userContextKey = 'userContext'

export function storeUserContext(sub, userContext) {
    localStorage.setItem(
        userContextKey,
        JSON.stringify({
            [sub]: userContext,
        })
    )
}

export function clearUserContext() {
    localStorage.removeItem(userContextKey)
}
