export default class ConfigurationHolder {
    static configuration = null

    static setConfiguration(configuration) {
        ConfigurationHolder.configuration = configuration
    }

    static getConfiguration() {
        return ConfigurationHolder.configuration
    }
}
