import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { closeDealerProfilePopup, showLogoutFromGRPPopup } from '../../../actions/popupsActions'
import { PrimaryButton, DownloadButton } from '../../Buttons'
import PopUp from '../PopUp'
import React from 'react'
import { REPORT_ADMINISTRATOR_ROLE, TRANSLATIONS_NAMESPACE } from '../../../constants/global'
import { DialogButtonsWrapper, DialogContentWrapper } from '../../ContentDialog'
import styled from 'styled-components'
import { useDownloadReportActions } from '../../../hooks/actions/useDownloadReportActions'

const Row = styled.div`
    display: flex;
    flex-direction: row;
    font-size: 18px;
    font-weight: 400;
    color: #4a4a4a;
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 10px;
`

const Col = styled.div`
    display: flex;
    &:first-child {
        margin-right: 5px;
    }
`

export default function DealerProfilePopUp() {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)

    const userInfo = useSelector((state) => state.oidc.userInfo)
    const { dealerProfilePopupActive } = useSelector((state) => state.popups)
    const { downloadReport } = useDownloadReportActions()

    const content = (
        <DialogContentWrapper>
            <Row>
                <Col>{userInfo.firstName}</Col>
                <Col>{userInfo.lastName}</Col>
            </Row>
        </DialogContentWrapper>
    )

    const download = () => {
        downloadReport()
        closeDealerProfilePopup()
    }

    const onLogout = () => {
        closeDealerProfilePopup()
        showLogoutFromGRPPopup()
    }

    const actions = (
        <DialogButtonsWrapper>
            {userInfo && userInfo.role === REPORT_ADMINISTRATOR_ROLE && (
                <DownloadButton
                    clickAction={download}
                    label={t('general.dealer.profile.download')}
                />
            )}
            <PrimaryButton clickAction={onLogout} label={t('general.header.profile.logout')} />
        </DialogButtonsWrapper>
    )

    const config = {
        title: t('general.dealer.profile.title'),
        type: 'DEALER_PROFILE',
        actions: actions,
        body: content,
    }

    return (
        <PopUp
            onClose={closeDealerProfilePopup}
            open={dealerProfilePopupActive}
            body={config.body}
            title={config.title}
            actions={config.actions}
            isOnRight
        />
    )
}
