import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { TRANSLATIONS_NAMESPACE } from '../constants/global'

export const Text = styled.div`
    font-size: 14px;
    line-height: 1.38;
    color: #808285;
`

export const Wrapper = styled.div`
    margin-top: ${(props) => (props.withTop ? '20px' : '0')};
    margin-bottom: ${(props) => (props.withBottom ? '20px' : '0')};
`

export default function RequiredBox({ withBottom, withTop }) {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)

    return (
        <Wrapper withTop={withTop} withBottom={withBottom}>
            <Text>{t('general.form.required.hint')}</Text>
        </Wrapper>
    )
}
