import { SET_AVAILABILITY_STATUS } from '../types/reduxActionTypes'

const initialState = []
const userSignedOutAction = 'oidc/userSignedOut'

export const systemsAvailabilityReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_AVAILABILITY_STATUS:
            return action.payload
        case userSignedOutAction:
            return initialState
        default:
            return state
    }
}
