import {
    GET_COMMISSIONS_ERROR,
    GET_COMMISSIONS_STARTED,
    GET_COMMISSIONS_SUCCESS,
    RESET_FILTER_PARAMS,
    SET_FILTER_PARAMS,
    SET_SEARCH_PARAM,
} from '../types/reduxActionTypes'

const defaultFilter = {
    dateFrom: null,
    dateTo: null,
    customerStatus: null,
    vehicleStatus: null,
}

const initialState = {
    loading: false,
    error: false,
    commissionsList: [],
    filter: defaultFilter,
    searchParam: null,
    failedCommissionsCount: 0,
}
const userSignedOutAction = 'oidc/userSignedOut'

export const commissionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_COMMISSIONS_STARTED:
            return {
                ...state,
                loading: true,
                error: false,
            }
        case GET_COMMISSIONS_SUCCESS:
            return {
                ...state,
                commissionsList: action.payload.commissions?.commissionList
                    ? action.payload.commissions.commissionList.map((item) => ({
                          ...item,
                          customerStatus: item.customerStatus.status,
                          vehicleStatus: item.vehicleStatus.status,
                          customerStatusFailed: item.customerStatus.failed,
                          vehicleStatusFailed: item.vehicleStatus.failed,
                      }))
                    : [],
                failedCommissionsCount: action.payload.commissions?.failedCommissionsCount || 0,
                loading: false,
                error: false,
            }
        case GET_COMMISSIONS_ERROR:
            return {
                ...state,
                commissionsList: [],
                loading: false,
                error: true,
            }
        case SET_FILTER_PARAMS:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    [action.payload.name]: action.payload.value,
                },
            }
        case RESET_FILTER_PARAMS:
            return {
                ...state,
                filter: defaultFilter,
            }
        case SET_SEARCH_PARAM:
            return {
                ...state,
                searchParam: action.payload.value,
            }
        case userSignedOutAction:
            return {
                ...initialState,
            }
        default:
            return state
    }
}
