// import Logger from 'js-logger';
import { userFound, userExpired, loadUserError, loadingUser } from '../OidcSlice'

// callback function called when the user has been loaded
function getUserCallback(user, reduxStore, tokenType) {
    if (user && !user.expired) {
        reduxStore.dispatch(
            userFound({
                tokenType,
                user,
            })
        )
    } else if (!user || (user && user.expired)) {
        reduxStore.dispatch(userExpired(tokenType))
    }
    return user
}

// error callback called when the userManager's loadUser() function failed
function errorCallback(error, reduxStore) {
    // console.error(`redux-oidc: Error in loadUser() function: ${error.message}`)
    reduxStore.dispatch(loadUserError())
}

// function to load the current user into the store
// NOTE: use only when silent renew is configured
export default function loadUser(store, userManager) {
    if (!store || !store.dispatch) {
        throw new Error('redux-oidc: You need to pass the redux store into the loadUser helper!')
    }

    if (!userManager || !userManager.getUser) {
        throw new Error('redux-oidc: You need to pass the userManager into the loadUser helper!')
    }

    const { tokenType } = userManager
    store.dispatch(loadingUser(tokenType))

    return userManager
        .getUser()
        .then((user) => getUserCallback(user, store, tokenType))
        .catch((err) => errorCallback(err, store, tokenType))
}
