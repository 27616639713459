import React from 'react'
import logFrontendError from '../util/frontendLogger'
import DateTime from 'luxon/src/datetime.js'
import { connect } from 'react-redux'
import Loading from './Loading'

const mapStateToProps = (state) => ({
    dealershipId: state.oidc.userInfo ? state.oidc.userInfo.dealershipId : '',
    dealerId: state.oidc.userInfo ? state.oidc.userInfo.username : '',
    role: state.oidc.userInfo ? state.oidc.userInfo.role : '',
    accessToken: state.oidc.user ? state.oidc.user.access_token : '',
    customerId: state.customer.id ? state.customer.id : '',
})

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { error: null, errorInfo: null }
    }

    static getDerivedStateFromError(error) {
        return { error }
    }

    componentDidCatch(error, errorInfo) {
        const { message, stack } = error
        const { accessToken, dealershipId, dealerId, role, customerId } = this.props

        const dt = DateTime.local().toString()
        const frontendLog = {
            message,
            stackTrace: stack,
            dealershipId,
            dealerId,
            role,
            customerId,
            browser: navigator.userAgent,
            dateTime: dt,
        }
        console.log(frontendLog)
        logFrontendError(frontendLog, accessToken)
        this.setState({
            error: error,
            errorInfo: errorInfo,
        })
        console.error(JSON.stringify(error))
        console.error(errorInfo)
    }

    reset = () => {
        this.setState({
            error: null,
            errorInfo: null,
            dateTime: null,
        })
    }

    render() {
        const { errorInfo } = this.state
        const { children } = this.props
        if (errorInfo) {
            window.location.pathname = '/error'
            this.reset()
            return <Loading />
        }
        return children
    }
}

export default connect(mapStateToProps, null)(ErrorBoundary)
