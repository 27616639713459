import {
    jws,
    KEYUTIL as KeyUtil,
    X509,
    crypto,
    hextob64u,
    b64tohex,
} from '../jsrsasign/jsrsasign.js'

const AllowedSigningAlgs = [
    'RS256',
    'RS384',
    'RS512',
    'PS256',
    'PS384',
    'PS512',
    'ES256',
    'ES384',
    'ES512',
]

export { jws, KeyUtil, X509, crypto, hextob64u, b64tohex, AllowedSigningAlgs }
