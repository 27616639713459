import React from 'react'
import { useHistory } from 'react-router-dom'
import {
    CUSTOMER_INFORMATION_STEP,
    NAVIGATION_BUTTONS_CONFIGURATION,
    ORDER_DETAILS_STEP,
} from '../constants/registrationConstants'
import { CancelButton, PrimaryButton, SecondaryButton } from './Buttons'
import { useDispatch, useSelector } from 'react-redux'
import { showCancelNewCustomer } from '../actions/popupsActions'
import { useTranslation } from 'react-i18next'
import { TRANSLATIONS_NAMESPACE } from '../constants/global'
import { REMOVE_ORDER_DETAILS_ERRORS } from '../types/reduxActionTypes'
import styled from 'styled-components'
import { countryPhoneCodes } from '../constants/countryPhoneCodesConfiguration'
import { useUpdateCustomerActions } from '../hooks/actions/useUpdateCustomerActions'

const Wrapper = styled.div`
    width: 500px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 40px auto 0;
`

const Back = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`

const Right = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 12px;
`

export default function RegistrationNavigationButtons({
    activeStep,
    onNextClick,
    isNextDisabled = false,
}) {
    const { accountCheck } = useSelector((state) => state.account)
    const buttonConfig = NAVIGATION_BUTTONS_CONFIGURATION[activeStep]
    let history = useHistory()
    const dispatch = useDispatch()
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const { customerInformation } = useSelector((state) => state.customer)
    const { countries } = useSelector((state) => state.options)
    const { saveSeatIdentField } = useUpdateCustomerActions()

    const handleNext = () => {
        onNextClick()
    }

    const handleBack = () => {
        if (activeStep === ORDER_DETAILS_STEP) {
            if (accountCheck !== null) {
                const selectedCountry = countries.find(
                    (c) => c.id === customerInformation.address?.countryId
                )
                if (selectedCountry) {
                    countryPhoneCodes.forEach((c, i) => {
                        if (c.code === selectedCountry.countryCode) {
                            saveSeatIdentField('countryOfIssueCode', countryPhoneCodes[i].code)
                        }
                    })
                }
                history.push({
                    pathname: buttonConfig.backButtonUrl,
                })
            }
            dispatch({
                type: REMOVE_ORDER_DETAILS_ERRORS,
            })
        } else {
            history.push({
                pathname: buttonConfig.backButtonUrl,
            })
        }
    }

    const handleCancel = () => {
        if (activeStep === CUSTOMER_INFORMATION_STEP) {
            showCancelNewCustomer('customers')
        }
    }

    return (
        <React.Fragment>
            <Wrapper>
                {buttonConfig.isBack && (
                    <Back>
                        <SecondaryButton
                            clickAction={handleBack}
                            label={t('general.button.back')}
                            isBack
                        />
                    </Back>
                )}
                {buttonConfig.isCancel && (
                    <SecondaryButton
                        clickAction={handleCancel}
                        label={t('general.button.cancel')}
                    />
                )}
                <Right>
                    <PrimaryButton
                        disabled={isNextDisabled}
                        clickAction={handleNext}
                        label={t(buttonConfig.buttonLabelKey)}
                    />
                    {buttonConfig.isCancelCustomer && (
                        <CancelButton
                            clickAction={handleCancel}
                            label={t('general.button.consents.cancel.customer')}
                        />
                    )}
                </Right>
            </Wrapper>
        </React.Fragment>
    )
}
