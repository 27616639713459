import React from 'react'
import styled from 'styled-components'
import skip from '../assets/images/icon-arrow-right-light.svg'
import back from '../assets/images/icon-back.svg'
import XIcon from './Icons/XIcon'
import muiTheme from '../themes/muiTheme'
import InputLabel from '@material-ui/core/InputLabel'

const Button = styled.div`
    font-family: 'Seat Bcn', sans-serif;
    width: 240px;
    height: 44px;
    border-radius: 22.5px;
    font-size: 12px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    margin-bottom: 1em;
    img {
        cursor: pointer;
    }
`
const Primary = styled(Button)`
    box-shadow: 0 2px 4px 0 rgba(75, 168, 46, 0.27), 0 6px 12px 0 rgba(75, 168, 46, 0.2);
    background-color: ${muiTheme.palette.secondary.main};
    color: #fff;
     {
        ${({ disabled }) =>
            disabled &&
            `
        background-color: #d5dadd;
        color: #818081;
        box-shadow: none;
    `}
    }
`

const PrimarySmall = styled(Button)`
    box-shadow: 0 2px 4px 0 rgba(75, 168, 46, 0.27), 0 6px 12px 0 rgba(75, 168, 46, 0.2);
    background-color: ${muiTheme.palette.secondary.main};
    color: #fff;
    width: 55px;
    margin-left: 20px;
    height: 31px;
    margin-bottom: 7px;
`
const Secondary = styled(Button)`
    width: 140px;
    border: solid 1px #c0c0c0;
    background-color: #fff;
    color: #4b4a4b;
    img {
        margin-right: 5px;
    }
`
const Skip = styled(Button)`
    display: flex;
    justify-content: flex-end;
    color: #4b4a4b;
    margin-top: 10px;
    background: none;
`

const Download = styled(Button)`
    display: flex;
    justify-content: center;
    color: #4b4a4b;
    margin-top: 10px;
    margin-bottom: 15px;
    background: none;
`

const Cancel = styled(Button)`
    width: 240px;
    border: none;
    margin-top: 20px;
    background: none;
`
const PrintLink = styled.div`
    text-decoration: none;
`
const Print = styled(Button)`
    border: solid 1px #c0c0c0;
    background-color: #fff;
    color: #4b4a4b;
    margin-left: 12px;
    text-decoration: none;
`
const Search = styled(Button)`
    box-shadow: 0 2px 4px 0 rgba(75, 168, 46, 0.27), 0 6px 12px 0 rgba(75, 168, 46, 0.2);
    background-color: ${muiTheme.palette.secondary.main};
    color: #fff;
    min-width: 100px;
    max-width: 100px;
    width: auto;
    height: 30px;
`

export function SkipButton({ clickAction, label }) {
    return (
        <Skip onClick={clickAction}>
            {label}
            <img src={skip} width={16} alt="skip" />
        </Skip>
    )
}

export function DownloadButton({ clickAction, label }) {
    return (
        <Download onClick={clickAction}>
            {label}
            <img src={skip} width={16} alt="download" />
        </Download>
    )
}

export function SecondaryButton({ clickAction, label, isBack }) {
    return (
        <Secondary onClick={clickAction}>
            {isBack && <img src={back} width={16} alt="back" />}
            {label}
        </Secondary>
    )
}

export function SearchButton({ clickAction, label }) {
    return <Search onClick={clickAction}>{label}</Search>
}

export function PrintButton({ label, handlePrint }) {
    return (
        <PrintLink onClick={handlePrint}>
            <Print>{label}</Print>
        </PrintLink>
    )
}

export function PrimaryButton({ clickAction, label, disabled }) {
    return (
        <Primary onClick={disabled ? null : clickAction} disabled={disabled}>
            {label}
        </Primary>
    )
}

export function XButton({ clickAction }) {
    return (
        <PrimarySmall onClick={clickAction}>
            <XIcon />
        </PrimarySmall>
    )
}

export function CancelButton({ clickAction, label }) {
    return <Cancel onClick={clickAction}>{label}</Cancel>
}

export const ButtonWithSuffixWrapper = styled('div')`
    position: relative;
`

export const ButtonWithSuffixSuffix = styled('div')`
    position: absolute;
    left: 100%;
    margin-left: 8px;
    top: 50%;
    transform: translateY(-50%);
`

export const Label = styled(InputLabel)`
    font-weight: 400;
`

export const ButtonSmallAutoFit = styled.button`
    font-family: 'SKODA Next', sans-serif;
    box-shadow: 0 2px 3px 0 rgba(75, 168, 46, 0.27), 0 6px 9px 0 rgba(75, 168, 46, 0.2);
    background-color: #4ba82e;
    color: #fff;
    border-radius: 22.5px;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    padding: 4px 8px;
`
