import { useTranslation } from 'react-i18next'
import { SecondaryButton, PrimaryButton } from '../../Buttons'
import PopUp from '../PopUp'
import React, { useCallback } from 'react'
import { TRANSLATIONS_NAMESPACE } from '../../../constants/global'
import { DialogButtonsWrapper, DialogContentTitle } from '../../ContentDialog'
import { Vehicles } from '../../../api'
import {
    cancelOrderSuccessMessage,
    somethingWentWrongErrorMessage,
} from '../../../types/reduxNotificationTypes'
import { useDispatchNotification } from '../../../hooks/useDispatchNotification'

export default function CancelOrderPopUp({
    customerId,
    vehicleId,
    getStatusesData,
    isOpen,
    setIsOpen,
}) {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const [dispatchNotification] = useDispatchNotification()

    const cancelOrder = useCallback(async () => {
        try {
            await Vehicles.deleteVehicleOrderData(vehicleId)
            getStatusesData(customerId)
            setIsOpen(false)
            dispatchNotification(cancelOrderSuccessMessage)
        } catch (error) {
            console.error(error)
            dispatchNotification(somethingWentWrongErrorMessage)
        }
    }, [customerId, vehicleId, dispatchNotification, getStatusesData, setIsOpen])

    const declineCancelOrder = () => {
        setIsOpen(false)
    }

    const content = <DialogContentTitle>{t('general.button.order.cancel')} ?</DialogContentTitle>

    const actions = (
        <DialogButtonsWrapper>
            <PrimaryButton
                clickAction={cancelOrder}
                label={t('general.customer.create.popup.yes')}
            />
            <SecondaryButton
                clickAction={declineCancelOrder}
                label={t('general.customer.create.popup.no')}
            />
        </DialogButtonsWrapper>
    )

    const config = {
        title: t('general.button.order.cancel'),
        type: 'CANCEL_ORDER',
        actions: actions,
        body: content,
    }
    return (
        <PopUp
            open={isOpen}
            body={config.body}
            title={config.title}
            actions={config.actions}
            onClose={declineCancelOrder}
        />
    )
}
