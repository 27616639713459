import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'
import { useHistory } from 'react-router'
import { useDispatchNotification } from '../useDispatchNotification'
import { Accounts, Commissions, Customers } from '../../api'
import {
    ADD_CUSTOMER_INFO_FROM_GROUP_ID,
    CHECK_ACCOUNT_ERROR,
    CHECK_ACCOUNT_STARTED,
    CHECK_ACCOUNT_SUCCESS,
    CLEAR_NEW_CUSTOMER,
    CREATE_CUSTOMER_ERROR,
    CREATE_CUSTOMER_STARTED,
    CREATE_CUSTOMER_SUCCESS,
    DELETE_CUSTOMER_ERROR,
    DELETE_CUSTOMER_STARTED,
    DELETE_CUSTOMER_SUCCESS,
    GET_CUSTOMER_ERROR,
    GET_CUSTOMER_STARTED,
    GET_CUSTOMER_STATUSES_ERROR,
    GET_CUSTOMER_STATUSES_STARTED,
    GET_CUSTOMER_STATUSES_SUCCESS,
    GET_CUSTOMER_SUCCESS,
    INIT_REGISTRATION_PROCESS,
} from '../../types/reduxActionTypes'
import {
    customerCreatedErrorMessage,
    customerCreatedSuccessMessage,
    customerDeletedErrorMessage,
    customerDeletedSuccessMessage,
} from '../../types/reduxNotificationTypes'
import { prepareCustomerForCreate } from '../../util/requestBodyCreators'
import { closeCreateNewCustomer } from '../../actions/popupsActions'

export function useCustomerActions() {
    const dispatch = useDispatch()
    const history = useHistory()
    const [dispatchNotification] = useDispatchNotification()
    const { userInfo } = useSelector((state) => state.oidc)
    const { servicePartners } = useSelector((state) => state.servicePartners)
    const { accountCheck } = useSelector((state) => state.account)

    const getCustomer = useCallback(
        async (customerId) => {
            dispatch({
                type: GET_CUSTOMER_STARTED,
            })
            return await Commissions.getCommission(customerId)
                .then((response) => {
                    dispatch({
                        type: GET_CUSTOMER_SUCCESS,
                        payload: {
                            customer: {
                                ...response.data,
                            },
                        },
                    })
                    dispatch({
                        type: CHECK_ACCOUNT_SUCCESS,
                        payload: {
                            accountCheck: createAccountCheck(response.data),
                        },
                    })
                })
                .catch((error) => {
                    console.log(error)
                    history.push({
                        pathname: '/error',
                    })
                    dispatch({
                        type: GET_CUSTOMER_ERROR,
                    })
                })
        },
        [dispatch, history]
    )

    const getCustomerStatuses = useCallback(
        async (customerId) => {
            dispatch({
                type: GET_CUSTOMER_STATUSES_STARTED,
            })
            return await Customers.getCustomerStatuses(customerId)
                .then((response) => {
                    dispatch({
                        type: GET_CUSTOMER_STATUSES_SUCCESS,
                        payload: {
                            statuses: response.data,
                        },
                    })
                })
                .catch((error) => {
                    console.log(error)
                    history.push({
                        pathname: '/error',
                    })
                    dispatch({
                        type: GET_CUSTOMER_STATUSES_ERROR,
                    })
                })
        },
        [dispatch, history]
    )

    const deleteCustomer = useCallback(
        async (customerId) => {
            dispatch({
                type: DELETE_CUSTOMER_STARTED,
            })
            return await Customers.deleteCustomer(customerId)
                .then(() => {
                    dispatch({
                        type: DELETE_CUSTOMER_SUCCESS,
                    })
                    dispatchNotification(customerDeletedSuccessMessage)
                })
                .catch((error) => {
                    console.log(error)
                    dispatch({
                        type: DELETE_CUSTOMER_ERROR,
                    })
                    dispatchNotification(customerDeletedErrorMessage)
                })
        },
        [dispatch, dispatchNotification]
    )

    const addMissingGroupIdData = (originalData) => {
        const dateOfBirth = new Date(originalData.dateOfBirth)
        return {
            ...originalData,
            email: originalData.email ?? '-',
            firstName: originalData.firstName ?? '-',
            surname: originalData.surname ?? '-',
            nickname: originalData.nickname ?? '-',
            phoneNumber: originalData.phoneNumber ?? '-',
            dateOfBirth: dateOfBirth.getDate() ? dateOfBirth : null,
        }
    }

    const getCustomerGroupIdData = useCallback(
        (email) => {
            dispatch({
                type: GET_CUSTOMER_STARTED,
            })
            Customers.getCustomerGroupIdData(email)
                .then((response) => {
                    dispatch({
                        type: ADD_CUSTOMER_INFO_FROM_GROUP_ID,
                        payload: {
                            customer: addMissingGroupIdData(response.data),
                        },
                    })
                })
                .catch((error) => {
                    console.log(error)
                    dispatch({
                        type: GET_CUSTOMER_ERROR,
                    })
                })
        },
        [dispatch]
    )

    const checkCustomer = useCallback(
        (email) => {
            dispatch({
                type: CHECK_ACCOUNT_STARTED,
            })
            Accounts.checkAccount(email)
                .then((response) => {
                    dispatch({
                        type: CHECK_ACCOUNT_SUCCESS,
                        payload: {
                            accountCheck: response.data,
                        },
                    })
                })
                .catch((error) => {
                    console.log(error)
                    closeCreateNewCustomer()
                    history.push({
                        pathname: '/error',
                    })
                    dispatch({
                        type: CHECK_ACCOUNT_ERROR,
                    })
                })
        },
        [dispatch, history]
    )

    const initializeRegistrationProcess = useCallback(
        (payload) => {
            dispatch({
                type: INIT_REGISTRATION_PROCESS,
                payload,
            })
        },
        [dispatch]
    )

    const createCustomer = useCallback(
        async (customer) => {
            dispatch({
                type: CREATE_CUSTOMER_STARTED,
            })
            const body = prepareCustomerForCreate(customer, servicePartners, accountCheck, userInfo)
            return await Commissions.createCommission(body)
                .then((result) => {
                    dispatch({
                        type: CREATE_CUSTOMER_SUCCESS,
                    })
                    dispatchNotification(customerCreatedSuccessMessage)
                    return result
                })
                .catch((error) => {
                    console.error(error)
                    dispatch({
                        type: CREATE_CUSTOMER_ERROR,
                    })
                    dispatchNotification(customerCreatedErrorMessage)
                    return error
                })
        },
        [accountCheck, dispatch, dispatchNotification, servicePartners, userInfo]
    )

    const clearCustomer = useCallback(async () => {
        dispatch({
            type: CLEAR_NEW_CUSTOMER,
        })
    }, [dispatch])

    const createAccountCheck = (data) => {
        return {
            groupIdAccount: data.seatGroupIdExistsFlag,
            cbaAccount: true,
            seatIdAccount: data.seatIdentExistsFlag,
            connectAccount: data.seatConnectExistsFlag,
        }
    }

    return {
        getCustomer,
        deleteCustomer,
        getCustomerGroupIdData,
        checkCustomer,
        initializeRegistrationProcess,
        createCustomer,
        getCustomerStatuses,
        clearCustomer,
    }
}
