import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import Loading from '../../components/Loading'
import ConfigurationHolder from '../../configurationHolder'

export default function LogoutHandler() {
    const { userInfo } = useSelector((state) => state.oidc)
    const { contextUri } = useSelector((state) => state.auth)
    const { logoutFromGRPPopupActive } = useSelector((state) => state.popups)
    const isAuthenticated = userInfo !== null

    useEffect(() => {
        if (!isAuthenticated && contextUri === null && !logoutFromGRPPopupActive) {
            localStorage.clear()
            window.open(ConfigurationHolder.getConfiguration().grpLogoutUrl)
            window.close()
        }
    }, [contextUri, isAuthenticated, logoutFromGRPPopupActive])

    return <Loading />
}
