export const dateFormats = {
    de: 'dd.MM.yyyy',
    en: 'dd/MM/yyyy',
    fr: 'dd/MM/yyyy',
    it: 'dd/MM/yyyy',
    bs: 'd.M.yyyy',
    cs: 'd.M.yyyy',
    nl: 'd-M-yyyy',
    bg: 'dd.M.yyyy',
    el: 'd/M/yyyy',
    da: 'dd-MM-yyyy',
    et: 'd.MM.yyyy',
    ru: 'dd.MM.yyyy',
    es: 'dd/MM/yyyy',
    fi: 'd.M.yyyy',
    hr: 'd.M.yyyy',
    hu: 'yyyy.MM.dd.',
    is: 'd.M.yyyy',
    lt: 'yyyy.MM.dd',
    lv: 'yyyy.MM.dd',
    mk: 'dd.MM.yyyy',
    no: 'dd.MM.yyyy',
    pl: 'yyyy-MM-dd',
    pt: 'dd-MM-yyyy',
    ro: 'dd.MM.yyyy',
    sr: 'd/M/yyyy',
    sv: 'yyyy-MM-dd',
    sl: 'd.M.yyyy',
    sk: 'd.M.yyyy',
    uk: 'dd.MM.yyyy',
}

export const defaultDateFormat = 'yyyy-MM-dd'
export const defaultCalendarFormat = 'dd/MM/yyyy'
