const withCommonAttributes = (object) => {
    return {
        ...object,

        getToday(gregorianTodayObject) {
            return gregorianTodayObject
        },

        toNativeDate(date) {
            return new Date(date.year, date.month - 1, date.day)
        },

        getMonthLength(date) {
            return new Date(date.year, date.month, 0).getDate()
        },

        transformDigit(digit) {
            return digit
        },

        nextMonth: '',
        previousMonth: '',
        openMonthSelector: '',
        openYearSelector: '',
        closeMonthSelector: '',
        closeYearSelector: '',
        defaultPlaceholder: '',
        from: '',
        to: '',
        digitSeparator: ',',
        yearLetterSkip: 0,
        isRtl: false,
        weekStartingIndex: 6,
    }
}

export const enNewLocale = withCommonAttributes({
    weekDays: [
        {
            name: 'Monday',
            short: 'M',
        },
        {
            name: 'Tuesday',
            short: 'T',
        },
        {
            name: 'Wednesday',
            short: 'W',
        },
        {
            name: 'Thursday',
            short: 'T',
        },
        {
            name: 'Friday',
            short: 'F',
        },
        {
            name: 'Saturday',
            short: 'S',
            isWeekend: true,
        },
        {
            name: 'Sunday',
            short: 'S',
            isWeekend: true,
        },
    ],
})

export const deNewLocale = withCommonAttributes({
    weekDays: [
        {
            name: 'Montag',
            short: 'Mo',
        },
        {
            name: 'Dienstag',
            short: 'Di',
        },
        {
            name: 'Mittwoch',
            short: 'Mi',
        },
        {
            name: 'Donnerstag',
            short: 'Do',
        },
        {
            name: 'Freitag',
            short: 'Fr',
        },
        {
            name: 'Samstag',
            short: 'Sa',
            isWeekend: true,
        },
        {
            name: 'Sonntag',
            short: 'So',
            isWeekend: true,
        },
    ],
})
export const frNewLocale = withCommonAttributes({
    weekDays: [
        {
            name: 'Lundi',
            short: 'lu',
        },
        {
            name: 'Mardi',
            short: 'ma',
        },
        {
            name: 'Mercredi',
            short: 'me',
        },
        {
            name: 'Jeudi',
            short: 'je',
        },
        {
            name: 'Vendredi',
            short: 've',
        },
        {
            name: 'Samedi',
            short: 'sa',
            isWeekend: true,
        },
        {
            name: 'Dimanche',
            short: 'di',
            isWeekend: true,
        },
    ],
})
export const itNewLocale = withCommonAttributes({
    weekDays: [
        {
            name: 'Lunedi',
            short: 'lun',
        },
        {
            name: 'Martedi',
            short: 'mar',
        },
        {
            name: 'Mercoledi',
            short: 'mer',
        },
        {
            name: 'Giovedi',
            short: 'gio',
        },
        {
            name: 'Venerdi',
            short: 'ven',
        },
        {
            name: 'Sabato',
            short: 'sab',
            isWeekend: true,
        },
        {
            name: 'Domenica',
            short: 'dom',
            isWeekend: true,
        },
    ],
})
export const bsNewLocale = withCommonAttributes({
    weekDays: [
        {
            name: 'Ponedeljak',
            short: 'pon',
        },
        {
            name: 'Utorak',
            short: 'uto',
        },
        {
            name: 'Sreda',
            short: 'sre',
        },
        {
            name: 'Četvrtak',
            short: 'čet',
        },
        {
            name: 'Petak',
            short: 'pet',
        },
        {
            name: 'Subota',
            short: 'sub',
            isWeekend: true,
        },
        {
            name: 'Nedelja',
            short: 'ned',
            isWeekend: true,
        },
    ],
})
export const csNewLocale = withCommonAttributes({
    weekDays: [
        {
            name: 'Pondělí',
            short: 'po',
        },
        {
            name: 'Úterý',
            short: 'út',
        },
        {
            name: 'Strěda',
            short: 'st',
        },
        {
            name: 'Čtvrtek',
            short: 'čt',
        },
        {
            name: 'Pátek',
            short: 'pá',
        },
        {
            name: 'Sobota',
            short: 'so',
            isWeekend: true,
        },
        {
            name: 'Neděle',
            short: 'ne',
            isWeekend: true,
        },
    ],
})
export const nlNewLocale = withCommonAttributes({
    weekDays: [
        {
            name: 'Maandag',
            short: 'ma',
        },
        {
            name: 'Dinsdag',
            short: 'di',
        },
        {
            name: 'Woensdag',
            short: 'wo',
        },
        {
            name: 'Donderdag',
            short: 'do',
        },
        {
            name: 'Vrijdag',
            short: 'vr',
        },
        {
            name: 'Zaterdag',
            short: 'za',
            isWeekend: true,
        },
        {
            name: 'Zondag',
            short: 'zo',
            isWeekend: true,
        },
    ],
})
export const bgNewLocale = withCommonAttributes({
    weekDays: [
        {
            name: 'понеделник',
            short: 'пн',
        },
        {
            name: 'вторник',
            short: 'вт',
        },
        {
            name: 'сряда',
            short: 'ср',
        },
        {
            name: 'четвъртък',
            short: 'чт',
        },
        {
            name: 'петък',
            short: 'пт',
        },
        {
            name: 'събота',
            short: 'сб',
            isWeekend: true,
        },
        {
            name: 'неделя',
            short: 'нд',
            isWeekend: true,
        },
    ],
})
export const elNewLocale = withCommonAttributes({
    weekDays: [
        {
            name: 'Δευτέρα',
            short: 'δε',
        },
        {
            name: 'Τρίτη',
            short: 'τρ',
        },
        {
            name: 'Τετάρτη',
            short: 'τε',
        },
        {
            name: 'Πέμπτη',
            short: 'πε',
        },
        {
            name: 'Παρασκευή',
            short: 'πα',
        },
        {
            name: 'Σάββατο',
            short: 'σα',
            isWeekend: true,
        },
        {
            name: 'Κυριακή',
            short: 'κυ',
            isWeekend: true,
        },
    ],
})
export const daNewLocale = withCommonAttributes({
    weekDays: [
        {
            name: 'Mandag',
            short: 'ma',
        },
        {
            name: 'Tirsdag',
            short: 'ti',
        },
        {
            name: 'Onsdag',
            short: 'on',
        },
        {
            name: 'Torsdag',
            short: 'to',
        },
        {
            name: 'Fredag',
            short: 'fr',
        },
        {
            name: 'Lørdag',
            short: 'lø',
            isWeekend: true,
        },
        {
            name: 'Søndag',
            short: 'sø',
            isWeekend: true,
        },
    ],
})
export const etNewLocale = withCommonAttributes({
    weekDays: [
        {
            name: 'Esmaspäev',
            short: 'E',
        },
        {
            name: 'Teisipäev',
            short: 'T',
        },
        {
            name: 'Kolmapäev',
            short: 'K',
        },
        {
            name: 'Neljapäev',
            short: 'N',
        },
        {
            name: 'Reede',
            short: 'R',
        },
        {
            name: 'Laupäev',
            short: 'L',
            isWeekend: true,
        },
        {
            name: 'Pühapäev',
            short: 'P',
            isWeekend: true,
        },
    ],
})
export const ruNewLocale = withCommonAttributes({
    weekDays: [
        {
            name: 'понедельник',
            short: 'пн',
        },
        {
            name: 'вторник',
            short: 'вт',
        },
        {
            name: 'среда',
            short: 'ср',
        },
        {
            name: 'четверг',
            short: 'чт',
        },
        {
            name: 'пятница',
            short: 'пт',
        },
        {
            name: 'суббота',
            short: 'сб',
            isWeekend: true,
        },
        {
            name: 'воскресенье',
            short: 'вс',
            isWeekend: true,
        },
    ],
})
export const esNewLocale = withCommonAttributes({
    weekDays: [
        {
            name: 'Lunes',
            short: 'lu',
        },
        {
            name: 'Martes',
            short: 'ma',
        },
        {
            name: 'Miercoles',
            short: 'mi',
        },
        {
            name: 'Jueves',
            short: 'ju',
        },
        {
            name: 'Viernes',
            short: 'vi',
        },
        {
            name: 'Sabado',
            short: 'sa',
            isWeekend: true,
        },
        {
            name: 'Domingo',
            short: 'do',
            isWeekend: true,
        },
    ],
})
export const fiNewLocale = withCommonAttributes({
    weekDays: [
        {
            name: 'Maanantai',
            short: 'ma',
        },
        {
            name: 'Tiistai',
            short: 'ti',
        },
        {
            name: 'Keskiviikko',
            short: 'ke',
        },
        {
            name: 'Torstai',
            short: 'to',
        },
        {
            name: 'Perjantai',
            short: 'pe',
        },
        {
            name: 'Lauantai',
            short: 'la',
            isWeekend: true,
        },
        {
            name: 'Sunnuntai',
            short: 'su',
            isWeekend: true,
        },
    ],
})
export const hrNewLocale = withCommonAttributes({
    weekDays: [
        {
            name: 'Ponedjeljak',
            short: 'pon',
        },
        {
            name: 'Utorak',
            short: 'uto',
        },
        {
            name: 'Srijeda',
            short: 'sri',
        },
        {
            name: 'Četvrtak',
            short: 'čet',
        },
        {
            name: 'Petak',
            short: 'pet',
        },
        {
            name: 'Subota',
            short: 'sub',
            isWeekend: true,
        },
        {
            name: 'Nedjelja',
            short: 'ned',
            isWeekend: true,
        },
    ],
})
export const huNewLocale = withCommonAttributes({
    weekDays: [
        {
            name: 'hétfő',
            short: 'H',
        },
        {
            name: 'kedd',
            short: 'K',
        },
        {
            name: 'szerda',
            short: 'Sze',
        },
        {
            name: 'csütörtök',
            short: 'Cs',
        },
        {
            name: 'péntek',
            short: 'P',
        },
        {
            name: 'szombat',
            short: 'Szo',
            isWeekend: true,
        },
        {
            name: 'vasárnap',
            short: 'V',
            isWeekend: true,
        },
    ],
})
export const isNewLocale = withCommonAttributes({
    weekDays: [
        {
            name: 'mánudagur',
            short: 'Má',
        },
        {
            name: 'þriðjudagur',
            short: 'þr',
        },
        {
            name: 'miðvikudagur',
            short: 'Mi',
        },
        {
            name: 'fimmtudagur',
            short: 'Fi',
        },
        {
            name: 'föstudagur',
            short: 'Fö',
        },
        {
            name: 'laugardagur',
            short: 'La',
            isWeekend: true,
        },
        {
            name: 'sunnudagur',
            short: 'Su',
            isWeekend: true,
        },
    ],
})
export const ltNewLocale = withCommonAttributes({
    weekDays: [
        {
            name: 'Pirmadienis',
            short: 'Pr',
        },
        {
            name: 'Antradienis',
            short: 'An',
        },
        {
            name: 'Trečiadienis',
            short: 'Tr',
        },
        {
            name: 'Ketvirtadienis',
            short: 'Kt',
        },
        {
            name: 'Penktadienis',
            short: 'Pn',
        },
        {
            name: 'Šeštadienis',
            short: 'Št',
            isWeekend: true,
        },
        {
            name: 'Sekmadienis',
            short: 'Sk',
            isWeekend: true,
        },
    ],
})
export const lvNewLocale = withCommonAttributes({
    weekDays: [
        {
            name: 'pirmdiena',
            short: 'P',
        },
        {
            name: 'otrdiena',
            short: 'O',
        },
        {
            name: 'trešdiena',
            short: 'T',
        },
        {
            name: 'ceturtdiena',
            short: 'C',
        },
        {
            name: 'piektdiena',
            short: 'Pk',
        },
        {
            name: 'sestdiena',
            short: 'S',
            isWeekend: true,
        },
        {
            name: 'svētdiena',
            short: 'Sv',
            isWeekend: true,
        },
    ],
})
export const mkNewLocale = withCommonAttributes({
    weekDays: [
        {
            name: 'понеделник',
            short: 'по',
        },
        {
            name: 'вторник',
            short: 'вт',
        },
        {
            name: 'среда',
            short: 'ср',
        },
        {
            name: 'четврток',
            short: 'че',
        },
        {
            name: 'петок',
            short: 'пе',
        },
        {
            name: 'сабота',
            short: 'са',
            isWeekend: true,
        },
        {
            name: 'недела',
            short: 'не',
            isWeekend: true,
        },
    ],
})
export const noNewLocale = withCommonAttributes({
    weekDays: [
        {
            name: 'Måndag',
            short: 'må',
        },
        {
            name: 'Tysdag',
            short: 'ty',
        },
        {
            name: 'Onsdag',
            short: 'on',
        },
        {
            name: 'Torsdag',
            short: 'to',
        },
        {
            name: 'Fredag',
            short: 'fr',
        },
        {
            name: 'Laurdag',
            short: 'lau',
            isWeekend: true,
        },
        {
            name: 'Sundag',
            short: 'su',
            isWeekend: true,
        },
    ],
})
export const plNewLocale = withCommonAttributes({
    weekDays: [
        {
            name: 'poniedziałek',
            short: 'pon',
        },
        {
            name: 'wtorek',
            short: 'wto',
        },
        {
            name: 'środa',
            short: 'śro',
        },
        {
            name: 'czwartek',
            short: 'czw',
        },
        {
            name: 'piątek',
            short: 'pią',
        },
        {
            name: 'sobota',
            short: 'sob',
            isWeekend: true,
        },
        {
            name: 'niedziela',
            short: 'nie',
            isWeekend: true,
        },
    ],
})
export const ptNewLocale = withCommonAttributes({
    weekDays: [
        {
            name: 'segunda-feira',
            short: 'seg',
        },
        {
            name: 'terça-feira',
            short: 'ter',
        },
        {
            name: 'quarta-feira',
            short: 'qua',
        },
        {
            name: 'quinta-feira',
            short: 'qui',
        },
        {
            name: 'sexta-feira',
            short: 'sex',
        },
        {
            name: 'sábado',
            short: 'sáb',
            isWeekend: true,
        },
        {
            name: 'domingo',
            short: 'dom',
            isWeekend: true,
        },
    ],
})
export const roNewLocale = withCommonAttributes({
    weekDays: [
        {
            name: 'Luni',
            short: 'lu',
        },
        {
            name: 'Marţi',
            short: 'ma',
        },
        {
            name: 'Miercuri',
            short: 'mi',
        },
        {
            name: 'Joi',
            short: 'jo',
        },
        {
            name: 'Vineri',
            short: 'vi',
        },
        {
            name: 'Sâmbătă',
            short: 'sâ',
            isWeekend: true,
        },
        {
            name: 'Duminica',
            short: 'du',
            isWeekend: true,
        },
    ],
})
export const srNewLocale = withCommonAttributes({
    weekDays: [
        {
            name: 'понедељак',
            short: 'пон',
        },
        {
            name: 'уторак',
            short: 'уто',
        },
        {
            name: 'среда',
            short: 'сре',
        },
        {
            name: 'четвртак',
            short: 'чет',
        },
        {
            name: 'петак',
            short: 'пет',
        },
        {
            name: 'субота',
            short: 'суб',
            isWeekend: true,
        },
        {
            name: 'недеља',
            short: 'нед',
            isWeekend: true,
        },
    ],
})
export const svNewLocale = withCommonAttributes({
    weekDays: [
        {
            name: 'måndag',
            short: 'må',
        },
        {
            name: 'tisdag',
            short: 'ti',
        },
        {
            name: 'onsdag',
            short: 'on',
        },
        {
            name: 'torsdag',
            short: 'to',
        },
        {
            name: 'fredag',
            short: 'fr',
        },
        {
            name: 'lördag',
            short: 'lö',
            isWeekend: true,
        },
        {
            name: 'söndag',
            short: 'sö',
            isWeekend: true,
        },
    ],
})
export const slNewLocale = withCommonAttributes({
    weekDays: [
        {
            name: 'ponedeljek',
            short: 'pon.',
        },
        {
            name: 'torek',
            short: 'tor.',
        },
        {
            name: 'sreda',
            short: 'sre.',
        },
        {
            name: 'četrtek',
            short: 'čet.',
        },
        {
            name: 'petek',
            short: 'pet.',
        },
        {
            name: 'sobota',
            short: 'sob.',
            isWeekend: true,
        },
        {
            name: 'nedelja',
            short: 'ned.',
            isWeekend: true,
        },
    ],
})
export const skNewLocale = withCommonAttributes({
    weekDays: [
        {
            name: 'Pondelok',
            short: 'po',
        },
        {
            name: 'Utorok',
            short: 'ut',
        },
        {
            name: 'Streda',
            short: 'st',
        },
        {
            name: 'Štvrtok',
            short: 'št',
        },
        {
            name: 'Piatok',
            short: 'pi',
        },
        {
            name: 'Sobota',
            short: 'so',
            isWeekend: true,
        },
        {
            name: 'Nedeľa',
            short: 'ne',
            isWeekend: true,
        },
    ],
})
export const ukNewLocale = withCommonAttributes({
    weekDays: [
        {
            name: 'понеділок',
            short: 'пн',
        },
        {
            name: 'вівторок',
            short: 'вт',
        },
        {
            name: 'середа',
            short: 'ср',
        },
        {
            name: 'четвер',
            short: 'чт',
        },
        {
            name: `пятниця`,
            short: 'пт',
        },
        {
            name: 'субота',
            short: 'сб',
            isWeekend: true,
        },
        {
            name: 'неділя§',
            short: 'нд',
            isWeekend: true,
        },
    ],
})
