import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    ignoreKeyInHTML,
    rqStatusMap,
    SUPPORTED_LANGUAGES_CULTURES,
    TRANSLATIONS_NAMESPACE,
} from '../../constants/global'
import { getDefaultTranslations, getTranslations } from '../../actions/i18nActions'
import { useSelector } from 'react-redux'
import { useChangeLanguage } from '../../hooks/useChangeLanguage'
import { Accounts } from '../../api'
import {
    Box,
    CircularProgress,
    FormHelperText,
    Grid,
    MenuItem,
    Select,
    Typography,
    FormControl,
    InputLabel,
    ThemeProvider,
    CssBaseline,
    Container,
    Button,
    Input,
} from '@material-ui/core'
import DoneIcon from '@material-ui/icons/Check'
import { defaultCountryId, INPUT_LONG } from '../../constants/defaultFormValues'
import DatePicker from '../../components/Datepicker/DatePicker'
import { ButtonWithSuffixSuffix, ButtonWithSuffixWrapper } from '../../components/Buttons'
import { isCustomerInformationDataValid } from '../../util/util'
import { formatDateIntoYYYYMMDDWithDashes } from '../../util/formatters'
import { getOptions } from '../../actions/optionsActions'
import { getErrorText, validateCustomerInformation } from '../../util/validators'
import DownloadAppBox from '../../components/DownloadAppBox'
import { updateErrorMessage } from '../../types/reduxNotificationTypes'
import { useDispatchNotification } from '../../hooks/useDispatchNotification'
import cupraTheme from '../../themes/cupraTheme'
import defaultTheme from '../../themes/muiTheme'
import seatTheme from '../../themes/seatTheme'
import { countryPhoneCodes } from '../../constants/countryPhoneCodesConfiguration'

export default function ThankYouPageOld() {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)

    const { translations, countries, loading } = useSelector((state) => state.options)
    const { setLanguage } = useChangeLanguage()
    const [customerData, setCustomerData] = useState(null)
    const [getDataRqStatus, setGetDataRqStatus] = useState(rqStatusMap.INITIAL)
    const [setDataRqStatus, setSetDataRqStatus] = useState(rqStatusMap.INITIAL)
    const formId = 'thank-you-form'
    const [brand, setBrand] = useState(null)
    const [lang, setLang] = useState(null)
    const [errors, setErrors] = useState({})
    const [dispatchNotification] = useDispatchNotification()

    const submitData = async () => {
        const validationErrors = validateCustomerInformation(customerData)
        setErrors(validationErrors)
        const isValid = isCustomerInformationDataValid(validationErrors)
        if (isValid) {
            setSetDataRqStatus(rqStatusMap.IN_PROGRESS)
            const dataToSubmit = {
                uri: window.location.toString(),
                customerInformation: {
                    ...customerData,
                    dialCode: customerData.dialCode || null,
                    phoneNumber:
                        customerData.dialCode && customerData.phoneNumber
                            ? customerData.phoneNumber
                            : null,
                },
            }
            try {
                await Accounts.confirmCustomerData(dataToSubmit)
                setSetDataRqStatus(rqStatusMap.SUCCESS)
            } catch (e) {
                console.error(e)
                setSetDataRqStatus(rqStatusMap.ERROR)
                dispatchNotification(updateErrorMessage)
            }
        }
    }

    const handleChangeData = (e) => {
        e.persist()
        setCustomerData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }

    const handleChangeDate = (event) => {
        if (event instanceof Date) {
            const convertedDate = isNaN(event) ? event : formatDateIntoYYYYMMDDWithDashes(event)
            setCustomerData((prevState) => ({
                ...prevState,
                dateOfBirth: convertedDate,
            }))
        } else {
            setCustomerData((prevState) => ({
                ...prevState,
                dateOfBirth: null,
            }))
        }
    }

    const handleChangeAddressField = (e) => {
        e.persist()
        setCustomerData((prevState) => ({
            ...prevState,
            address: {
                ...prevState.address,
                [e.target.name]: e.target.value,
            },
        }))
    }

    useEffect(() => {
        getOptions()
        const getCustomerData = async () => {
            setGetDataRqStatus(rqStatusMap.IN_PROGRESS)
            const uriParsed = `${window.location.origin}${
                window.location.pathname
            }#${encodeURIComponent(window.location.hash.substring(1))}`

            try {
                const response = await Accounts.getUserDataAfterRegistration({
                    uri: uriParsed,
                })
                const responseData = response.data
                setLang(responseData.customerInformation?.preferredLanguage)
                setBrand(responseData.brand)
                setCustomerData(responseData.customerInformation)
                setGetDataRqStatus(rqStatusMap.SUCCESS)
            } catch (e) {
                setGetDataRqStatus(rqStatusMap.ERROR)
                console.error(e)
            }
        }
        getCustomerData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (lang && SUPPORTED_LANGUAGES_CULTURES[lang]) {
            getTranslations([SUPPORTED_LANGUAGES_CULTURES[lang]])
            setLanguage(SUPPORTED_LANGUAGES_CULTURES[lang])
        } else {
            if (translations && Object.keys(translations).length > 0) {
                setLanguage(Object.keys(translations)[0])
            } else {
                getDefaultTranslations()
            }
        }
    }, [lang, loading, translations, setLanguage])

    return (
        <ThemeProvider
            theme={brand === 'CUPRA' ? cupraTheme : brand === 'SEAT' ? seatTheme : defaultTheme}
        >
            <CssBaseline />
            <Container>
                {(getDataRqStatus === rqStatusMap.IN_PROGRESS || loading) && (
                    <Box mt={5}>
                        <Grid container justifyContent={'center'}>
                            <CircularProgress />
                        </Grid>
                    </Box>
                )}
                {getDataRqStatus === rqStatusMap.ERROR && (
                    <Box mt={5}>
                        <Grid container alignItems={'center'} direction={'column'}>
                            <Box mb={3}>
                                <Typography variant={'h2'}>
                                    {t('general.page.thankYou.title')}
                                </Typography>
                            </Box>
                            <Typography variant={'body1'}>
                                {t('general.page.thankYou.error')}
                            </Typography>
                        </Grid>
                    </Box>
                )}
                {getDataRqStatus === rqStatusMap.SUCCESS && (
                    <>
                        {setDataRqStatus !== rqStatusMap.SUCCESS && (
                            <>
                                <Box mt={4}>
                                    <Typography variant={'h1'}>
                                        {t('general.page.thankYou.title')}
                                    </Typography>
                                    <Typography variant={'h2'}>
                                        {t('general.page.thankYou.formTitle')}
                                    </Typography>
                                </Box>
                                <form noValidate autoComplete="off">
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <Input
                                                name={'email'}
                                                label={t('general.customer.email')}
                                                value={customerData.email}
                                                fullWidth
                                                disabled
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl fullWidth>
                                                <InputLabel shrink required>
                                                    {t('general.customer.firstName')}
                                                </InputLabel>
                                                <Input
                                                    onChange={handleChangeData}
                                                    name={'firstName'}
                                                    value={customerData.firstName || ''}
                                                    maxLength={INPUT_LONG}
                                                />
                                                {errors.firstName && (
                                                    <FormHelperText error>
                                                        {
                                                            <>
                                                                {t('general.customer.firstName')}{' '}
                                                                {t(getErrorText(errors.firstName))}
                                                            </>
                                                        }
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl fullWidth>
                                                <InputLabel shrink required>
                                                    {t('general.customer.surname')}
                                                </InputLabel>
                                                <Input
                                                    onChange={handleChangeData}
                                                    name={'surname'}
                                                    value={customerData.surname || ''}
                                                    maxLength={INPUT_LONG}
                                                />
                                                {errors.surname && (
                                                    <FormHelperText error>
                                                        {
                                                            <>
                                                                {t('general.customer.surname')}{' '}
                                                                {t(getErrorText(errors.surname))}
                                                            </>
                                                        }
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl fullWidth>
                                                <InputLabel shrink required>
                                                    {t('general.customer.nickname')}
                                                </InputLabel>
                                                <Input
                                                    onChange={handleChangeData}
                                                    name={'nickname'}
                                                    value={customerData.nickname || ''}
                                                    maxLength={INPUT_LONG}
                                                />
                                            </FormControl>
                                            {errors.nickname && (
                                                <FormHelperText error>
                                                    {
                                                        <>
                                                            {t('general.customer.nickname')}{' '}
                                                            {t(getErrorText(errors.nickname))}
                                                        </>
                                                    }
                                                </FormHelperText>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl fullWidth>
                                                <InputLabel shrink>
                                                    {t('general.ident.birthDate')}
                                                </InputLabel>
                                                <DatePicker
                                                    formId={formId}
                                                    name={'dateOfBirth'}
                                                    value={customerData.dateOfBirth}
                                                    handleChange={handleChangeDate}
                                                    tooltipText={t('general.datepicker.tooltip')}
                                                />
                                            </FormControl>
                                            {errors.dateOfBirth && (
                                                <FormHelperText error>
                                                    {
                                                        <>
                                                            {t('general.ident.birthDate')}{' '}
                                                            {t(getErrorText(errors.dateOfBirth))}
                                                        </>
                                                    }
                                                </FormHelperText>
                                            )}
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl
                                                fullWidth
                                                error={errors?.address?.countryId}
                                            >
                                                <InputLabel shrink required>
                                                    {t('general.customer.country')}
                                                </InputLabel>
                                                <Select
                                                    onChange={(e) => {
                                                        handleChangeAddressField(e)
                                                        setCustomerData((prevState) => ({
                                                            ...prevState,
                                                            preferredLanguage: '',
                                                        }))
                                                    }}
                                                    name={'countryId'}
                                                    value={
                                                        customerData.address?.countryId ||
                                                        defaultCountryId
                                                    }
                                                >
                                                    <MenuItem value={'0'} disabled>
                                                        {t('general.select.choose')}
                                                    </MenuItem>
                                                    {countries.map((item) => (
                                                        <MenuItem value={item.id} key={item.id}>
                                                            {t(
                                                                `general.country.${item.countryCode}`
                                                            )}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {errors?.address?.countryId && (
                                                    <FormHelperText error={true}>
                                                        {
                                                            <>
                                                                {t('general.customer.country')}{' '}
                                                                {t(
                                                                    getErrorText(
                                                                        errors.address?.countryId
                                                                    )
                                                                )}
                                                            </>
                                                        }
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            {!!(customerData?.address?.countryId && countries) && (
                                                <FormControl
                                                    fullWidth
                                                    error={!!errors.preferredLanguage}
                                                >
                                                    <InputLabel shrink required>
                                                        {t('general.customer.preferredLanguage')}
                                                    </InputLabel>
                                                    <Select
                                                        fullWidth
                                                        onChange={handleChangeData}
                                                        name={'preferredLanguage'}
                                                        label={t(
                                                            'general.customer.preferredLanguage'
                                                        )}
                                                        value={customerData.preferredLanguage || ''}
                                                        error={!!errors.preferredLanguage}
                                                        required
                                                    >
                                                        {(
                                                            countries
                                                                .find(
                                                                    (country) =>
                                                                        country.id.toString() ===
                                                                        customerData.address?.countryId.toString()
                                                                )
                                                                ?.languages?.map((item) => ({
                                                                    value: item.toString(),
                                                                    label: t(
                                                                        `general.language.${item}`
                                                                    ),
                                                                })) || []
                                                        ).map((item) => (
                                                            <MenuItem
                                                                value={item.value}
                                                                key={item.value}
                                                            >
                                                                {item.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    {errors?.preferredLanguage && (
                                                        <FormHelperText error={true}>
                                                            {
                                                                <>
                                                                    {t(
                                                                        'general.customer.preferredLanguage'
                                                                    )}{' '}
                                                                    {t(
                                                                        getErrorText(
                                                                            errors.preferredLanguage
                                                                        )
                                                                    )}
                                                                </>
                                                            }
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>
                                            )}
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={4}>
                                            <FormControl fullWidth>
                                                <InputLabel
                                                    shrink
                                                    required={!!customerData.phoneNumber}
                                                >
                                                    {t('general.customer.countryCode')}
                                                </InputLabel>
                                                <Select
                                                    onChange={handleChangeData}
                                                    name={'dialCode'}
                                                    value={customerData.dialCode ?? ''}
                                                    renderValue={(value) => value}
                                                >
                                                    <MenuItem value={''}>
                                                        {t('general.select.choose')}
                                                    </MenuItem>
                                                    {countryPhoneCodes
                                                        .map((item) => ({
                                                            ...item,
                                                            translatedLabel: t(
                                                                `general.country.${item.code}`,
                                                                { replace: ignoreKeyInHTML }
                                                            ),
                                                        }))
                                                        .sort((a, b) =>
                                                            a.translatedLabel.localeCompare(
                                                                b.translatedLabel,
                                                                lang
                                                            )
                                                        )
                                                        .map((item, index) => (
                                                            <MenuItem
                                                                value={item.dial_code}
                                                                key={index}
                                                            >
                                                                {item.translatedLabel} (
                                                                {item.dial_code})
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                                {errors.dialCode && (
                                                    <FormHelperText error>
                                                        {
                                                            <>
                                                                {t('general.customer.countryCode')}{' '}
                                                                {t(getErrorText(errors.dialCode))}
                                                            </>
                                                        }
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={8}>
                                            <FormControl fullWidth>
                                                <InputLabel
                                                    shrink
                                                    required={!!customerData.dialCode}
                                                >
                                                    {t('general.customer.phoneNumber')}
                                                </InputLabel>
                                                <Input
                                                    onChange={handleChangeData}
                                                    name={'phoneNumber'}
                                                    value={customerData.phoneNumber || ''}
                                                    maxLength={INPUT_LONG}
                                                />
                                                {errors.phoneNumber && (
                                                    <FormHelperText error>
                                                        {
                                                            <>
                                                                {t('general.customer.phoneNumber')}{' '}
                                                                {t(
                                                                    getErrorText(errors.phoneNumber)
                                                                )}
                                                            </>
                                                        }
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container justifyContent={'center'}>
                                        <Grid item>
                                            <Box mt={4}>
                                                <Typography variant={'caption'}>
                                                    {t('general.form.required.hint')}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Box mt={2} mb={8}>
                                        <Grid container justifyContent={'center'}>
                                            <Grid item>
                                                <ButtonWithSuffixWrapper>
                                                    <Button
                                                        size={'large'}
                                                        color={'primary'}
                                                        variant={'outlined'}
                                                        onClick={submitData}
                                                        disabled={
                                                            setDataRqStatus ===
                                                            rqStatusMap.IN_PROGRESS
                                                        }
                                                    >
                                                        {t('general.page.thankYou.submit')}
                                                    </Button>
                                                    <ButtonWithSuffixSuffix>
                                                        {setDataRqStatus ===
                                                            rqStatusMap.IN_PROGRESS && (
                                                            <CircularProgress size={'24px'} />
                                                        )}
                                                    </ButtonWithSuffixSuffix>
                                                </ButtonWithSuffixWrapper>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </form>
                            </>
                        )}
                        {setDataRqStatus === rqStatusMap.SUCCESS && (
                            <Grid
                                container
                                alignItems={'center'}
                                justifyContent={'center'}
                                spacing={2}
                            >
                                <Grid item md={8}>
                                    <Box my={4}>
                                        <Grid container justifyContent={'center'}>
                                            <Grid item>
                                                <DoneIcon fontSize={'large'} />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box mb={4}>
                                        <Typography variant={'h3'} align={'center'}>
                                            {t('general.page.thankYou.success.title')}
                                        </Typography>
                                    </Box>
                                    <Box mb={7}>
                                        <Typography variant={'body1'} align={'center'}>
                                            {t(
                                                brand === 'SEAT'
                                                    ? 'general.thankYou.app.line1'
                                                    : 'general.thankYou.app.line2'
                                            )}
                                        </Typography>
                                    </Box>

                                    <Box mb={2}>
                                        <Typography variant={'body1'} align={'center'}>
                                            {t('general.page.thankYou.app.downloadOrScanCode')}
                                        </Typography>
                                    </Box>
                                    {brand === 'SEAT' && (
                                        <DownloadAppBox
                                            appleLink={
                                                'https://apps.apple.com/cz/app/seat-connect-app/id1474078442'
                                            }
                                            googleLink={
                                                'https://play.google.com/store/apps/details?id=com.seat.connectedcar.mod2connectapp'
                                            }
                                            qrImage={'qr-seat-connect.png'}
                                        />
                                    )}
                                    {brand === 'CUPRA' && (
                                        <>
                                            <Grid container spacing={4} justifyContent={'center'}>
                                                <Grid item>
                                                    <DownloadAppBox
                                                        description={t(
                                                            'general.page.thankYou.app.install.MyCupra'
                                                        )}
                                                        appleLink={
                                                            'https://apps.apple.com/cz/app/my-cupra-app/id1576693756'
                                                        }
                                                        googleLink={
                                                            'https://play.google.com/store/apps/details?id=com.cupra.mycupra'
                                                        }
                                                        qrImage={'qr-my-cupra.png'}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <DownloadAppBox
                                                        description={t(
                                                            'general.page.thankYou.app.install.CupraConnect'
                                                        )}
                                                        appleLink={
                                                            'https://apps.apple.com/cz/app/cupra-connect-app/id1528964918'
                                                        }
                                                        googleLink={
                                                            'https://play.google.com/store/apps/details?id=com.cupra.connectedcar.mod2connectapp'
                                                        }
                                                        qrImage={'qr-cupra-connect.png'}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                        )}
                    </>
                )}
            </Container>
        </ThemeProvider>
    )
}
