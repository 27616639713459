import React, { useEffect, useRef, useState } from 'react'
import 'react-modern-calendar-datepicker/lib/DatePicker.css'
import './theme.scss'
import { Calendar } from 'react-modern-calendar-datepicker'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import InputTooltipWithLabel from '../../components/InputTooltipWithLabel'
import {
    formatDateFromDatePicker,
    formatDateIntoDatePicker,
    getDateFromDatePicker,
    getDateFromManualInput,
} from '../../util/formatters'
import { createLocaleWithMonths, getDateFormat } from '../../util/util'
import { useSelector } from 'react-redux'
import { EMPTY, getErrorText, INVALID_DATE } from '../../util/validators'
import FormHelperText from '@material-ui/core/FormHelperText'
import { useTranslation } from 'react-i18next'
import { ignoreKeyInHTML, TRANSLATIONS_NAMESPACE } from '../../constants/global'
import InputAdornment from '@material-ui/core/InputAdornment'
import DatePickerIcon from '../Icons/DatePickerIcon'
import { XButton } from '../Buttons'
import { DateTime } from 'luxon'
import muiTheme from '../../themes/muiTheme'
import { Icon, Input, Tooltip } from '@material-ui/core'
import CalendarIcon from '@material-ui/icons/Event'
import HintIcon from '@material-ui/icons/HelpOutline'

const CalendarWrapper = styled.div`
    ${(props) => (props.open ? `display: flex;` : `display: none`)}
`

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: 18px;
    flex-direction: column;
    position: relative;
`

const TextInputHelper = styled(FormHelperText)`
    text-align: right;
`

const Col = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    div {
        margin-left: 0;
    }
`

export default function DatePicker({
    formId,
    handleChange,
    label,
    value,
    error,
    oneOfDates,
    tooltipText,
    disabled,
    maximumDay,
    minimumDay,
    noTooltip,
    mandatory,
    lighter,
}) {
    const [selectedDay, setSelectedDay] = useState(null)
    const [lastValidDate, setLastValidDate] = useState(null)
    const [open, setOpen] = useState(false)
    const { selectedLanguage } = useSelector((state) => state.options)
    const dateFormat = getDateFormat(selectedLanguage)
    const isError = error !== null && error !== undefined
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const ref = useRef(null)
    const locale = createLocaleWithMonths(t, selectedLanguage)
    const handleClickOutside = (e) => {
        if (ref && ref.current && !ref.current.contains(e.target)) {
            setOpen(false)
        }
    }

    const onKeyPress = (e) => {
        if (e.key === 'Escape') {
            setOpen(false)
        }
    }

    useEffect(() => {
        if (disabled) {
            setOpen(false)
        }
    }, [disabled])

    useEffect(() => {
        window.addEventListener('keyup', onKeyPress)
        window.addEventListener('mousedown', handleClickOutside)
        return () => {
            setOpen(false)
            window.removeEventListener('keyup', onKeyPress)
            window.removeEventListener('mousedown', handleClickOutside)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (value && value.toString() !== 'Invalid Date') {
            const formattedDate = formatDateIntoDatePicker(value)
            setSelectedDay(formattedDate)
            setLastValidDate(formattedDate)
        }
        if (!value) {
            setSelectedDay(null)
        }
    }, [value])

    const setDate = (date) => {
        setSelectedDay(date)
        setOpen(false)
        handleChange(getDateFromDatePicker(date))
    }

    const setDateFromManualInput = (date) => {
        if (date.trim().length === 0) {
            setDate(null)
            return
        }
        setSelectedDay(date)
        const formattedDate = getDateFromManualInput(date, dateFormat)

        if (formattedDate && formattedDate.invalid === null) {
            handleChange(
                new Date(
                    formattedDate.c.year,
                    formattedDate.c.month - 1,
                    formattedDate.c.day,
                    0,
                    0,
                    0,
                    0
                )
            )
        } else {
            handleChange(new Date('invalid'))
        }
    }

    const disabledStyle = { opacity: 1, cursor: 'default !important' }
    const lighterStyle = { opacity: 0.5, cursor: 'default !important' }
    const style = lighter ? lighterStyle : disabled ? disabledStyle : {}

    const getTooltip = () => {
        if (noTooltip) {
            return undefined
        } else {
            if (tooltipText !== undefined) {
                let delimiter = ' '
                const lastChar = tooltipText.substr(-1)
                if (lastChar !== '.' && lastChar !== '?' && lastChar !== '!') {
                    delimiter = '. '
                }
                return (
                    tooltipText +
                    delimiter +
                    t('general.datepicker.tooltip', { replace: ignoreKeyInHTML })
                )
            } else {
                return t('general.datepicker.tooltip')
            }
        }
    }

    const toggleCalendar = () => {
        if (!disabled) {
            setOpen(!open)
        }
    }
    const openCalendar = () => {
        if (!disabled && !open) {
            setOpen(true)
        }
    }

    const renderInput = () => {
        const renderedTooltip = getTooltip()

        return (
            <TextField
                readOnly={false}
                style={style}
                value={formatDateFromDatePicker(selectedDay, dateFormat)}
                onClick={openCalendar}
                onChange={(event) => {
                    setDateFromManualInput(event.target.value)
                }}
                label={
                    <InputTooltipWithLabel
                        labelText={label}
                        tooltipText={renderedTooltip}
                        mandatory={mandatory}
                    />
                }
                error={isError}
                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <DatePickerIcon onClick={toggleCalendar} />
                        </InputAdornment>
                    ),
                    disabled: disabled,
                }}
            />
        )
    }

    const renderFooter = () => {
        if (selectedDay === null) {
            return null
        }
        const reset = () => {
            setSelectedDay(null)
            setOpen(false)
            handleChange(null)
        }
        return (
            <div style={{ display: 'flex', justifyContent: 'center', padding: '1rem 2rem' }}>
                <Col>
                    <XButton clickAction={reset} />
                </Col>
            </div>
        )
    }

    const getCalendarDate = () => {
        if (selectedDay?.year) {
            return selectedDay
        }
        if (selectedDay === null) {
            return null
        }

        try {
            const date = DateTime.fromFormat(selectedDay, dateFormat)
            if (date.invalid) {
                return lastValidDate
            } else {
                const year = date.year
                const month = date.month
                const day = date.day
                return {
                    year,
                    month,
                    day,
                }
            }
        } catch (err) {
            return null
        }
    }

    // variant with label is using material-ui TextField component including also label
    return label ? (
        <Wrapper ref={ref}>
            {renderInput()}
            {/* format hint */}
            {!disabled && open && (
                <TextInputHelper id="component-error-text">
                    {t('general.datepicker.formatHint')} {dateFormat.toLowerCase()}
                </TextInputHelper>
            )}
            {/* regular validation */}
            {oneOfDates === undefined && isError && INVALID_DATE !== error && (
                <TextInputHelper id="component-error-text" error={isError}>
                    {label} {t(getErrorText(error))}
                </TextInputHelper>
            )}
            {/* validation in group of inputs */}
            {oneOfDates === false && isError && EMPTY === error && (
                <TextInputHelper id="component-error-text" error={isError}>
                    {t('general.validation.2dates')}
                </TextInputHelper>
            )}
            {/* validation of invalid date after manual input */}
            {isError && INVALID_DATE === error && (
                <TextInputHelper id="component-error-text" error={isError}>
                    {label} {t(getErrorText(error))}
                </TextInputHelper>
            )}
            {/* validation of future/past */}
            {oneOfDates === true && isError && EMPTY !== error && INVALID_DATE !== error && (
                <TextInputHelper id="component-error-text" error={isError}>
                    {label} {t(getErrorText(error))}
                </TextInputHelper>
            )}
            {/* validation in group of inputs with future/past */}
            {oneOfDates === false && isError && EMPTY !== error && INVALID_DATE !== error && (
                <TextInputHelper id="component-error-text" error={isError}>
                    {label} {t(getErrorText(error))}
                </TextInputHelper>
            )}
            {locale && (
                <CalendarWrapper open={open}>
                    <Calendar
                        formId={formId}
                        value={getCalendarDate()}
                        onChange={setDate}
                        shouldHighlightWeekends
                        locale={locale}
                        calendarClassName={
                            isError
                                ? 'calendar-wrapper calendar-wrapper--with-error'
                                : 'calendar-wrapper'
                        }
                        calendarPopperPosition={'bottom'}
                        renderFooter={renderFooter}
                        colorPrimary={muiTheme.palette.secondary.main}
                        maximumDate={maximumDay}
                        minimumDate={minimumDay}
                    />
                </CalendarWrapper>
            )}
        </Wrapper>
    ) : (
        // variant without label is using simple Input component,
        // label and error messages are outside of a Datepicker component
        <>
            <Input
                value={formatDateFromDatePicker(selectedDay, dateFormat)}
                onClick={openCalendar}
                onChange={(event) => {
                    setDateFromManualInput(event.target.value)
                }}
                startAdornment={
                    <InputAdornment position="start">
                        <Icon onClick={toggleCalendar}>
                            <CalendarIcon />
                        </Icon>
                    </InputAdornment>
                }
                disabled={disabled}
                endAdornment={
                    tooltipText ? (
                        <InputAdornment position={'end'}>
                            <Tooltip title={tooltipText}>
                                <HintIcon fontSize={'small'} />
                            </Tooltip>
                        </InputAdornment>
                    ) : null
                }
            />
            {/* format hint */}
            {!disabled && open && (
                <TextInputHelper id="component-error-text">
                    {t('general.datepicker.formatHint')} {dateFormat.toLowerCase()}
                </TextInputHelper>
            )}
            {locale && (
                <CalendarWrapper open={open}>
                    <Calendar
                        formId={formId}
                        value={getCalendarDate()}
                        onChange={setDate}
                        shouldHighlightWeekends
                        locale={locale}
                        calendarClassName={
                            isError
                                ? 'calendar-wrapper calendar-wrapper--with-error'
                                : 'calendar-wrapper'
                        }
                        calendarPopperPosition={'bottom'}
                        renderFooter={renderFooter}
                        colorPrimary={muiTheme.palette.secondary.main}
                        maximumDate={maximumDay}
                        minimumDate={minimumDay}
                    />
                </CalendarWrapper>
            )}
        </>
    )
}
