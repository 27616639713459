import React from 'react'
import { store } from './../index'
import UserManagerHolder from './../userManagerHolder'
import {
    sessionTerminated,
    silentRenewError,
    userExpired,
    userExpiring,
    userFound,
    userSignedOut,
} from './OidcSlice'

class OidcProvider extends React.Component {
    componentDidMount() {
        // register the event callbacks
        const userManager = UserManagerHolder.getUserManager()
        if (userManager) {
            userManager.events.addUserLoaded(this.onUserLoaded)
            userManager.events.addSilentRenewError(this.onSilentRenewError)
            userManager.events.addAccessTokenExpired(this.onAccessTokenExpired)
            userManager.events.addAccessTokenExpiring(this.onAccessTokenExpiring)
            userManager.events.addUserUnloaded(this.onUserUnloaded)
            userManager.events.addUserSignedOut(this.onUserSignedOut)
        }
    }

    componentWillUnmount() {
        // unregister the event callbacks
        const userManager = UserManagerHolder.getUserManager()
        if (userManager) {
            userManager.events.removeUserLoaded(this.onUserLoaded)
            userManager.events.removeSilentRenewError(this.onSilentRenewError)
            userManager.events.removeAccessTokenExpired(this.onAccessTokenExpired)
            userManager.events.removeAccessTokenExpiring(this.onAccessTokenExpiring)
            userManager.events.removeUserUnloaded(this.onUserUnloaded)
            userManager.events.removeUserSignedOut(this.onUserSignedOut)
        }
    }

    // event callback when the user has been loaded (on silent renew or redirect)
    onUserLoaded = (user) => {
        store.dispatch(
            userFound({
                tokenType: 'WRAPPER',
                user,
            })
        )
    }

    // event callback when silent renew errored
    onSilentRenewError = () => {
        store.dispatch(silentRenewError('WRAPPER'))
    }

    // event callback when the access token expired
    onAccessTokenExpired = () => {
        store.dispatch(userExpired('WRAPPER'))
    }

    // event callback when the user is logged out
    onUserUnloaded = () => {
        store.dispatch(sessionTerminated('WRAPPER'))
    }

    // event callback when the user is expiring
    onAccessTokenExpiring = () => {
        store.dispatch(userExpiring())
    }

    // event callback when the user is signed out
    onUserSignedOut = () => {
        store.dispatch(userSignedOut('WRAPPER'))
    }

    render() {
        const { children } = this.props
        return React.Children.only(children)
    }
}

export default OidcProvider
