import { push } from 'connected-react-router'
import { WebStorageStateStore } from './oidc/client/WebStorageStateStore'
import { clearUserContext } from './oidc/userContext'
import { createUserManager } from './oidc'
import { Users, setAccessToken, setIdToken } from './api'
import { DEFAULT_CULTURE } from './constants/global'

export default class UserManagerHolder {
    static userManager = null

    static initUserManager(configuration) {
        UserManagerHolder.userManager = createUserManager({
            client_id: configuration.client_id,
            redirect_uri: configuration.redirect_uri,
            response_type: configuration.response_type,
            scope: configuration.scope,
            authority: configuration.authority,
            automaticSilentRenew: configuration.automaticSilentRenew,
            filterProtocolClaims: configuration.filterProtocolClaims,
            loadUserInfo: configuration.loadUserInfo,
            post_logout_redirect_uri: configuration.post_logout_redirect_uri,
            prompt: configuration.prompt,
            userStore: new WebStorageStateStore(),
        })

        UserManagerHolder.userManager.tokenType = 'WRAPPER'
    }

    static signInRedirect(args) {
        this.userManager.metadataService.getMetadata().then((meta) => {
            const uiLocale = this.getLocale(meta)
            this.userManager.signinRedirect({
                ui_locales: uiLocale,
                ...args,
            })
        })
    }

    static signOut(dispatch, destination = '/') {
        localStorage.clear()

        Users.logout().catch((error) => {
            console.error(error)
        })

        // Terminates app session, but not GRP session. Next login must be performed with prompt=login to allow user to login
        UserManagerHolder.clear().then(() => {
            // Move to login screen after termination
            dispatch(push(destination))
        })
    }

    static clear() {
        const { userManager } = UserManagerHolder
        const wrapper = (userManager && userManager.removeUser()) || Promise.resolve()
        clearUserContext()
        setAccessToken(null)
        setIdToken(null)
        return Promise.all([wrapper])
    }

    static getUserManager() {
        return UserManagerHolder.userManager
    }

    static getLocale() {
        return DEFAULT_CULTURE
    }
}
