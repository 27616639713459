import beak from '../../assets/images/beak-down.svg'
import React from 'react'
import styled from 'styled-components'

const Icon = styled.div`
    padding-bottom: 5px;
    cursor: pointer;
    position: absolute;
    right: ${(props) => (props.isInput ? '8px' : '0')};
    top: calc(50% - 12px);
    pointer-events: none;
`

export function BeakIcon({ isInput }) {
    return (
        <Icon isInput={isInput}>
            <img src={beak} alt="beak" width={16} />
        </Icon>
    )
}
