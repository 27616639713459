import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { getCustomerFromEmail } from '../../../util/util'
import { closeCreateNewCustomer } from '../../../actions/popupsActions'
import { CancelButton, SecondaryButton } from '../../Buttons'
import PopUp from '../PopUp'
import React, { useEffect } from 'react'
import { TRANSLATIONS_NAMESPACE } from '../../../constants/global'
import { WarningIcon } from '../../Icons/WarningIcon'
import { DialogButtonsWrapper, DialogContentTitle, DialogContentWrapper } from '../../ContentDialog'
import { useCustomerActions } from '../../../hooks/actions/useCustomerActions'
import { useSaveInputChange } from '../../../hooks/form/useSaveInputChange'
import { useServicePartnersActions } from '../../../hooks/actions/useServicePartnerActions'
import { useGetCommissions } from '../../../hooks/useGetCommissions'
import { defaultTab } from '../../../constants/defaultFormValues'

export default function CustomerExistsInCBAPopUp({ emailValue }) {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const history = useHistory()
    const { getCustomer } = useCustomerActions()
    const { setSearchParam } = useSaveInputChange()
    const { removePsp } = useServicePartnersActions()
    const { createNewCustomerActive } = useSelector((state) => state.popups)
    const { commissionsList } = useSelector((state) => state.commissions)
    const { customerInformation } = useSelector((state) => state.customer)
    const [getCommissions] = useGetCommissions()

    const handleViewDetail = () => {
        if (commissionsList && commissionsList.length) {
            const customer = getCustomerFromEmail(
                emailValue || customerInformation.email,
                commissionsList
            )
            getCustomer(customer.customerId)
            removePsp()
            closeCreateNewCustomer()
            setSearchParam(null)
            history.push({
                pathname: `/customers/${customer.customerId}`,
                state: { customer: customer },
            })
        }
    }

    useEffect(() => {
        const init = async () => {
            if (!commissionsList || !commissionsList.length) {
                getCommissions(defaultTab, null, null, null)
            }
        }
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const content = (
        <DialogContentWrapper>
            <WarningIcon width={56} />
            <DialogContentTitle>{t('general.customer.create.popup.exists')}</DialogContentTitle>
        </DialogContentWrapper>
    )

    const actions = (
        <DialogButtonsWrapper>
            {commissionsList && commissionsList.length && (
                <SecondaryButton
                    clickAction={handleViewDetail}
                    label={t('general.customer.create.popup.view')}
                />
            )}
            <CancelButton
                clickAction={closeCreateNewCustomer}
                label={t('general.customer.create.popup.cancel')}
            />
        </DialogButtonsWrapper>
    )

    const userExistsInCBAPopupData = {
        title: t('general.customer.create.popup.title'),
        type: 'CREATE_NEW_USER_EXISTS_IN_CBA',
        actions,
        body: content,
    }
    return (
        <PopUp
            onClose={closeCreateNewCustomer}
            open={createNewCustomerActive}
            body={userExistsInCBAPopupData.body}
            title={userExistsInCBAPopupData.title}
            actions={userExistsInCBAPopupData.actions}
        />
    )
}
