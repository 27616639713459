import { saveAs } from 'file-saver'

const base64ToBytes = (base64) => {
    const binaryString = atob(base64)
    return Uint8Array.from(binaryString, (m) => m.codePointAt(0))
}

export function saveToCsvFile(data, fileName) {
    const reader = new window.FileReader()
    reader.readAsDataURL(data)
    reader.onloadend = function () {
        const result = reader.result
        const decoder = new TextDecoder()
        const base64data = result.substring(result.indexOf(',') + 1)
        const s = decoder.decode(base64ToBytes(base64data))
        const blob = new Blob([decoder.decode(base64ToBytes(s))], {
            type: 'text/csv',
        })
        const iOSDeviceWithChrome =
            /CriOS/i.test(navigator.userAgent) && /iphone|ipod|ipad/i.test(navigator.userAgent)
        if (iOSDeviceWithChrome) {
            // blob in Chrome iOS is not fully supported  (https://support.google.com/chrome/thread/9516403),
            // workaround to fix downloading feature: by using FileReader (https://github.com/eligrey/FileSaver.js/issues/467#issuecomment-605596975)
            // but without predefined filename for save
            const fileReader = new FileReader()
            fileReader.onload = () => {
                if (typeof fileReader.result === 'string') {
                    const newWindow = window.open(fileReader.result)
                    setTimeout(() => {
                        newWindow.document.title = `CBA Report ${fileName}.csv`
                    }, 10)
                }
            }
            fileReader.readAsDataURL(blob)
        } else {
            saveAs(blob, `CBA Report ${fileName}.csv`)
        }
    }
}
