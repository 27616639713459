import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { closeLogoutFromGRPPopup } from '../../../actions/popupsActions'
import { SecondaryButton, PrimaryButton } from '../../Buttons'
import PopUp from '../PopUp'
import React from 'react'
import { TRANSLATIONS_NAMESPACE } from '../../../constants/global'
import { WarningIcon } from '../../Icons/WarningIcon'
import { DialogButtonsWrapper, DialogContentTitle, DialogContentWrapper } from '../../ContentDialog'
import { useAuthActions } from '../../../hooks/actions/useAuthActions'

export default function LogoutFromGRPPopUp() {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const { logoutFromGRPPopupActive } = useSelector((state) => state.popups)
    const { user } = useSelector((state) => state.oidc)
    const { logoutFromGRP } = useAuthActions()

    const content = (
        <DialogContentWrapper>
            <WarningIcon width={56} />
            <DialogContentTitle>
                {t('general.customer.logout.grp.popup.content')}
            </DialogContentTitle>
        </DialogContentWrapper>
    )

    const logout = () => {
        logoutFromGRP(user && user.refresh_token, user.access_token)
    }

    const actions = (
        <DialogButtonsWrapper>
            <PrimaryButton clickAction={logout} label={t('general.customer.create.popup.yes')} />
            <SecondaryButton
                clickAction={closeLogoutFromGRPPopup}
                label={t('general.customer.create.popup.no')}
            />
        </DialogButtonsWrapper>
    )

    const config = {
        title: t('general.dealer.profile.title'),
        type: 'LOGOUT',
        actions: actions,
        body: content,
    }
    return (
        <PopUp
            onClose={closeLogoutFromGRPPopup}
            open={logoutFromGRPPopupActive}
            body={config.body}
            title={config.title}
            actions={config.actions}
        />
    )
}
