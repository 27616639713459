export const defaultAddressTypes = [
    {
        id: '1',
        type: 'general.address.home',
    },
    {
        id: '2',
        type: 'general.address.business',
    },
    {
        id: '3',
        type: 'general.address.billing',
    },
]

export const defaultCountryId = '0'
export const defaultTab = 'all'

export const INPUT_LONG = 100
export const INPUT_SHORT = 17
export const NONE = 'none'

export const DEFAULT_EXP_DATE = '2033-01-19'
