import React from 'react'
import styled from 'styled-components'
import muiTheme from '../../themes/muiTheme'

const Root = styled.div`
    flex: 1 0 20px;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
`
const Cross = styled.div`
    position: absolute;
    top: 2px;
    right: -12px;
    width: 20px;
    height: 20px;
    opacity: 1;

    &:before,
    &:after {
        position: absolute;
        content: '';
        height: 22px;
        width: 1px;
        background-color: black;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }

    &:hover {
        &:after,
        &:before {
            background-color: ${muiTheme.palette.secondary.main};
        }
    }
`
export default function CloserIcon({ onClose }) {
    return (
        <Root onClick={onClose}>
            <Cross />
        </Root>
    )
}
