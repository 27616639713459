import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { RESET_FILTER_PARAMS } from '../../types/reduxActionTypes'

export function useCommissionFilter() {
    const dispatch = useDispatch()
    const setFilterParams = useCallback(
        (payload, type) => {
            dispatch({
                type,
                payload,
            })
        },
        [dispatch]
    )
    const resetFilterParams = useCallback(() => {
        dispatch({
            type: RESET_FILTER_PARAMS,
        })
    }, [dispatch])
    return { setFilterParams, resetFilterParams }
}
