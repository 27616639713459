import React, { useEffect } from 'react'
import './index.scss'
import styled from 'styled-components'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import muiTheme from './themes/muiTheme'
import { StylesProvider, MuiThemeProvider, CssBaseline } from '@material-ui/core'
import CreateCustomerInformation from './pages/Registration/CreateCustomerInformation'
import CreateOrderDetails from './pages/Registration/CreateOrderDetails'
import ConsentDocumentPreview from './pages/Documents/ConsentDocumentPreview'
import CommissionsList from './pages/Commissions/CommissionsList'
import CommissionDetail from './pages/Commissions/CommissionDetail'
import Footer from './components/Footer'
import LoginCallback from './pages/Auth/LoginCallback'
import ThankYouPageOld from './pages/Static/ThankYouPageOld'
import ImprintPage from './pages/Static/ImprintPage'
import LegalPage from './pages/Static/LegalPage'
import Notification from './components/Notification'
import ErrorBoundary from './components/ErrorBoundary'
import ErrorPage from './pages/Static/ErrorPage'
import LoginHandler from './pages/Auth/LoginHandler'
import { useSelector } from 'react-redux'
import NotLoggedInPage from './pages/Static/NotLoggedInPage'
import LogoutHandler from './pages/Auth/LogoutHandler'
import { useChangeLanguage } from './hooks/useChangeLanguage'
import { getTranslations } from './actions/i18nActions'
import ConsentConfirmation from './pages/Registration/ConsentConfirmation'
import useOcwCreateUser from './hooks/useOcwCreateUser'
import SystemAvailability from './components/SystemAvailability'
import ThankYouPage from './pages/Static/ThankYouPage'
import { CountriesProvider } from './components/CountriesProvider'

const ContentContainer = styled.div`
    flex: 1 0 auto;
`

function App() {
    const userInfo = useSelector((state) => state.oidc.userInfo)
    const isAuthenticated = userInfo !== null

    const { setLanguage } = useChangeLanguage()
    const { selectedLanguage, translations } = useSelector((state) => state.options)

    useEffect(() => {
        setLanguage(selectedLanguage)
    }, [selectedLanguage, setLanguage, userInfo])

    useEffect(() => {
        getTranslations()
    }, [translations])

    useOcwCreateUser()

    return (
        <Router>
            <StylesProvider injectFirst>
                <ErrorBoundary>
                    {process.env.REACT_APP_ENV === 'dev_local' && (
                        <div
                            style={{
                                position: 'fixed',
                                top: '-10px',
                                left: '-40px',
                                zIndex: 999999,
                                background: 'red',
                                transform: 'rotate(-45deg)',
                                color: 'white',
                                fontSize: '12px',
                                padding: '20px 40px 5px 40px',
                            }}
                        >
                            local
                        </div>
                    )}
                    <MuiThemeProvider theme={muiTheme}>
                        <CssBaseline />
                        <Switch>
                            <ProtectedRoute path="/consent-documents" isAuth={isAuthenticated}>
                                <ConsentDocumentPreview />
                            </ProtectedRoute>
                            <Route path="/thank-you">
                                <ThankYouPage />
                                <Notification position={'TopRight'} />
                            </Route>
                            <Route path="/thank-you-old">
                                <ThankYouPageOld />
                                <Notification position={'TopRight'} />
                            </Route>
                            <Route path="/not-logged-in">
                                <NotLoggedInPage />
                            </Route>
                            <Route path="/login-callback">
                                <LoginCallback />
                            </Route>
                            <Route exact path="/">
                                <LoginHandler />
                            </Route>
                            <Route exact path="/logout">
                                <LogoutHandler />
                            </Route>
                            <Route path="/error">
                                <ErrorPage />
                            </Route>
                            <ProtectedRoute path="/customer-information" isAuth={isAuthenticated}>
                                <ContentContainer>
                                    <CreateCustomerInformation />
                                </ContentContainer>
                                <Notification position={'TopRight'} />
                                <Footer />
                            </ProtectedRoute>
                            <ProtectedRoute path="/order-details" isAuth={isAuthenticated}>
                                <ContentContainer>
                                    <CreateOrderDetails />
                                </ContentContainer>
                                <Notification position={'TopRight'} />
                                <Footer />
                            </ProtectedRoute>
                            <ProtectedRoute path="/customers" exact isAuth={isAuthenticated}>
                                <ContentContainer>
                                    <CommissionsList />
                                </ContentContainer>
                                <Notification position={'TopRight'} />
                                <Footer />
                            </ProtectedRoute>
                            <ProtectedRoute
                                path="/customers/:customerId"
                                exact
                                isAuth={isAuthenticated}
                            >
                                <ContentContainer>
                                    <CommissionDetail />
                                </ContentContainer>
                                <Notification position={'TopRight'} />
                                <Footer />
                            </ProtectedRoute>
                            <ProtectedRoute path="/imprint" isAuth={isAuthenticated}>
                                <ContentContainer>
                                    <ImprintPage />
                                </ContentContainer>
                                <Notification position={'TopRight'} />
                                <Footer />
                            </ProtectedRoute>
                            <ProtectedRoute path="/legal" isAuth={isAuthenticated}>
                                <ContentContainer>
                                    <LegalPage />
                                </ContentContainer>
                                <Notification position={'TopRight'} />
                                <Footer />
                            </ProtectedRoute>
                        </Switch>
                    </MuiThemeProvider>
                    <Switch>
                        <Route path="/consent-confirmation">
                            <ConsentConfirmation />
                        </Route>
                    </Switch>
                    <SystemAvailability />
                </ErrorBoundary>
            </StylesProvider>
        </Router>
    )
}

function ProtectedRoute({ isAuth, children, ...rest }) {
    return (
        <Route
            {...rest}
            render={({ location }) =>
                isAuth ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/not-logged-in',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    )
}

export default App
