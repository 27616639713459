import React from 'react'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { TRANSLATIONS_NAMESPACE } from '../constants/global'
import styled from 'styled-components'

const Wrapper = styled.div`
    width: 750px;
`

export default function HorizontalStepper({ activeStep }) {
    const { skippedSteps } = useSelector((state) => state.customer)
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const stepLabels = [t('general.customer.title'), t('general.order.title')]

    const isStepSkipped = (step) => {
        return skippedSteps.find((s) => s === step)
    }

    return (
        <Wrapper>
            <Stepper activeStep={activeStep} alternativeLabel style={{ background: 'none' }}>
                {stepLabels.map((label, index) => {
                    const stepProps = {}
                    if (isStepSkipped(index)) {
                        stepProps.completed = false
                    }
                    return (
                        <Step key={index} {...stepProps}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    )
                })}
            </Stepper>
        </Wrapper>
    )
}
