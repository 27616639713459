import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isRegistrationProcess } from '../util/util'
import styled from 'styled-components'
import { SET_AVAILABILITY_STATUS } from '../types/reduxActionTypes'
import { useTranslation } from 'react-i18next'
import { TRANSLATIONS_NAMESPACE } from '../constants/global'

const Wrapper = styled.div`
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 3;
`

const Alert = styled('div')(
    ({ systemStatus }) => `
    position: relative;
    margin-top: 10px;
    padding: 15px;
    border-radius: 5px;
    opacity: 0.9;
    background-color: ${systemStatus === 'ERROR' ? '#ef5350' : '#ffc400'};`
)

const AlertClose = styled.button`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    top: 0;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #fff;
    color: #000;
    font-size: 10px;
    padding: 0;
    border: none;
    cursor: pointer;
    transform: translate(50%, -50%);

    &:hover {
        background-color: #d0d0d0;
    }
`

export default function SystemAvailability() {
    const dispatch = useDispatch()
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const { createNewCustomerActive } = useSelector((state) => state.popups)
    const availability = useSelector((state) => state.systemsAvailability)

    const closeAlertHandler = (e, systemKey) => {
        const systemAlertToRemoveIndex = availability.findIndex((item) => item.system === systemKey)

        if (systemAlertToRemoveIndex > -1) {
            const availabilityToSplice = [...availability]
            availabilityToSplice.splice(systemAlertToRemoveIndex, 1)

            dispatch({
                type: SET_AVAILABILITY_STATUS,
                payload: availabilityToSplice || [],
            })
        }
    }

    return availability &&
        availability.length &&
        (createNewCustomerActive || isRegistrationProcess()) ? (
        <Wrapper>
            {availability.map((item) => (
                <Alert key={item.system} systemStatus={item.status}>
                    <AlertClose
                        onClick={(e) => closeAlertHandler(e, item.system)}
                        data-clickaway-callback={'preventClose'}
                    >
                        &times;
                    </AlertClose>
                    {t(
                        `popupNotification.${item.system.toLowerCase()}.${item.status.toLowerCase()}`
                    )}
                </Alert>
            ))}
        </Wrapper>
    ) : (
        ''
    )
}
