import React from 'react'
import styled from 'styled-components'
import search from '../../assets/images/search.svg'

const Icon = styled.img`
    margin-right: 10px;
`

export function SearchIcon() {
    return <Icon src={search} width={20} height={36} alt="search" />
}
