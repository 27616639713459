import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { REMOVE_PSP, SET_PSP } from '../../types/reduxActionTypes'

export function useServicePartnersActions() {
    const dispatch = useDispatch()

    const removePsp = useCallback(
        (id = undefined, name = undefined) => {
            if (id && name) {
                dispatch({
                    type: REMOVE_PSP,
                    payload: {
                        keepId: id,
                        keepName: name,
                    },
                })
            } else {
                dispatch({
                    type: REMOVE_PSP,
                })
            }
        },
        [dispatch]
    )

    const setPsp = useCallback(
        (id, name) => {
            dispatch({
                type: SET_PSP,
                payload: {
                    value: {
                        id: id,
                        name: name,
                    },
                },
            })
        },
        [dispatch]
    )

    return { setPsp, removePsp }
}
