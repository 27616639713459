import React from 'react'
import { Notify } from 'react-redux-notify'
import 'react-redux-notify/dist/ReactReduxNotify.css'
import muiTheme from '../themes/muiTheme'

const NotificationComponent = ({ message, type, canDismiss, id, handleDismiss, duration }) => {
    let color
    switch (type.toString()) {
        case 'SUCCESS':
            color = muiTheme.palette.success.main
            break
        case 'ERROR':
            color = muiTheme.palette.error.main
            break
        default:
            color = '#ccc'
    }

    let styles = {
        margin: '5px 120px',
        padding: '15px',
        background: color,
        fontFamily: 'Seat Bcn',
        opacity: 0.9,
        float: 'right',
        clear: 'right',
        width: 'auto',
        boxSizing: 'border-box',
        borderRadius: '5px',
    }
    if (canDismiss) {
        styles = Object.assign({}, styles, { cursor: 'pointer' })
    }
    if (duration) {
        setTimeout(() => {
            handleDismiss(id)
        }, duration)
    }
    return (
        <div
            onClick={() => {
                if (canDismiss) {
                    handleDismiss(id)
                }
            }}
            style={styles}
        >
            {message}
        </div>
    )
}

export default function Notification({ position }) {
    return <Notify position={position} notificationComponent={NotificationComponent} />
}
