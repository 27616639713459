import React from 'react'
import Header from '../../components/Header'

export const WithHeader = (WrappedComponent) => (moreProps) => {
    return (
        <>
            <Header />
            <WrappedComponent {...moreProps} />
        </>
    )
}
