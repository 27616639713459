import React from 'react'
import styled from 'styled-components'
import { showDealerProfilePopup } from '../../actions/popupsActions'
import avatar from '../../assets/images/avatar.svg'

const Avatar = styled.div`
    cursor: pointer;
`

export function DealerAvatar() {
    return (
        <Avatar onClick={showDealerProfilePopup}>
            <img src={avatar} alt="Profile" />
        </Avatar>
    )
}
