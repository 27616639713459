import React, { useEffect, useState, Fragment } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import {
    journeyStatusStatesEnum,
    orderCancelledKey,
    TRANSLATIONS_NAMESPACE,
} from '../constants/global'
import { formatDateIntoYYYYMMDDWithDashes } from '../util/formatters'
import { ButtonSmallAutoFit, PrimaryButton, SecondaryButton } from './Buttons'
import PopUp from './PopUp/PopUp'
import { WarningIcon } from './Icons/WarningIcon'
import { DialogButtonsWrapper, DialogContentTitle, DialogContentWrapper } from './ContentDialog'
import { EmailNotifications, Vehicles } from '../api'
import { useDispatchNotification } from '../hooks/useDispatchNotification'
import {
    sendEmailErrorMessage,
    sendEmailSuccessMessage,
    setDmsErrorMessage,
    setPspErrorMessage,
} from '../types/reduxNotificationTypes'
import { MediumWidthCard } from './Wrappers/MediumWithCard'

const ListWrapper = styled.div`
    margin-top: 36px;
`

const List = styled.div`
    margin-bottom: 40px;
    &:last-child {
        margin-bottom: 0;
    }
`

const Row = styled.div`
  display: flex;
  min-height: 40px;
  align-items: center;
  border-bottom: 1px solid #f3f3f3;
{
  ${({ state }) => {
      if (state === journeyStatusStatesEnum.COMPLETED) {
          return `state: #4ba82e;`
      } else if (state === journeyStatusStatesEnum.FAILED) {
          return `color: #f44336;`
      } else if (state === journeyStatusStatesEnum.NOT_REQUIRED) {
          return `color: #808285;`
      } else {
          return `color: #000;`
      }
  }}
`

const StatusText = styled.div`
    flex: 1 0 auto;
`

const JourneyTHRow = styled.div`
    display: flex;
    padding: 0 56px;
    margin: 20px 0 10px;
    text-transform: uppercase;
    color: #808285;
    font-weight: bold;
    font-size: 13px;
`

const JourneyColStatus = styled.div`
    flex: 0 0 40%;
`

const JourneyColDetail = styled.div`
    flex: 1 0 auto;
`

const JourneyColAction = styled.div`
    display: flex;
    flex: 0 0 10%;
    justify-content: center;
`

const JourneyColDone = styled.div`
    display: flex;
    justify-content: center;
    flex: 0 0 50px;
     {
        ${({ state }) => {
            if (state === journeyStatusStatesEnum.COMPLETED) {
                return `color: #4ba82e;`
            } else if (state === journeyStatusStatesEnum.FAILED) {
                return `color: #f44336;`
            } else if (state === journeyStatusStatesEnum.NOT_REQUIRED) {
                return `color: #808285;`
            } else {
                return `color: #000;`
            }
        }}
    }
`

const SectionTitle = styled.h3`
    margin-left: -20px;
`

const buttonActions = {
    action: 'ACTION',
    repeatedAction: 'REPEATED_ACTION',
}

const actionTypes = {
    sendEmail: 'SEND_EMAIL',
    setPSP: 'SET_PSP',
    setDMS: 'SET_DMS',
}

// journey statuses helper object, to specify, for which status is it possible to call an action
// (action button is shown) with specific parameters
/*
statusId: ID of journey status
actionType: what action to trigger
customParams: specific params for triggered action
actions: available actions - for some statuses there are multiple actions available
 */
const statusesWithAction = [
    {
        statusId: 'seatIdAndAppDownloadInvitationSent',
        actionType: actionTypes.sendEmail,
        customParams: {
            emailType: 'GROUP_ID_REGISTRATION_INVITATION',
        },
        actions: {
            [buttonActions.action]: 'general.button.sendEmail',
            [buttonActions.repeatedAction]: 'general.button.resendEmail',
        },
    },
    {
        statusId: 'preferredServicePartnerSet',
        actionType: actionTypes.setPSP,
        actions: {
            [buttonActions.action]: 'general.journey.vehicle.button.setPsp',
        },
    },
    {
        statusId: 'dealerMaintenanceSchedulingEnablement',
        actionType: actionTypes.setDMS,
        actions: {
            [buttonActions.action]: 'general.journey.vehicle.button.setDms',
        },
    },
    {
        statusId: 'consent',
        actionType: actionTypes.sendEmail,
        actions: {
            [buttonActions.repeatedAction]: 'general.button.resendEmail',
        },
        customParams: {
            emailType: 'CONSENT_EMAIL',
        },
    },
]

const CheckMark = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 21" width="30" height="21">
        <path
            d="m26.682.54-15.368 15.3-8.5-8.5L.366 9.788 11.314 20.77 29.13 2.988 26.682.54Z"
            fill="currentColor"
        />
    </svg>
)

const CloseIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
        <path
            d="m23.39 20.974-8.942-8.942L23.39 3.09 20.942.642 12 9.584 3.058.642.61 3.09l8.942 8.942L.61 20.974l2.448 2.448L12 14.48l8.942 8.942 2.448-2.448Z"
            fill="currentColor"
        />
    </svg>
)

const StatusRow = ({
    itemKey,
    value,
    journeyType,
    setEmailType,
    sendEmailNotification,
    setVehiclePSP,
    setVehicleDMS,
    setIsResendEmailWarningPopupOpen,
    vehicleId,
}) => {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const actionItem = statusesWithAction.find((item) => item.statusId === itemKey)

    const openConfirmEmailPopup = () => {
        setEmailType(actionItem.customParams.emailType)
        setIsResendEmailWarningPopupOpen(true)
    }

    const actionClickHandler = () => {
        if (actionItem.actionType === actionTypes.sendEmail) {
            value.actionButton === buttonActions.repeatedAction
                ? sendEmailNotification(actionItem.customParams.emailType, { vehicleId })
                : openConfirmEmailPopup()
        } else if (actionItem.actionType === actionTypes.setPSP) {
            setVehiclePSP()
        } else if (actionItem.actionType === actionTypes.setDMS) {
            setVehicleDMS()
        }
    }

    return (
        <Row state={value.state}>
            <JourneyColStatus>
                <StatusText>{t(`general.statuses.${journeyType}.${itemKey}`)}</StatusText>
            </JourneyColStatus>
            <JourneyColDetail>
                {value.stepDescription.value &&
                    `${formatDateIntoYYYYMMDDWithDashes(new Date(value.stepDescription.value))}: `}
                {t(value.stepDescription.key)}
            </JourneyColDetail>
            <JourneyColAction>
                {actionItem && actionItem.actions[value.actionButton] && (
                    <ButtonSmallAutoFit onClick={actionClickHandler}>
                        {t(actionItem.actions[value.actionButton])}
                    </ButtonSmallAutoFit>
                )}
            </JourneyColAction>
            <JourneyColDone state={value.state}>
                {(value.state === journeyStatusStatesEnum.NOT_REQUIRED ||
                    value.state === journeyStatusStatesEnum.FAILED) && <CloseIcon />}
                {value.state === journeyStatusStatesEnum.COMPLETED && <CheckMark />}
            </JourneyColDone>
        </Row>
    )
}

export default function Journey({ customer, statuses, getStatusesData }) {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const [isResendEmailWarningPopupOpen, setIsResendEmailWarningPopupOpen] = useState(false)
    const [emailType, setEmailType] = useState(null)
    const [dispatchNotification] = useDispatchNotification()

    const { customerJourneyStatuses, vehicleJourneyStatuses } = statuses

    const submitConfirmEmailPopup = async (type) => {
        await sendEmailNotification(type)
        setIsResendEmailWarningPopupOpen(false)
    }

    const sendEmailNotification = async (type, customParams = undefined) => {
        if (type === 'CONSENT_EMAIL' && customParams?.vehicleId) {
            try {
                await EmailNotifications.resendDigitalConsentEmailNotification(
                    customParams.vehicleId
                )
                dispatchNotification(sendEmailSuccessMessage)
            } catch (e) {
                console.error(e)
                dispatchNotification(sendEmailErrorMessage)
            }
        } else {
            try {
                await EmailNotifications.sendEmailNotification(customer.id, type)
                dispatchNotification(sendEmailSuccessMessage)
            } catch (e) {
                console.error(e)
                dispatchNotification(sendEmailErrorMessage)
            }
        }
    }

    const setVehiclePSP = async (vehicleId) => {
        try {
            await Vehicles.setPsp(vehicleId)
            getStatusesData(customer.id)
        } catch (e) {
            console.error(e)
            dispatchNotification(setPspErrorMessage)
        }
    }

    const setVehicleDMS = async (vehicleId) => {
        try {
            await Vehicles.setDms(vehicleId)
            getStatusesData(customer.id)
        } catch (e) {
            console.error(e)
            dispatchNotification(setDmsErrorMessage)
        }
    }

    useEffect(() => {
        if (!isResendEmailWarningPopupOpen) {
            setEmailType(null)
        }
    }, [isResendEmailWarningPopupOpen])

    return (
        <>
            <JourneyTHRow>
                <JourneyColStatus>{t('general.journey.status')}</JourneyColStatus>
                <JourneyColDetail>{t('general.detail')}</JourneyColDetail>
                <JourneyColDone>{t('general.done')}</JourneyColDone>
            </JourneyTHRow>
            <MediumWidthCard>
                <ListWrapper>
                    <SectionTitle>{t('general.journey.customer')}</SectionTitle>
                    <List>
                        {customerJourneyStatuses &&
                            Object.entries(customerJourneyStatuses)
                                // hide ident MSP-47322
                                .filter(([key]) => key !== 'seatIdentVerificationStatus')
                                .map(([key, value]) => (
                                    <StatusRow
                                        key={key}
                                        itemKey={key}
                                        value={value}
                                        journeyType={'customer'}
                                        setEmailType={setEmailType}
                                        setIsResendEmailWarningPopupOpen={
                                            setIsResendEmailWarningPopupOpen
                                        }
                                        sendEmailNotification={sendEmailNotification}
                                    />
                                ))}
                    </List>
                    {vehicleJourneyStatuses &&
                        vehicleJourneyStatuses.map((vehicle, index) => (
                            <Fragment key={vehicle.vehicleId}>
                                <SectionTitle>
                                    {t('general.journey.vehicle')}
                                    {vehicle.vehicleOrderId ? ` (${vehicle.vehicleOrderId})` : ''}
                                </SectionTitle>
                                <List>
                                    {Object.entries(vehicle)
                                        .filter(
                                            ([key, value]) =>
                                                !!value?.stepDescription &&
                                                ((key === orderCancelledKey &&
                                                    value?.state ===
                                                        journeyStatusStatesEnum.FAILED) ||
                                                    key !== orderCancelledKey)
                                        )
                                        .map(([key, value]) => (
                                            <StatusRow
                                                key={key}
                                                itemKey={key}
                                                value={value}
                                                setEmailType={setEmailType}
                                                setIsResendEmailWarningPopupOpen={
                                                    setIsResendEmailWarningPopupOpen
                                                }
                                                setVehiclePSP={() =>
                                                    setVehiclePSP(vehicle.vehicleId)
                                                }
                                                setVehicleDMS={() =>
                                                    setVehicleDMS(vehicle.vehicleId)
                                                }
                                                journeyType={'vehicle'}
                                                sendEmailNotification={sendEmailNotification}
                                                vehicleId={vehicle.vehicleId}
                                            />
                                        ))}
                                </List>
                            </Fragment>
                        ))}
                </ListWrapper>
            </MediumWidthCard>
            <PopUp
                onClose={() => setIsResendEmailWarningPopupOpen(false)}
                open={isResendEmailWarningPopupOpen}
                body={
                    <DialogContentWrapper>
                        <WarningIcon width={56} />
                        <DialogContentTitle>
                            {t('general.journey.popup.resendEmail.warning')}
                        </DialogContentTitle>
                    </DialogContentWrapper>
                }
                title={t('general.journey.popup.sendEmail.title')}
                actions={
                    <DialogButtonsWrapper>
                        <PrimaryButton
                            disabled={!emailType}
                            clickAction={() => submitConfirmEmailPopup(emailType)}
                            label={t('general.yes')}
                        />
                        <SecondaryButton
                            clickAction={() => setIsResendEmailWarningPopupOpen(false)}
                            label={t('general.no')}
                        />
                    </DialogButtonsWrapper>
                }
            />
        </>
    )
}
