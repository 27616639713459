import React, { useEffect, useState } from 'react'
import { MuiThemeProvider } from 'material-ui'
import FormWrapper from '../../components/Wrappers/FormWrapper'
import { PrimaryButton } from '../../components/Buttons'
import { useHistory } from 'react-router'
import DialogContentText from '@material-ui/core/DialogContentText'
import { DialogContentTitle, DialogContentWrapper } from '../../components/ContentDialog'
import { WarningIcon } from '../../components/Icons/WarningIcon'
import styled from 'styled-components'
import { getTranslations } from '../../actions/i18nActions'
import { useTranslation } from 'react-i18next'
import { DEFAULT_CULTURE, TRANSLATIONS_NAMESPACE } from '../../constants/global'
import DateTime from 'luxon/src/datetime'
import { useSelector } from 'react-redux'
import { useChangeLanguage } from '../../hooks/useChangeLanguage'

const Wrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`

const Button = styled.div`
    margin-top: 45px;
`

export default function ErrorPage({ reset, dateTime }) {
    const { t, i18n } = useTranslation(TRANSLATIONS_NAMESPACE)

    if (!dateTime) {
        dateTime = DateTime.local().toString()
    }

    const history = useHistory()
    const { translations, loading } = useSelector((state) => state.options)
    const [loaded, setLoaded] = useState(true)
    const { setLanguage } = useChangeLanguage()

    useEffect(() => {
        getTranslations()
        if (translations && Object.keys(translations).length > 0 && !loading) {
            setLoaded(true)
            if (Object.keys(translations).length === 1) {
                setLanguage(Object.keys(translations)[0])
            } else {
                setLanguage(DEFAULT_CULTURE)
            }
        }
    }, [translations, i18n, loading, setLanguage])

    const goToHomepage = () => {
        history.replace({
            pathname: '/customers',
        })
        if (reset) {
            reset()
        }
    }
    return (
        <MuiThemeProvider>
            <Wrapper>
                {loaded && (
                    <Content>
                        <FormWrapper>
                            <DialogContentWrapper>
                                <WarningIcon width={60} />
                                <DialogContentTitle>
                                    {t('general.errorBoundary.info')}
                                </DialogContentTitle>
                                <DialogContentText>
                                    {t('general.errorBoundary.info.additional')}
                                </DialogContentText>
                                <Button>
                                    <PrimaryButton
                                        clickAction={goToHomepage}
                                        label={t('general.errorBoundary.button')}
                                    />
                                </Button>
                                {dateTime}
                            </DialogContentWrapper>
                        </FormWrapper>
                    </Content>
                )}
            </Wrapper>
        </MuiThemeProvider>
    )
}
