import { useEffect } from 'react'
import {
    allowAccessToCreateProcess,
    closeCreateNewCustomer,
    showCreateNewCustomer,
} from '../actions/popupsActions'
import { GET_CUSTOMER_SUCCESS } from '../types/reduxActionTypes'
import { useDispatch, useSelector } from 'react-redux'
import { Customers } from '../api'
import { somethingWentWrongErrorMessage } from '../types/reduxNotificationTypes'
import { useDispatchNotification } from './useDispatchNotification'
import { useUpdateCustomerActions } from './actions/useUpdateCustomerActions'

export default function UseOcwCreateUser() {
    const dispatch = useDispatch()
    const [dispatchNotification] = useDispatchNotification()
    const { saveOrderDetailsField } = useUpdateCustomerActions()
    const customer = useSelector((state) => state.customer)
    const { ocwRequestId } = useSelector((state) => state.oidc)

    useEffect(() => {
        const init = async () => {
            if (ocwRequestId) {
                try {
                    showCreateNewCustomer()
                    allowAccessToCreateProcess()
                    const response = await Customers.getOCWData(ocwRequestId)

                    dispatch({
                        type: GET_CUSTOMER_SUCCESS,
                        payload: {
                            customer: {
                                ...customer,
                                customerInformation: response.data.customerInformation,
                                orderDetails: response.data.orderDetails,
                            },
                        },
                    })
                    saveOrderDetailsField('createdByOcwData', true)
                } catch (e) {
                    dispatchNotification(somethingWentWrongErrorMessage)
                    closeCreateNewCustomer()
                    console.error(e)
                }
            }
        }
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ocwRequestId])
}
