import React from 'react'
import info from '../../assets/images/app-store.svg'
import styled from 'styled-components'

const Icon = styled.img`
    width: 175px;

    @media (max-width: 768px) {
        width: 150px;
    }

    @media (max-width: 400px) {
        width: 130px;
    }
`
export function AppStoreIcon({ href }) {
    return (
        <div>
            <a href={href} target="_blank" rel="noopener noreferrer">
                <Icon src={info} alt="app-store" />
            </a>
        </div>
    )
}
