import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Page from '../../components/Wrappers/Page'
import { ImprintContent } from '../Documents/ImprintContent'
import { TRANSLATIONS_NAMESPACE } from '../../constants/global'
import ScrollTop from '../../components/Wrappers/ScrollTop'
import { useSaveInputChange } from '../../hooks/form/useSaveInputChange'
import { getTranslations } from '../../actions/i18nActions'
import { WithHeader } from '../../components/Wrappers/WithHeader'

function ImprintPage() {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)

    getTranslations()
    const { setSearchParam } = useSaveInputChange()

    useEffect(() => {
        setSearchParam(null)
    }, [setSearchParam])

    return (
        <ScrollTop>
            <Page
                withBackLink={true}
                backLinkUrl={'/customers'}
                title={t('general.footer.imprint.title')}
            >
                <ImprintContent />
            </Page>
        </ScrollTop>
    )
}

export default WithHeader(ImprintPage)
