import styled from 'styled-components'

export const FormWrapperPadding = styled.div`
    padding: 0 100px;
`

export const FormTitle = styled.div`
    display: flex;
    justify-content: center;
    font-size: 32px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4b4a4b;
    padding-bottom: 48px;
`

export const FormRow = styled.div`
    display: flex;
    flex-direction: row;
`

export const FormCenteredRow = styled.div`
    display: flex;
    padding-bottom: 15px;
    justify-content: center;
`

export const FormCenteredRowBigger = styled.div`
    display: flex;
    padding-top: 15px;
    padding-bottom: 15px;
    justify-content: center;
`

export const FormCenteredField = styled.div`
    padding-right: 25px;
    flex: 1 1 auto;
     {
        ${({ size }) =>
            size &&
            `
        flex: ${size} 1 auto;
    `}
    }
    &:last-child {
        padding-right: 0;
    }
`

export const FormField = styled.div`
    width: 554px;
`

export const FormInputField = styled.div`
    & input[type='text'] {
        width: 100%;
        margin-right: 2em;
        box-sizing: border-box;
        border: none;
    }
`

export const FormSmallField = styled.div`
    width: 165px;
`

export const FormDatepicker = styled.div`
    width: 220px;
    margin-right: 25px;
`

export const FormCheckboxWrapper = styled.div`
    margin-bottom: 10px;
`

export const FormDateRow = styled.div`
    display: flex;
    flex-direction: row;

    > * {
        width: 50%;
        margin-right: 0;
        padding: 0;
    }
`
