import styled from 'styled-components'

export const DialogContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 95%;
    align-items: center;

    @media (max-width: 768px) {
        width: 90%;
    }

    @media (max-width: 400px) {
        width: 85%;
    }
`

export const DialogContentTitle = styled.div`
    font-size: 28px;
    font-weight: 700;
    color: #4b4a4b;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
    width: 100%;
`

export const DialogContentText = styled.div`
    font-size: 16px;
    font-weight: 400;
    line-height: 1.38;
    color: #808285;
    text-align: center;
    width: 100%;
`

export const DialogContentDiv = styled.div`
    width: 100%;
    height: 50px;
`

export const DialogContentSmallDiv = styled.div`
    width: 100%;
    height: 10px;
`

export const DialogButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 240px;
    align-items: center;
`
