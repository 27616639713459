import { responsiveFontSizes } from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles'
import '../assets/fonts.scss'

const defaultTheme = createTheme()
const colorPrimary = '#ffffff'
const colorDisabled = '#c0c0c0'
const colorBg = '#e63a2b'
const fontFamilyPrimary = ['Helvetica', 'Arial', 'sans-serif'].join(',')
const fontFamilySecondary = ['Seat Bcn', 'Helvetica', 'Arial', 'sans-serif'].join(',')

let seatTheme = createTheme({
    palette: {
        primary: {
            main: colorPrimary,
        },
        text: {
            primary: colorPrimary,
            disabled: colorDisabled,
        },
        background: {
            default: colorBg,
        },
        error: {
            main: '#f0f0f0',
        },
    },
    typography: {
        fontFamily: fontFamilyPrimary,
        fontWeightRegular: 400,
        allVariants: {
            color: colorPrimary,
        },
        h1: {
            fontFamily: fontFamilySecondary,
            fontSize: 60,
            fontWeight: 900,
            textTransform: 'uppercase',
            marginBottom: 0,
        },
        h2: {
            fontFamily: fontFamilySecondary,
            fontSize: 40,
            fontWeight: 700,
            marginBottom: defaultTheme.spacing(4),
        },
        caption: {
            fontSize: 10,
        },
    },
    props: {
        MuiInputLabel: {
            shrink: true,
        },
    },
    overrides: {
        MuiFormControlLabel: {
            root: {
                display: 'flex',
                alignItems: 'start',
                marginBottom: defaultTheme.spacing(2),
                marginLeft: 0,
            },
            label: {
                marginLeft: '0.5rem',
            },
        },
        MuiCheckbox: {
            root: {
                color: colorPrimary,
                padding: 0,
                '&$checked': {
                    color: colorPrimary,
                },
            },
            colorPrimary: {
                '&$disabled': {
                    color: colorDisabled,
                },
            },
        },
        MuiButton: {
            root: {
                borderRadius: 5,
            },
            outlined: {
                borderWidth: '2px',
                '&:disabled': {
                    border: `solid ${colorDisabled} 2px`,
                    color: colorDisabled,
                },
                '&:hover': {
                    borderWidth: '2px',
                },
            },
            outlinedPrimary: {
                border: `solid ${colorPrimary} 2px`,
                '&:disabled': {
                    borderWidth: '2px',
                },
                '&:hover': {
                    border: `solid ${colorPrimary} 2px`,
                },
            },
        },
        MuiMenuItem: {
            root: {
                background: '#505050',
                '&:hover': {
                    color: '#505050',
                },
                '&:focus': {
                    color: '#505050',
                },
                '&$selected': {
                    backgroundColor: colorBg,
                },
            },
        },
        MuiFormControl: {
            root: {
                marginTop: defaultTheme.spacing(4),
                marginBottom: 0,
            },
        },
    },
})

seatTheme = responsiveFontSizes(seatTheme)

export default seatTheme
