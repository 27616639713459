import React from 'react'
import InputTooltip from './Tooltip/InputTooltip'
import styled from 'styled-components'

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
`

const Text = styled.div`
    margin-right: 5px;
    color: rgba(0, 0, 0, 0.54);
    font-size: ${(props) => (props.small ? '0.75rem' : '1rem')};
    font-weight: 400;
    line-height: 1;
`

const WarningText = styled.div`
    margin-left: 8px;
    color: #f44336;
    font-size: ${(props) => (props.small ? '0.75rem' : '1rem')};
    font-weight: 400;
    line-height: 1;
`

const MandatoryStar = styled.div`
    margin-right: 5px;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1;
    color: rgba(0, 0, 0, 0.54);

    :after {
        content: '*';
    }
`

export default function InputTooltipWithLabel({
    labelText,
    tooltipText,
    small,
    warningText,
    mandatory,
}) {
    return (
        <Wrapper>
            {mandatory && <MandatoryStar />}
            <Text small={small}>{labelText}</Text>
            {tooltipText && <InputTooltip text={tooltipText} size={small ? 'small' : undefined} />}
            {warningText && <WarningText small={small}>{warningText}</WarningText>}
        </Wrapper>
    )
}
