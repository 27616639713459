import { responsiveFontSizes } from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles'

let muiTheme = createTheme({
    palette: {
        primary: { main: '#af1e23' },
        secondary: { main: '#ea5d1a' },
        success: { main: '#4ba82e' },
        error: { main: '#ff9494' },
        background: {
            default: '#f3f3f3',
        },
    },
    typography: {
        useNextVariants: true,
        fontFamily: 'Seat Bcn, Arial, Helvetica, sans-serif',
        h3: {
            fontWeight: 700,
            fontSize: '2rem',
            color: '#4b4a4b',
        },
        h4: {
            fontWeight: 700,
            fontSize: '1em',
            letterSpacing: '-1px',
        },
        body1: {
            fontWeight: 400,
        },
        subtitle1: {
            fontWeight: 400,
        },
    },
    props: {
        MuiInputLabel: {
            shrink: true,
        },
    },
    overrides: {
        MuiInput: {
            underline: {
                fontWeight: 400,
                '&:before': {
                    borderBottomColor: '#000',
                },
                '&:after': {
                    borderBottomColor: '#000',
                },
                '&$disabled': {
                    '&:before': {
                        borderBottomStyle: 'solid',
                    },
                },
            },
            formControl: {
                marginTop: '8px !important',
                marginBottom: '10px !important',
            },
        },
        MuiInputBase: {
            input: {
                '&$disabled': {
                    color: '#333',
                    cursor: 'default',
                },
            },
        },
        MuiFormLabel: {
            root: {
                '&$focused': {
                    color: '#808285',
                },
            },
        },
        MuiTextField: {
            root: {
                width: '100%',
            },
        },
        MuiFormHelperText: {
            root: {
                textAlign: 'right',
                marginTop: '0',
            },
        },
        MuiSelect: {
            select: {
                '&:focus': {
                    backgroundColor: 'none',
                },
                textTransform: 'none',
            },
        },
        MuiStepIcon: {
            root: {
                color: '#fff',
                border: `1px solid #c0c0c0`,
                borderRadius: '50%',
                '&$completed': {
                    color: '#4b4a4b',
                    border: `none`,
                },
                '&$active': {
                    color: '#ea5d1a',
                    border: `none`,
                    '&& text': {
                        fill: '#fff',
                    },
                },
            },
            text: {
                fill: '#4b4a4b',
            },
            active: {},
            completed: {},
        },
        MuiStepLabel: {
            label: {
                '&$completed': {
                    color: '#4b4a4b',
                    fontWeight: 400,
                    fontSize: '14px',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: '1.71',
                    letterSpacing: 'normal',
                },
                '&$active': {
                    color: '#ea5d1a',
                    fontWeight: '700',
                    fontSize: '14px',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: '1.71',
                    letterSpacing: 'normal',
                },
            },
        },
        MuiStepConnector: {
            alternativeLabel: {
                left: `calc(-50% + 12px)`,
                right: `calc(50% + 12px)`,
            },
        },
        MuiPickersDay: {
            daySelected: {
                backgroundColor: '#ea5d1a',
            },
        },
        MuiInputAdornment: {
            root: {
                '&& button': {
                    padding: '0',
                },
            },
        },
        MuiPickersBasePicker: {
            pickerView: {
                height: '280px',
                minHeight: '280px',
            },
        },
        MuiPickersToolbar: {
            toolbar: {
                height: '64',
            },
        },
        MuiPickersToolbarText: {
            toolbarTxt: {
                color: '#fff',
            },
        },
        MuiPickersCalendarHeader: {
            switchHeader: {
                display: 'none',
            },
        },
        MuiInputLabel: {
            shrink: {
                transform: 'translate(0, -15px) scale(0.75)',
                transformOrigin: 'top left',
            },
        },
        MuiCssBaseline: {
            '@global': {
                html: {
                    boxSizing: 'content-box',
                },
            },
        },
    },
})
muiTheme = responsiveFontSizes(muiTheme)

export default muiTheme
