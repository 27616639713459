import datepicker from '../../assets/images/event.svg'
import React from 'react'
import styled from 'styled-components'

const Icon = styled.div`
    padding-bottom: 0;
    cursor: pointer;
`

export default function DatePickerIcon({ onClick }) {
    return (
        <Icon onClick={onClick}>
            <img src={datepicker} alt="datepicker" />
        </Icon>
    )
}
