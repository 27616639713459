import { store } from '../index'
import { I18n } from '../api'
import i18next from 'i18next'
import {
    GET_ALL_TRANSLATIONS_SUCCESS,
    GET_TRANSLATIONS_ERROR,
    GET_TRANSLATIONS_STARTED,
    GET_TRANSLATIONS_SUCCESS,
} from '../types/reduxActionTypes'
import {
    DEFAULT_CULTURE,
    SUPPORTED_LANGUAGES,
    SUPPORTED_LANGUAGES_CULTURES,
    TRANSLATIONS_NAMESPACE,
} from '../constants/global'

const initI18n = () => {
    const translations = store.getState().options.translations
    let resources = {}
    Object.entries(translations).forEach(([k, v]) => {
        resources[k] = {
            translation: v,
        }
        if (i18next.getResourceBundle(k, TRANSLATIONS_NAMESPACE) === undefined) {
            i18next.addResourceBundle(k, TRANSLATIONS_NAMESPACE, v)
        }
    })
}

export const getTranslations = (languages) => {
    const { loading, countries, translations } = store.getState().options
    const { userInfo } = store.getState().oidc
    if (userInfo !== null && countries.length > 0) {
        if (!languages) {
            const selectedCountry = countries.find((c) => c.countryCode === userInfo.country)
            const selectedLanguages = selectedCountry.languages.map(
                (c) => c + '-' + selectedCountry.countryCode
            )
            loadLanguages(selectedLanguages, translations, loading)
        } else {
            loadLanguages(languages, translations, loading)
        }
    } else if (languages) {
        loadLanguages(languages, translations, false)
    } else {
        getDefaultTranslations()
    }
}

const createSelectedLanguages = () => {
    const url = new URL(window.location.href)
    const loc = url.searchParams.get('loc')?.substring(0, 2)
    const langFromLoc = Object.values(SUPPORTED_LANGUAGES).includes(loc) ? loc : undefined
    const selectedLang = langFromLoc || navigator.language

    if (selectedLang) {
        if (SUPPORTED_LANGUAGES.includes(selectedLang.substring(0, 2))) {
            const lang = SUPPORTED_LANGUAGES_CULTURES[selectedLang.substring(0, 2)]
            return [lang]
        } else {
            return [DEFAULT_CULTURE]
        }
    } else {
        return [DEFAULT_CULTURE]
    }
}

export const getDefaultTranslations = () => {
    const { translations, loading } = store.getState().options
    const selectedLanguages = createSelectedLanguages()

    loadLanguages(selectedLanguages, translations, loading)
}

const loadLanguages = (selectedLanguages, translations, loading) => {
    let loaded = true
    selectedLanguages.forEach((l) => {
        if (!translations[l]) {
            loaded = false
        }
    })
    if (loaded && Object.keys(translations).length > 0) {
        initI18n()
    } else {
        if (!loading) {
            store.dispatch({
                type: GET_TRANSLATIONS_STARTED,
            })
            Promise.all(selectedLanguages.map((l) => I18n.getCultureTranslations(l)))
                .then((responses) => {
                    responses.forEach((r, i) => {
                        store.dispatch({
                            type: GET_TRANSLATIONS_SUCCESS,
                            payload: {
                                language: selectedLanguages[i],
                                translations: r.data,
                            },
                        })
                    })
                    store.dispatch({
                        type: GET_ALL_TRANSLATIONS_SUCCESS,
                    })
                    initI18n()
                })
                .catch((error) => {
                    console.error(error)
                    store.dispatch({
                        type: GET_TRANSLATIONS_ERROR,
                    })
                })
        }
    }
}
