import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const Icon = styled.img`
    width: 264px;
`

export function AppQrCode({ qrImg }) {
    const [imgModule, setImgModule] = useState(null)
    useEffect(() => {
        const loadImg = async () => {
            try {
                const importedImg = await import(`../../assets/images/${qrImg}`)
                setImgModule(importedImg.default)
            } catch (e) {
                console.error(e)
            }
        }
        loadImg()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <div>{imgModule && <Icon src={imgModule} alt="qr" />}</div>
}
