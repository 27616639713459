import {
    GET_PSP_ERROR,
    GET_PSP_STARTED,
    GET_PSP_SUCCESS,
    REMOVE_PSP,
} from '../types/reduxActionTypes'

const initialState = {
    loading: false,
    error: false,
    servicePartners: [],
    servicePartnersLoading: false,
}
const userSignedOutAction = 'oidc/userSignedOut'

export const servicePartnerReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PSP_STARTED:
            return {
                ...state,
                servicePartners: [],
                servicePartnersLoading: true,
                error: false,
            }
        case GET_PSP_SUCCESS:
            return {
                ...state,
                servicePartnersLoading: false,
                error: false,
                servicePartners: action.payload.servicePartners,
            }
        case GET_PSP_ERROR:
            return {
                ...state,
                servicePartnersLoading: false,
                error: true,
                servicePartners: [],
            }
        case REMOVE_PSP:
            let filtered = []
            if (action.payload) {
                const keepId = state.servicePartners.find((s) => s.id === action.payload.keepId)
                if (keepId !== undefined && keepId !== null) {
                    filtered = [
                        {
                            name: action.payload.keepName,
                            value: action.payload.keepId,
                        },
                    ]
                }
            }
            return {
                ...state,
                servicePartners: filtered,
            }
        case userSignedOutAction:
            return {
                ...initialState,
            }
        default:
            return state
    }
}
