import React, { useEffect, useState } from 'react'
import { MuiThemeProvider } from 'material-ui'
import FormWrapper from '../../components/Wrappers/FormWrapper'
import { useTranslation } from 'react-i18next'
import { DEFAULT_CULTURE, TRANSLATIONS_NAMESPACE } from '../../constants/global'
import { DialogContentTitle, DialogContentWrapper } from '../../components/ContentDialog'
import { WarningIcon } from '../../components/Icons/WarningIcon'
import styled from 'styled-components'
import { getDefaultTranslations } from '../../actions/i18nActions'
import ConfigurationHolder from '../../configurationHolder'
import { useSelector } from 'react-redux'
import { useChangeLanguage } from '../../hooks/useChangeLanguage'
import muiTheme from '../../themes/muiTheme'

const Wrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`

const Link = styled.a`
    color: ${muiTheme.palette.secondary.main};
    text-decoration: none;
    &:hover,
    &:focus {
        text-decoration: underline;
    }
`

export default function NotLoggedInPage() {
    const { t, i18n } = useTranslation(TRANSLATIONS_NAMESPACE)

    const { translations, loading, selectedLanguage } = useSelector((state) => state.options)
    const [loaded, setLoaded] = useState(true)
    const { setLanguage } = useChangeLanguage()

    useEffect(() => {
        if (!selectedLanguage || !translations[selectedLanguage]) {
            getDefaultTranslations()
            if (translations && Object.keys(translations).length > 0 && !loading) {
                setLoaded(true)
                if (Object.keys(translations).length === 1) {
                    setLanguage(Object.keys(translations)[0])
                } else {
                    setLanguage(DEFAULT_CULTURE)
                }
            }
        }
    }, [translations, i18n, loading, setLanguage, selectedLanguage])

    return (
        <MuiThemeProvider>
            <Wrapper>
                {loaded && (
                    <Content>
                        <FormWrapper>
                            <DialogContentWrapper>
                                <WarningIcon width={60} />
                                <DialogContentTitle>
                                    {t('general.notlogged.part1')}{' '}
                                    <Link
                                        href={ConfigurationHolder.getConfiguration().grpRedirectUrl}
                                    >
                                        Group Retail Portal
                                    </Link>{' '}
                                    {t('general.notlogged.part2')}
                                </DialogContentTitle>
                            </DialogContentWrapper>
                        </FormWrapper>
                    </Content>
                )}
            </Wrapper>
        </MuiThemeProvider>
    )
}
