import React from 'react'
import styled from 'styled-components'
import checkmark from '../../assets/images/checkmark.svg'

const Icon = styled.img`
    position: absolute;
    right: 8px;
    top: 18px;
`

export function InputCheckmark() {
    return <Icon src={checkmark} width={16} alt="checkmark" />
}
