import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
    width: 552px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
    padding: 75px 98px;
`

export default function Panel({ children }) {
    return <Wrapper>{children}</Wrapper>
}
