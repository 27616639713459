import MenuItem from '@material-ui/core/MenuItem'
import { SecondaryButton } from '../../Buttons'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import DatePicker from '../../Datepicker/DatePicker'
import Select from '@material-ui/core/Select'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import CloserIcon from '../../Icons/CloserIcon'
import { useGetCommissions } from '../../../hooks/useGetCommissions'
import { TRANSLATIONS_NAMESPACE } from '../../../constants/global'
import { customerStatuses, vehicleStatuses } from '../../../constants/filterStatusOptions'
import { SET_FILTER_PARAMS } from '../../../types/reduxActionTypes'
import { useCommissionFilter } from '../../../hooks/form/useCommissionFilter'
import { defaultTab } from '../../../constants/defaultFormValues'
import { BeakIcon } from '../../Icons/BeakIcon'
import { ResetIcon } from '../../Icons/ResetIcon'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import {
    formatDateIntoYYYYMMDDWithDashes,
    formatDateIntoDatePicker,
} from '../../../util/formatters'
import InputTooltip from '../../Tooltip/InputTooltip'
import { Box, FormControl, Grid, InputLabel } from '@material-ui/core'

const Root = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: 34px;
    right: 0;
    z-index: 1;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    padding: 32px;
`

const ResetButton = styled.div`
    flex: 0 1 auto;
    text-transform: uppercase;
    display: flex;
    text-align: right;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 1em;
    height: 44px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    > img {
        margin-left: 2px;
    }
`

export default function FilterPopUp({ close }) {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const { setFilterParams, resetFilterParams } = useCommissionFilter()
    const [getCommissions] = useGetCommissions()
    const { filter } = useSelector((state) => state.commissions)

    const onChangeFilterDateHandle = (date, key) => {
        const convertedDate = isNaN(date) ? date : formatDateIntoYYYYMMDDWithDashes(date)
        setFilterParams(
            {
                name: key,
                value: convertedDate,
            },
            SET_FILTER_PARAMS
        )
    }

    const onChangeParamHandle = (e) => {
        setFilterParams(
            {
                name: e.target.name,
                value: e.target.value,
            },
            SET_FILTER_PARAMS
        )
    }

    const applyFilter = useCallback(() => {
        getCommissions(
            defaultTab,
            filter.dateFrom,
            filter.dateTo,
            filter.customerStatus,
            filter.vehicleStatus
        )
        close()
    }, [filter, getCommissions, close])

    const resetFilter = useCallback(() => {
        resetFilterParams()
    }, [resetFilterParams])

    const getDateFromMax = () => {
        const today = new Date()
        if (filter.dateTo === null) {
            return formatDateIntoDatePicker(today)
        }
        const threshold = filter.dateTo < formatDateIntoDatePicker(today) ? filter.dateTo : today
        return formatDateIntoDatePicker(threshold)
    }
    const getDateToMax = () => {
        return formatDateIntoDatePicker(new Date())
    }
    const getDateToMin = () => {
        if (filter.dateFrom === null) {
            return undefined
        }
        return formatDateIntoDatePicker(filter.dateFrom)
    }
    return (
        <ClickAwayListener onClickAway={close}>
            <Root>
                <CloserIcon onClose={close} />
                <Box mt={4}>
                    <Grid container spacing={3} wrap={'nowrap'}>
                        <Grid item>
                            <FormControl fullWidth>
                                <InputLabel shrink style={{ whiteSpace: 'nowrap' }}>
                                    <Grid container wrap={'nowrap'}>
                                        <Grid item>
                                            {t('general.customers.table.filter.createdOn')}
                                        </Grid>
                                        <Grid item>
                                            <InputTooltip
                                                text={t('general.datepicker.tooltip')}
                                                size="small"
                                            />
                                        </Grid>
                                    </Grid>
                                </InputLabel>
                                <DatePicker
                                    handleChange={(date) =>
                                        onChangeFilterDateHandle(date, 'dateFrom')
                                    }
                                    value={filter.dateFrom}
                                    maximumDay={getDateFromMax()}
                                    noTooltip
                                />
                            </FormControl>
                        </Grid>
                        <Grid item> - </Grid>
                        <Grid item>
                            <FormControl fullWidth>
                                <InputLabel shrink></InputLabel>
                                <DatePicker
                                    handleChange={(date) =>
                                        onChangeFilterDateHandle(date, 'dateTo')
                                    }
                                    value={filter.dateTo}
                                    maximumDay={getDateToMax()}
                                    minimumDay={getDateToMin()}
                                    noTooltip
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>

                <Box mt={6}>
                    <Grid container spacing={3}>
                        <Grid item>
                            <InputLabel shrink>
                                {t('general.customers.filter.customerStatus')}
                            </InputLabel>
                            <FormControl fullWidth>
                                <Select
                                    onChange={onChangeParamHandle}
                                    name={'customerStatus'}
                                    value={filter.customerStatus || ''}
                                    IconComponent={() => <BeakIcon />}
                                    fullWidth
                                    MenuProps={{
                                        disablePortal: true,
                                    }}
                                >
                                    <MenuItem value={''}>{t('general.statuses.all')}</MenuItem>
                                    {customerStatuses &&
                                        customerStatuses.map((option) => {
                                            return (
                                                <MenuItem value={option} key={option}>
                                                    {t(`general.statuses.${option}`)}
                                                </MenuItem>
                                            )
                                        })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <InputLabel shrink>
                                {t('general.customers.filter.vehicleStatus')}
                            </InputLabel>
                            <FormControl fullWidth>
                                <Select
                                    onChange={onChangeParamHandle}
                                    name={'vehicleStatus'}
                                    value={filter.vehicleStatus || ''}
                                    IconComponent={() => <BeakIcon />}
                                    fullWidth
                                    MenuProps={{
                                        disablePortal: true,
                                    }}
                                >
                                    <MenuItem value={''}>{t('general.statuses.all')}</MenuItem>
                                    {vehicleStatuses &&
                                        vehicleStatuses.map((option) => {
                                            return (
                                                <MenuItem value={option} key={option}>
                                                    {t(`general.statuses.${option}`)}
                                                </MenuItem>
                                            )
                                        })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
                <Box mt={6}>
                    <Grid container spacing={6} alignItems={'center'}>
                        <Grid item>
                            <SecondaryButton
                                label={t('general.button.customers.table.filter.apply')}
                                clickAction={applyFilter}
                            />
                        </Grid>
                        <Grid item>
                            <ResetButton onClick={resetFilter}>
                                {t('general.customers.table.filter.resetAll')}
                                <ResetIcon />
                            </ResetButton>
                        </Grid>
                    </Grid>
                </Box>
            </Root>
        </ClickAwayListener>
    )
}
