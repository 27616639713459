import React, { createContext, useEffect, useState } from 'react'
// import { Options } from '../api'

export const CountriesContext = createContext({
    countries: [],
    supportedLanguages: [],
    languageCultures: {},
})

export const CountriesProvider = ({ children }) => {
    const [countries, setCountries] = useState([])
    const [supportedLanguages, setSupportedLanguages] = useState([])
    const [languageCultures, setLanguageCultures] = useState({})

    // disabled atm - refactor with translations and user
    // useEffect(() => {
    //     const init = async () => {
    //         try {
    //             const response = await Options.getOptions()
    //             console.log('countries provider init', response.data)
    //             setCountries(response.data.countries)
    //             setSupportedLanguages(
    //                 response.data.countries.map(
    //                     (country) => country.defaultLanguage
    //                 )
    //             )
    //             setLanguageCultures(
    //                 response.data.countries.reduce(
    //                     (acc, curr) => ({
    //                         ...acc,
    //                         [curr.defaultLanguage]: `${curr.defaultLanguage}-${curr.countryCode}`,
    //                     }),
    //                     {}
    //                 )
    //             )
    //         } catch (e) {
    //             console.error(e)
    //         }
    //     }
    //     init()
    // }, [])

    return (
        <CountriesContext.Provider
            value={{ countries, supportedLanguages, languageCultures }}
        >
            {children}
        </CountriesContext.Provider>
    )
}
