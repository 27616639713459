export const CUSTOMER_INFORMATION_STEP = 0
export const ORDER_DETAILS_STEP = 1

export const NAVIGATION_BUTTONS_CONFIGURATION = {
    0: {
        buttonLabelKey: 'general.button.continue.order',
        backButtonUrl: '/customer-information',
        nextButtonUrl: '/order-details',
    },
    1: {
        isBack: true,
        buttonLabelKey: 'general.button.consents.create.customer',
        backButtonUrl: '/customer-information',
        nextButtonUrl: '/',
    },
}
