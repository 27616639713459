import { store } from '../index'
import { Options } from '../api'
import {
    GET_OPTIONS_ERROR,
    GET_OPTIONS_STARTED,
    GET_OPTIONS_SUCCESS,
} from '../types/reduxActionTypes'

export function getOptions() {
    const countries = store.getState().options.countries
    if (!countries || countries.length === 0) {
        store.dispatch({
            type: GET_OPTIONS_STARTED,
        })
        Options.getOptions()
            .then((response) => {
                store.dispatch({
                    type: GET_OPTIONS_SUCCESS,
                    payload: {
                        options: response.data,
                    },
                })
            })
            .catch((error) => {
                console.log(error)
                store.dispatch({
                    type: GET_OPTIONS_ERROR,
                })
            })
    }
}
