import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
    font-size: 18px;
    font-weight: 400;
    line-height: 1.38;
    color: #808285;
    text-align: center;
`

export default function TextInfo({ message }) {
    return <Wrapper>{message}</Wrapper>
}
