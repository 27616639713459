import React from 'react'
import styled from 'styled-components'
import LanguageSelector from './LanguageSelector'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { checkUrl } from '../util/util'
import { TRANSLATIONS_NAMESPACE } from '../constants/global'

const Root = styled.div`
    display: flex;
    height: 55px;
    min-height: 55px;
    padding: 0 30px;
    background-color: #4a4a4a;
    align-items: center;
`
const LeftSide = styled.div`
    display: flex;
    flex: 1 0 auto;
`
const RightSide = styled.div`
    flex: 1 0 auto;
    justify-content: flex-end;
    display: flex;
`
const Link = styled.div`
    margin-right: 40px;
    color: #c0c0c0;
    font-size: 12px;
    cursor: pointer;
`

const Element = styled.div`
    margin-right: 40px;
    color: #c0c0c0;
    font-size: 12px;
    cursor: default;
`

export default function Footer() {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const history = useHistory()

    const checkAndShowPopupForHome = (url) => {
        if (!checkUrl(url)) {
            history.push({
                pathname: url,
            })
        }
    }

    const openImprint = () => {
        checkAndShowPopupForHome('/imprint')
    }

    const openLegal = () => {
        checkAndShowPopupForHome('/legal')
    }

    return (
        <div className="noprint">
            <Root>
                <LeftSide>
                    <Element>{'© SEAT, S.A. ' + new Date().getUTCFullYear()}</Element>
                    <Link onClick={openImprint}>{t('general.footer.imprint')}</Link>
                    <Link onClick={openLegal}>{t('general.footer.legal')}</Link>
                </LeftSide>
                <RightSide>
                    <LanguageSelector />
                </RightSide>
            </Root>
        </div>
    )
}
