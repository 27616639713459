import PopUp from './PopUp'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ignoreKeyInHTML, rqStatusMap, TRANSLATIONS_NAMESPACE } from '../../constants/global'
import { ButtonWithSuffixSuffix, ButtonWithSuffixWrapper, Label, PrimaryButton } from '../Buttons'
import { SET_REQUESTED_SERVICES, SET_IS_PAPER_CONSENT } from '../../types/reduxActionTypes'
import { useDispatch, useSelector } from 'react-redux'
import { Box, CircularProgress, FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core'
import { useUpdateCustomerActions } from '../../hooks/actions/useUpdateCustomerActions'
import InputTooltip from '../Tooltip/InputTooltip'
import { Accounts } from '../../api'
import { NOTIFICATION_TYPE_ERROR, NOTIFICATION_TYPE_SUCCESS } from 'react-redux-notify'
import { useDispatchNotification } from '../../hooks/useDispatchNotification'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import { BeakIcon } from '../Icons/BeakIcon'
import styled from 'styled-components'
import MenuItem from '@material-ui/core/MenuItem'
import { RequestedServicesForm } from '../RequestedServicesForm'

export const ModifyConsents = ({ isOpen, onCloseHandler }) => {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const { saveOrderDetailsField } = useUpdateCustomerActions()
    const [dispatchNotification] = useDispatchNotification()
    const { saveCustomerItem } = useUpdateCustomerActions()
    const dispatch = useDispatch()

    const { requestedServices, customerInformation, orderDetails, consentLanguage } = useSelector(
        (state) => state.customer
    )
    const { userInfo } = useSelector((state) => state.oidc)
    const { selectedLanguage, countries } = useSelector((state) => state.options)

    const [consentEmailRqStatus, setConsentEmailRqStatus] = useState(rqStatusMap.INITIAL)
    const [languages, setLanguages] = useState([])
    const [isPrintConsentsFormShown, setIsPrintConsentsFormShown] = useState(false)

    const setBrand = (value) => {
        saveOrderDetailsField('brand', value)
    }

    const handlePrint = (triggerPrint = false) => {
        dispatch({
            type: SET_REQUESTED_SERVICES,
            payload: {
                ...requestedServices,
            },
        })
        const paramsArray = [
            `language=${consentLanguage}`,
            `email=${customerInformation.email}`,
            `preferredServicePartner=${requestedServices.preferredServicePartner.toString()}`,
            `dealerMaintenanceScheduling=${requestedServices.dealerMaintenanceScheduling.toString()}`,
            `seatIdent=${requestedServices.seatIdent.toString()}`,
            `isUpdate=true`,
        ]
        triggerPrint
            ? window.open(`/consent-documents?print=yes&${paramsArray.join('&')}`, '_blank')
            : window.open(`/consent-documents?${paramsArray.join('&')}`, '_blank')
        if (triggerPrint) {
            dispatch({
                type: SET_IS_PAPER_CONSENT,
                payload: true,
            })
        }
    }

    const sendConsentEmail = async () => {
        setConsentEmailRqStatus(rqStatusMap.IN_PROGRESS)
        try {
            await Accounts.modifyConsentsDigital({
                email: customerInformation.email,
                language: customerInformation.preferredLanguage,
                brand: orderDetails.brand,
            })
            setConsentEmailRqStatus(rqStatusMap.SUCCESS)

            dispatchNotification({
                message: t('general.consentEmail.sent.success', { replace: ignoreKeyInHTML }),
                type: NOTIFICATION_TYPE_SUCCESS,
                duration: 3000,
                canDismiss: true,
            })
            dispatch({
                type: SET_IS_PAPER_CONSENT,
                payload: false,
            })
        } catch (e) {
            console.error(e)
            setConsentEmailRqStatus(rqStatusMap.ERROR)
            dispatchNotification({
                message: t('general.consentEmail.sent.error', { replace: ignoreKeyInHTML }),
                type: NOTIFICATION_TYPE_ERROR,
                duration: 3000,
                canDismiss: true,
            })
        }
    }

    const Item = styled(MenuItem)`
        text-transform: none;
    `

    useEffect(() => {
        if (countries !== null && userInfo && userInfo.country !== null && countries.length > 0) {
            const selectedCulture = countries.find((c) => c.countryCode === userInfo.country)
            setLanguages(selectedCulture.languages)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLanguage, countries, userInfo])

    return (
        <PopUp
            open={isOpen}
            title={t('general.order.button.modifyConsent')}
            onClose={() => {
                isPrintConsentsFormShown
                    ? setIsPrintConsentsFormShown(false)
                    : onCloseHandler(false)
            }}
            body={
                isPrintConsentsFormShown ? (
                    <RequestedServicesForm />
                ) : (
                    <>
                        <Box sx={{ mb: 6 }}>
                            <Label shrink>{t('general.footer.language')}</Label>
                            <Select
                                displayEmpty={false}
                                value={consentLanguage}
                                onChange={(e) => {
                                    e.persist()
                                    saveCustomerItem('consentLanguage', e.target.value)
                                }}
                                input={<Input />}
                                IconComponent={BeakIcon}
                                label={t('general.customer.create.popup.email')}
                                name={'consentLanguage'}
                            >
                                {languages.map((name) => (
                                    <Item key={name} value={name}>
                                        {t('general.language.' + name.substring(0, 2))}
                                    </Item>
                                ))}
                            </Select>
                        </Box>
                        <Box sx={{ mb: 6 }}>
                            <Label shrink>{t(`general.order.brand.title`)}</Label>
                            <RadioGroup
                                row
                                name="brand"
                                value={orderDetails.brand || ''}
                                onChange={(e) => setBrand(e.target.value)}
                            >
                                <FormControlLabel value="SEAT" control={<Radio />} label="SEAT" />
                                <FormControlLabel value="CUPRA" control={<Radio />} label="CUPRA" />
                            </RadioGroup>
                        </Box>
                    </>
                )
            }
            actions={
                isPrintConsentsFormShown ? (
                    <Grid container spacing={3} justifyContent={'center'}>
                        <Grid item>
                            <PrimaryButton
                                label={t('general.button.showConsent')}
                                clickAction={() => handlePrint(false)}
                            />
                        </Grid>
                        <Grid item>
                            <PrimaryButton
                                label={t('general.button.print')}
                                clickAction={() => handlePrint(true)}
                            />
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container justifyContent={'center'} wrap={'wrap'}>
                        <Grid item>
                            <Box px={8} mb={4}>
                                <ButtonWithSuffixWrapper>
                                    <PrimaryButton
                                        variant={'contained'}
                                        clickAction={sendConsentEmail}
                                        label={t(`general.order.sendConsentEmail`)}
                                        disabled={
                                            !orderDetails.brand ||
                                            customerInformation.email === '' ||
                                            consentEmailRqStatus === rqStatusMap.IN_PROGRESS
                                        }
                                    />
                                    <ButtonWithSuffixSuffix>
                                        {consentEmailRqStatus === rqStatusMap.IN_PROGRESS ? (
                                            <CircularProgress size={'24px'} />
                                        ) : (
                                            <InputTooltip
                                                text={t(`general.order.sendConsentEmail.tooltip`)}
                                            />
                                        )}
                                    </ButtonWithSuffixSuffix>
                                </ButtonWithSuffixWrapper>
                            </Box>
                            <Box px={8}>
                                <ButtonWithSuffixWrapper>
                                    <PrimaryButton
                                        variant={'contained'}
                                        clickAction={() => setIsPrintConsentsFormShown(true)}
                                        label={t(`general.order.printConsentDocuments`)}
                                        disabled={
                                            !orderDetails.brand ||
                                            !customerInformation.email ||
                                            !consentLanguage
                                        }
                                    />
                                    <ButtonWithSuffixSuffix>
                                        <InputTooltip
                                            text={t(`general.order.printConsentDocuments.tooltip`)}
                                        />
                                    </ButtonWithSuffixSuffix>
                                </ButtonWithSuffixWrapper>
                            </Box>
                        </Grid>
                    </Grid>
                )
            }
        />
    )
}
