import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { SET_SELECTED_LANGUAGE } from '../types/reduxActionTypes'
import { useTranslation } from 'react-i18next'
import { TRANSLATIONS_NAMESPACE } from '../constants/global'

export function useChangeLanguage() {
    const dispatch = useDispatch()
    const { i18n } = useTranslation(TRANSLATIONS_NAMESPACE)

    const setLanguage = useCallback(
        (lang) => {
            i18n.changeLanguage(lang)
            dispatch({
                type: SET_SELECTED_LANGUAGE,
                payload: {
                    value: lang,
                },
            })
        },
        [dispatch, i18n]
    )

    return { setLanguage }
}
