import { defaultAddressTypes } from '../constants/defaultFormValues'
import {
    GET_ALL_TRANSLATIONS_SUCCESS,
    GET_OPTIONS_ERROR,
    GET_OPTIONS_STARTED,
    GET_OPTIONS_SUCCESS,
    GET_TRANSLATIONS_STARTED,
    GET_TRANSLATIONS_SUCCESS,
    SET_SELECTED_LANGUAGE,
} from '../types/reduxActionTypes'

const initialState = {
    loading: false,
    error: false,
    addressTypes: [],
    countries: [],
    selectedLanguage: null,
    translations: {},
}
const userSignedOutAction = 'oidc/userSignedOut'

export const optionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SELECTED_LANGUAGE:
            return {
                ...state,
                selectedLanguage: action.payload.value,
            }
        case GET_OPTIONS_STARTED:
        case GET_TRANSLATIONS_STARTED:
            return {
                ...state,
                loading: true,
                error: false,
                translations: state.translations,
            }
        case GET_OPTIONS_SUCCESS:
            return {
                ...state,
                addressTypes: defaultAddressTypes,
                countries: action.payload.options.countries,
                loading: false,
                error: false,
            }
        case GET_OPTIONS_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            }
        case GET_TRANSLATIONS_SUCCESS:
            return {
                ...state,
                translations: {
                    ...state.translations,
                    [action.payload.language]: action.payload.translations,
                },
            }
        case GET_ALL_TRANSLATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
            }
        case userSignedOutAction:
            return {
                ...initialState,
            }
        default:
            return state
    }
}
